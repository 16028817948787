import React from 'react';

import styled from 'styled-components';

type ChevronRightProps = {
  isOpen?: boolean;
  onClick?: () => void;
};

const ChevronContainer = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  svg {
    display: block;
  }
`;

export function AccordionChevron({ onClick, isOpen = false }: ChevronRightProps) {
  return (
    <ChevronContainer isOpen={isOpen} onClick={onClick}>
      <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
        <path
          clipRule='evenodd'
          d='M4.43416 8.45334C4.79468 8.07639 5.37844 8.07719 5.73802 8.45513L11.9924 15.0288L18.2637 8.47288C18.6243 8.09597 19.2081 8.09682 19.5676 8.47479C19.9272 8.85277 19.9263 9.46472 19.5658 9.84164L12.9684 16.7384C12.8399 16.8728 12.687 16.9798 12.5193 17.0524C12.3513 17.1251 12.1714 17.1624 11.9897 17.1622C11.808 17.1619 11.6281 17.1241 11.4604 17.0509C11.2928 16.9778 11.1407 16.8709 11.0125 16.7361L4.43245 9.82018C4.07287 9.44225 4.07363 8.83029 4.43416 8.45334Z'
          fill='#121516'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M4.31886 8.33216C4.74322 7.88846 5.43036 7.8894 5.85361 8.33426L11.9927 14.7867L18.1485 8.35169C18.5729 7.90803 19.26 7.90904 19.6832 8.35394C20.1064 8.79884 20.1055 9.51917 19.6811 9.96283L13.0837 16.8596C12.94 17.0098 12.7691 17.1294 12.5816 17.2106C12.3938 17.2919 12.1926 17.3336 11.9894 17.3333C11.7863 17.333 11.5852 17.2908 11.3976 17.2089C11.2103 17.1272 11.0402 17.0076 10.8969 16.857L4.31686 9.94105C3.8936 9.49619 3.8945 8.77586 4.31886 8.33216ZM5.62242 8.57599C5.32651 8.26498 4.84613 8.26432 4.54945 8.57452C4.25277 8.88472 4.25214 9.38831 4.54805 9.69932L11.1277 16.6148C11.2406 16.7336 11.3753 16.8284 11.5231 16.8929C11.671 16.9575 11.8296 16.9908 11.9899 16.991C12.1501 16.9913 12.3088 16.9584 12.4569 16.8942C12.6048 16.8302 12.7393 16.7362 12.8526 16.6178L19.4505 9.72044C19.7472 9.41027 19.7479 8.90669 19.452 8.59565C19.1561 8.28461 18.6757 8.2839 18.379 8.59407L12.1077 15.15C12.0439 15.2167 11.9405 15.2166 11.8768 15.1496L5.62242 8.57599ZM11.7814 15.2493C11.8451 15.3162 11.845 15.4245 11.7812 15.4913C11.7174 15.558 11.6135 15.5585 11.5498 15.4916C11.4861 15.4247 11.4862 15.3163 11.55 15.2496C11.6138 15.1828 11.7177 15.1824 11.7814 15.2493ZM12.2029 15.2499C12.2667 15.1832 12.3701 15.1833 12.4337 15.2502C12.4973 15.3172 12.4977 15.4261 12.4339 15.4928C12.37 15.5595 12.2667 15.5594 12.203 15.4925C12.1394 15.4255 12.139 15.3166 12.2029 15.2499Z'
          fill='#121516'
          fillRule='evenodd'
        />
      </svg>
    </ChevronContainer>
  );
}
