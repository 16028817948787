import React from 'react';

import _ from 'lodash';
import styled from 'styled-components';

import { DropdownSingle } from 'src/app/components/lib/dropdown';
import { usePaginateOnVisibleNode } from 'src/app/hooks/use-paginate-on-visible-node';

import type { DropdownSingleProps } from 'src/app/components/lib/dropdown';

export type ServerPaginatedDropdownSingleProps = DropdownSingleProps & {
  fetchNextPage: () => void;
  isFetching: boolean;
  morePagesExist: boolean;
  setFilterString: (filter: string) => void;
};

export function ServerPaginatedDropdownSingle({
  fetchNextPage,
  isFetching,
  morePagesExist,
  setFilterString,
  options,
  setValue,
  value,
  ...props
}: ServerPaginatedDropdownSingleProps) {
  const triggerNodeRef = usePaginateOnVisibleNode({ fetchNextPage, isFetching, morePagesExist });

  return (
    <DropdownSingle
      footerContent={isFetching ? <LoadingText key='loading-dropdown'>Loading...</LoadingText> : undefined}
      infiniteScroll
      options={options}
      paginationProps={{ isFetching, triggerNodeRef }}
      setValue={setValue}
      value={value}
      onSearchInputChange={_.debounce(setFilterString, 500)}
      {...props}
    />
  );
}

const LoadingText = styled.span`
  color: var(--color-gray-50);
`;
