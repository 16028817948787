import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { AssemblyDetailForm } from './detail';
import { AssemblyFormStateContext, useAssemblyFormState } from './detail/use-form-state';
import { AssembliesList } from './list';

import type { AssemblyDetailFormProps } from './detail';

function Assemblies() {
  return (
    <Routes>
      <Route element={<AssembliesList />} path='/' />
      <Route element={<AssemblyDetailFormWrapper formType='edit' />} path='/:assemblyId' />
      <Route element={<AssemblyDetailFormWrapper formType='create' />} path='/create' />
    </Routes>
  );
}

function AssemblyDetailFormWrapper({ formType }: AssemblyDetailFormProps) {
  const values = useAssemblyFormState();
  return (
    <AssemblyFormStateContext.Provider value={values}>
      <AssemblyDetailForm formType={formType} />
    </AssemblyFormStateContext.Provider>
  );
}

export default Assemblies;
