import React from 'react';

import _ from 'lodash';

import { costType as CostType } from 'src/app_deprecated/constants/InventoryConstants';

import { Input } from 'src/app/components/lib/input';
import { SelectAdornment } from 'src/app/components/lib/input/select-adornment';

import type { InputProps } from 'src/app/components/lib/input';
import type { Option } from 'src/app/components/lib/input/select-adornment';

type CostInputProps = InputProps & {
  automationId?: string;
  costType: string;
  costTypeDisabled?: boolean;
  label: string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  onChange?: ({ value }: { value: string }) => void;
  onCostTypeChange?: (value: string) => void;
  onFocus?: (e) => void;
  placeholder?: string;
  required?: boolean;
  value: number | string;
};

export function CostInput(props: CostInputProps) {
  const {
    onChange,
    value = 0,
    onCostTypeChange,
    costType,
    costTypeDisabled = false,
    required = false,
    label,
    placeholder,
    automationId,
    labelPlacement,
    onBlur,
    onFocus,
  } = props;

  const setCost = (event) => {
    const cost = event.target.value;
    onChange?.(cost);
  };

  const handleCostTypeChange = (val) => {
    const updatedCostType = val;
    onCostTypeChange?.(updatedCostType);
  };

  const costTypeOptions: Option[] = [
    {
      label: 'Catalog',
      value: CostType.PRODUCT_MASTER,
    },
    {
      label: 'Calculated',
      value: CostType.CALCULATED,
    },
    {
      label: 'Other',
      value: CostType.OTHER,
    },
  ];

  return (
    <Input
      autoComplete='off'
      automationId={automationId}
      className='form-control inv-conv__cost'
      disabled={_.includes([CostType.PRODUCT_MASTER, CostType.CALCULATED], costType)}
      endAdornment={
        <SelectAdornment
          disabled={costTypeDisabled}
          disableNoneOption
          label='Unit'
          options={costTypeOptions}
          useDefaultSelectRenderValue
          value={String(costType)}
          onChange={({ target }) => handleCostTypeChange(target.value)}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      placeholder={placeholder}
      required={required}
      type='number'
      value={value}
      onBlur={(e: any) => {
        if (onBlur) {
          onBlur(e.target.value);
        }
      }}
      onChange={setCost}
      onFocus={(e: any) => {
        if (onFocus) {
          onFocus(e.target.value);
        }
      }}
    />
  );
}
