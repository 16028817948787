import { useQuery } from '@tanstack/react-query';

import { fetchMany, useInfiniteGQLQuery } from 'src/app/queries/graphql';
import { GetProductsForDropdownDocument, GetProductsDocument } from 'src/gql/graphql';

import { productsKeys } from './query-key-factory';

import type { AbridgedFilterInput, QueryProps } from 'src/app/queries/types/gql-filtering-and-pagination';
import type { ProductAttributesFragment, TblWhseProductFilterInput, TblWhseProductSortInput } from 'src/gql/graphql';

type UseProductsQueryProps = QueryProps<AbridgedFilterInput<TblWhseProductFilterInput>, TblWhseProductSortInput>;

export function useProducts(queryProps: UseProductsQueryProps) {
  return useQuery({
    queryKey: productsKeys.many(queryProps),
    queryFn: async () => {
      const productsData = await fetchMany(GetProductsDocument, {
        ...queryProps,
        applyUserId: true,
      });
      const items = (productsData?.items?.filter(Boolean) ?? []) as ProductAttributesFragment[];
      return {
        ...productsData,
        items,
      };
    },
    keepPreviousData: true,
  });
}

export function useInfiniteProductsQuery(queryProps: UseProductsQueryProps, enabled = true) {
  const { fetchAllItems, ...result } = useInfiniteGQLQuery({
    enabled,
    queryKey: productsKeys.many(queryProps, 'infinite'),
    queryProps,
    queryDocument: GetProductsForDropdownDocument,
  });
  return {
    ...result,
    fetchAllProducts: async () => {
      const res = await fetchAllItems();
      return res.pages.flatMap(({ items }) => items);
    },
  };
}
