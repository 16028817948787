import React from 'react';

import _ from 'lodash';
import styled from 'styled-components';

import { DropdownMultiple } from 'src/app/components/lib/dropdown';
import { usePaginateOnVisibleNode } from 'src/app/hooks/use-paginate-on-visible-node';

import type { DropdownMultipleProps } from 'src/app/components/lib/dropdown/dropdown-multiple';

export type ServerPaginatedDropdownMultipleProps = DropdownMultipleProps & {
  fetchNextPage: () => void;
  isFetching: boolean;
  morePagesExist: boolean;
  placeholder?: string;
  setFilterString: (filter: string) => void;
};

export function ServerPaginatedDropdownMultiple({
  fetchNextPage,
  isFetching,
  morePagesExist,
  setFilterString,
  options,
  setValue,
  value,
  placeholder,
  ...props
}: ServerPaginatedDropdownMultipleProps) {
  const triggerNodeRef = usePaginateOnVisibleNode({ fetchNextPage, isFetching, morePagesExist });

  return (
    <DropdownMultiple
      footerContent={isFetching ? <LoadingText>Loading...</LoadingText> : undefined}
      helpText={placeholder}
      options={options}
      paginationProps={{ isFetching, triggerNodeRef }}
      setValue={setValue}
      value={value}
      onSearchInputChange={_.debounce(setFilterString, 500)}
      {...props}
    />
  );
}

const LoadingText = styled.span`
  color: var(--color-gray-50);
`;
