import GeneralApi from '../api/GeneralApi';
import { actions as ActionTypes } from '../constants/GeneralConstants';
import UserStore from '../stores/UserStore';
import AppDispatcher from '../utils/AppDispatcher';

import NotificationActions from './NotificationActions';

const GeneralActions = {
  getRooms: (notify) => {
    GeneralApi.getRooms(UserStore.getApiData())

      .then((res) => {
        if (notify) {
          NotificationActions.success('Rooms re-loaded');
        }

        AppDispatcher.dispatch({
          actionType: ActionTypes.ROOMS_LOADED,
          data: res.Data,
        });
      })
      .catch(() => {
        NotificationActions.apiError(false, false);
      });
  },

  loadLabels: () => {
    // GeneralApi.loadLabels(UserStore.getApiData())
    // .then(res => {
    //     AppDispatcher.dispatch({
    //         actionType: ActionTypes.ROOMS_LOADED,
    //         data: res.Data
    //     });
    // })
    // .catch(() => { NotificationActions.apiError(false, false); });
  },

  getStrains: async (notify) => {
    try {
      const res = await GeneralApi.getStrains(UserStore.getApiData());
      if (notify) {
        NotificationActions.success('Strains re-loaded');
      }
      AppDispatcher.dispatch({
        actionType: ActionTypes.STRAINS_LOADED,
        data: res.Data,
      });
    } catch (e) {
      NotificationActions.apiError(false, false);
    }
  },

  getUsers: async () => {
    try {
      const res = await GeneralApi.getUsers(UserStore.getApiData());

      if (res.Result) {
        AppDispatcher.dispatch({
          actionType: ActionTypes.USERS_LOADED,
          data: res.Data,
        });
      } else if (!res.Result) {
        NotificationActions.error(`Failed to load users: ${res.Message}`);
        AppDispatcher.dispatch({
          actionType: ActionTypes.USERS_LOADED,
          data: [],
        });
      }
    } catch (e) {
      NotificationActions.error('Failed to load users');
    }
  },
};

export default GeneralActions;
