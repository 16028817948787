import React from 'react';

import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ChevronRight } from 'src/app/components/icons/chevron-right';

type DemographicTableRowProps = {
  bulletColor?: string;
  index?: number;
  isBold?: boolean;
  isGrayedOut?: boolean;
  isLast?: boolean;
  key: string;
  keyValue: string;
  link?: string;
  onMouseEnter?: (index: number | undefined) => void;
  title: string;
  value: any;
};

export function DemographicTableRow({
  index,
  title,
  keyValue,
  value,
  isBold,
  key,
  isLast,
  link,
  bulletColor,
  isGrayedOut,
  onMouseEnter,
}: DemographicTableRowProps) {
  const navigate = useNavigate();

  return (
    <DemographicTableRowContainer
      $isBold={isBold}
      $isGrayedOut={isGrayedOut}
      $link={link}
      key={key}
      onClick={() => {
        if (link) {
          navigate(link);
        }
      }}
      onMouseEnter={() => {
        if (onMouseEnter && index !== undefined) {
          onMouseEnter(index);
        }
      }}
      onMouseLeave={() => {
        if (onMouseEnter && index !== undefined) {
          onMouseEnter(undefined);
        }
      }}
    >
      <TitleCell $bulletColor={bulletColor} $isLast={isLast}>
        {keyValue}
      </TitleCell>
      <ValueCell $isLast={isLast}>
        {title} ({value}%)
      </ValueCell>
      {link && link.length > 0 && (
        <ChevronWrapper>
          <ChevronRight />
        </ChevronWrapper>
      )}
    </DemographicTableRowContainer>
  );
}

const DemographicTableRowContainer = styled.div<{ $isBold?: boolean; $isGrayedOut?: boolean; $link?: string }>`
  display: flex;
  font: var(--font-regular-14pt-${(props) => (props.$isBold ? 'semibold' : 'normal')});
  width: auto;
  max-width: none;
  text-align: left;
  vertical-align: middle;
  border-top: none;
  padding-top: 1.1em;
  cursor: ${(props) => (props.$link && props.$link.length > 0 ? 'pointer' : '')};
  opacity: ${(props) => (props.$isGrayedOut ? 0.5 : 1)};
`;

const TitleCell = styled.div<{ $bulletColor?: string; $isLast?: boolean }>`
  display: flex;
  width: 50%;
  border-bottom: ${(props) => (props.$isLast ? '' : '1px solid var(--color-gray-20)')};

  &:before {
    ${(props) =>
      props.$bulletColor
        ? css`
            content: '• ';
          `
        : css`
            content: '';
          `};
    color: ${(props) => props.$bulletColor};
    padding-right: ${(props) => (props.$bulletColor ? '0.5em' : 0)};
`;

const ValueCell = styled.div<{ $isLast?: boolean }>`
  display: flex;
  width: 50%;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 1.1em;
  border-bottom: ${(props) => (props.$isLast ? '' : '1px solid var(--color-gray-20)')};
`;

const ChevronWrapper = styled.div`
  padding-left: 1em;
  padding-top: 0.1em;
`;
