import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { FAILED_TO_LOAD_BOM } from 'src/app/pages/manufacturing/assemblies/constants';
import { getBillOfMaterial } from 'src/app/queries/graphql/bill-of-materials/get-one';
import { useNotificationActions } from 'src/app/state/notifications';

import { useAssemblyFormStateContext } from '../../use-form-state';

import type { AssemblyFormState } from '../../use-form-state';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

export function useAssemblyDetails() {
  const queryClient = useQueryClient();
  const notification = useNotificationActions();

  const [{ formData, errors, isReadonly }, dispatch] = useAssemblyFormStateContext();
  const [isLoadingBom, setIsLoadingBom] = useState(false);

  const setValue = <K extends keyof AssemblyFormState>(key: K, value: AssemblyFormState[K]) => {
    dispatch({ type: 'set-value', payload: { key, value } });
  };

  const resetBomData = (bomId?: number) => {
    setValue('billOfMaterialsId', bomId ?? null);
    setValue('outputs', []);
  };

  const applyBillOfMaterial = async (option: DropdownMenuOption) => {
    const { id: bomId } = option;
    if (bomId === '') {
      resetBomData();
    } else {
      setIsLoadingBom(true);
      resetBomData(Number(bomId));
      try {
        const bom = await getBillOfMaterial(Number(bomId), queryClient);
        if (!bom) {
          throw new Error(FAILED_TO_LOAD_BOM);
        }
        dispatch({ type: 'apply-bom', payload: bom });
      } catch (error: unknown) {
        const errorMessage = error instanceof Error ? error.message : FAILED_TO_LOAD_BOM;
        notification.error({ message: errorMessage });
      } finally {
        setIsLoadingBom(false);
      }
    }
  };

  return {
    applyBillOfMaterial,
    errors,
    formData,
    isLoadingBom,
    isReadonly,
    setValue,
  };
}
