import { getOperatorsFromOperatorGroup } from './details/segment-query-builder/operators';

import type { Attributes, CustomerSegmentSchemaMap } from './details/segment-query-builder/schema';
import type {
  Rule,
  RuleGroup,
} from 'src/app/pages/customers/customers/segments/details/segment-query-builder/use-query-builder';

export type SegmentPayload = {
  segmentName: string;
};

export type QueryRulesAndSchemaResponse = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  segmentDetails?: RuleGroup;
};

type GetOperatorFromRuleProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableSegmentsV2: boolean;
  rule: Rule;
};

export const getOperatorFromRule = ({ rule, customerSegmentSchema, enableSegmentsV2 }: GetOperatorFromRuleProps) => {
  const operatorGroup = customerSegmentSchema
    ? customerSegmentSchema[rule.Condition]?.attributes?.find(
        (attribute: Attributes) => attribute.name === rule.Attribute
      )?.operatorGroup
    : 'string';
  const ruleOperators = getOperatorsFromOperatorGroup(operatorGroup, enableSegmentsV2);
  return ruleOperators.find((operator) => operator.name === rule.Operator);
};

type getOperatorGroupFromRuleProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableSegmentsV2: boolean;
  rule: Rule;
};

export const getOperatorGroupFromRule = ({
  rule,
  customerSegmentSchema,
  enableSegmentsV2,
}: getOperatorGroupFromRuleProps) => {
  const operatorGroup = customerSegmentSchema
    ? customerSegmentSchema[rule.Condition]?.attributes?.find(
        (attribute: Attributes) => attribute.name === rule.Attribute
      )?.operatorGroup
    : 'string';

  const ruleOperators = getOperatorsFromOperatorGroup(operatorGroup, enableSegmentsV2);
  return ruleOperators;
};
