import { atom } from 'recoil';

import GeneralStore from 'src/app_deprecated/stores/GeneralStore';

type Strain = {
  Abbreviation: string;
  ExternalId: number | string | null;
  StrainAbbreviation: string;
  StrainDescription: string;
  StrainId: number;
  StrainName: string;
  StrainType: string | null;
};

type TerminalDetails = {
  RoomId?: number | null;
  TemindalId?: number | null;
  TerminalName?: string | null;
};

type Room = {
  CheckOutRoom: string | null;
  CultivationStage: string | null;
  DispensaryLimitedRoom: string | null;
  DispensaryRestrictedRoom: string | null;
  EcommerceRoom: string | null;
  ExamRoom: string | null;
  ExternalId: string | null;
  InspectionRoom: string | null;
  InventoryRoom: string | null;
  IsMsuOwned: boolean | null;
  LocationType: string | null;
  MedicationRoom: string | null;
  MenuList: [];
  PosRoom: string | null;
  PreOrderRoom: string | null;
  PublishToMenus: boolean | null;
  QuarantineRoom: string | null;
  RoomArea: number | null;
  RoomAreaUnits: string | null;
  RoomId: number | null;
  RoomName: string | null;
  RoomPurpose: string | null;
  TerminalDetails: TerminalDetails[];
  Terminals: [];
  VaultRoom: string | null;
  WaitingRoom: string | null;
};

export type User = {
  DateOfBirth: string | null;
  DefaultLocation: number;
  Email: string | null;
  EmployeePinCode: string | null;
  ForcePasswordChange: boolean | null;
  FullName: string | null;
  IAmAnAdministrator: boolean | null;
  IamRestrictedToOfficeAcess: boolean | null;
  ICanAccessInventory: boolean | null;
  ICanAccessReports: boolean | null;
  ICanExportReports: boolean | null;
  IDispensaryCheckIn: boolean | null;
  InventoryReadOnly: boolean | null;
  IsLocalSuperuser: boolean | null;
  IWorkInADispensary: boolean | null;
  IWorkInAGrower: boolean | null;
  Location: string | null;
  ManagerFlag: string | null;
  Name: string | null;
  Password: string | null;
  PermissionGroups: string | null;
  StateId: string | null;
  StateIdExpiration: string | null;
  StateInspector: string | null;
  Status: string | null;
  UserId: number;
  UserStatus: string | null;
  UserType: string | null;
};

type GeneralAtom = {
  rooms: Room[] | false;
  strains: Strain[];
  users: User[] | false;
};

// FWIW this is a terrible name, I'm just duplicating the existing store
// so it is easier to find for others that come accross it.
// TODO-BACKOFFICE: rename
export const generalAtom = atom<GeneralAtom>({
  key: 'general',
  default: {
    strains: [],
    rooms: [],
    users: [],
  },
  effects_UNSTABLE: [useSyncStateEffect],
});

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListener() {
    const { rooms, strains, users } = GeneralStore.getState();

    setSelf({ rooms, strains, users });
  }

  if (trigger === 'get') {
    changeListener(); // initial call
  }

  GeneralStore.addChangeListener(changeListener);

  return () => GeneralStore.removeChangeListener(changeListener);
}
