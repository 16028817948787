import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';

import { useFavoriteReportsQuery } from 'src/app/queries/posv3/reports/get-favorites';
import { ReportCategory, useReportsListQuery } from 'src/app/queries/posv3/reports/list-reports';
import { usePermissionCheck } from 'src/app/state/user-permissions';

import { removeProductCategoryFromCanonicalName } from './utils';

// this hook's return value represents the source of truth for what a "StaticReport" is, as far as the frontend is concerned
export type StaticReport = ReturnType<typeof useReportsList>['reportsList'][number];

type UseReportsListParams = {
  isEnabled?: false;
};
export function useReportsList(params?: UseReportsListParams) {
  const { isEnabled } = params ?? {};
  const { data: reportsList = [], isLoading } = useReportsListQuery({ isEnabled });
  const { data: favoriteReports = [], isLoading: isLoadingFavorites } = useFavoriteReportsQuery();
  const flags = useFlags();

  // perm checks
  const isSuperUser = usePermissionCheck(Permissions.SuperUser);
  const canViewInventoryReports = usePermissionCheck(Permissions.ViewInventoryReports);
  const canViewSalesReports = usePermissionCheck(Permissions.ViewSalesReports);
  const canViewMarketingReports = usePermissionCheck(Permissions.ViewMarketingReports);
  const canViewOperationsReports = usePermissionCheck(Permissions.ViewOperationsReports);
  const canViewEmployeeReports = usePermissionCheck(Permissions.ViewEmployeeReports);

  const permToCategoryMap = useMemo(
    () => ({
      [ReportCategory.INVENTORY]: canViewInventoryReports,
      [ReportCategory.SALES]: canViewSalesReports,
      [ReportCategory.MARKETING]: canViewMarketingReports,
      [ReportCategory.OPERATIONS]: canViewOperationsReports,
      [ReportCategory.EMPLOYEE]: canViewEmployeeReports,
    }),
    [
      canViewEmployeeReports,
      canViewInventoryReports,
      canViewMarketingReports,
      canViewOperationsReports,
      canViewSalesReports,
    ]
  );

  const augmentedReportsList = useMemo(() => {
    const reportsWithCname = reportsList.map((report) => ({
      ...report,
      canonicalName: removeProductCategoryFromCanonicalName(_.kebabCase(report.ReportName)),
    }));
    const reportsByPermission = reportsWithCname.filter(
      (report) => isSuperUser || permToCategoryMap[report.ReportCategory]
    );
    const reportsWithFavorite = reportsByPermission.map((report) => {
      if (favoriteReports.some((favorite) => favorite.StaticReportId === report.ReportId)) {
        return { ...report, isFavorite: true };
      }
      return { ...report, isFavorite: false };
    });
    const reportsWithComputedIsRunnable = reportsWithFavorite.map((report) => {
      const isCCRSReportAsync = flags['platform.reports.ccrs-report-async.rollout'];

      const isRunnable =
        report.ExportEndpoint === 'api/posv3/reports/export-report' ||
        report.ExportEndpoint.startsWith('api/posv3/snowflake-reports') ||
        (report.ExportEndpoint.startsWith('api/posv3/reports/export-ccrs-reports') && isCCRSReportAsync);

      return { ...report, isRunnable };
    });

    return reportsWithComputedIsRunnable.sort((reportA, reportB) =>
      reportA.ReportName.localeCompare(reportB.ReportName)
    );
  }, [reportsList, isSuperUser, permToCategoryMap, favoriteReports, flags]);

  return { reportsList: augmentedReportsList, isLoading: isLoading || isLoadingFavorites };
}
