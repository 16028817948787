import { useCallback, useState } from 'react';

import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { Status } from 'src/app/pages/manufacturing/assemblies/constants';
import { useAssemblyFormStateContext } from 'src/app/pages/manufacturing/assemblies/detail/use-form-state';
import { useCompleteAssembly } from 'src/app/queries/manufacturing/complete-assembly';
import { useStartAssembly } from 'src/app/queries/manufacturing/start-assembly';
import { useUndoAssembly } from 'src/app/queries/manufacturing/undo-assembly';
import { useNotificationActions } from 'src/app/state/notifications';

import type { CompleteAssemblyRequest, FailedAssemblyResponse } from 'src/app/queries/manufacturing/types';

export function useOutputs() {
  // Local state
  const [isCompleteAssemblyModalOpen, setIsCompleteAssemblyModalOpen] = useState(false);

  // Form data fetching

  const [{ formData, errors, isReadonly, context }] = useAssemblyFormStateContext();
  const { assemblyStatusId, outputs, processingJobTypeTemplate } = formData;
  const { useMetrcV2 } = context;
  const { isProcessingJob } = processingJobTypeTemplate;
  const isValid = isEmpty(errors);

  // Hooks

  const { assemblyId } = useParams();
  const { mutate: startAssembly, isLoading: isStartingAssembly } = useStartAssembly();
  const { mutate: completeAssembly, isLoading: isCompletingAssembly } = useCompleteAssembly();
  const { mutate: undoChanges, isLoading: isUndoingChanges } = useUndoAssembly();
  const notifications = useNotificationActions();

  // Computed values

  const isActionDisabled = !isValid || isReadonly || assemblyId === undefined;
  const isCompleteButtonDisabled =
    isActionDisabled || isCompletingAssembly || !(assemblyStatusId === Status.IN_PROGRESS);
  const isStartButtonDisabled = isActionDisabled || isStartingAssembly || assemblyStatusId === Status.COMPLETE;
  const isUndoButtonDisabled = isActionDisabled || isUndoingChanges || assemblyStatusId === Status.COMPLETE;

  // Handlers

  const handleStartAssembly = () => {
    if (assemblyId === undefined) {
      return;
    }

    const onSuccess = () => {
      notifications.success({ message: 'Assembly has been successfully started' });
    };
    const onError = (error: FailedAssemblyResponse) => {
      const message = error?.response?.data?.Message;
      notifications.error({ message: message || 'Failed to start assembly' });
    };

    startAssembly({ AssemblyId: Number(assemblyId) }, { onSuccess, onError });
  };

  const handleUndoAssembly = () => {
    if (assemblyId === undefined) {
      return;
    }

    const onSuccess = () => {
      notifications.success({ message: 'Assembly changes have been successfully reverted' });
    };
    const onError = (error: FailedAssemblyResponse) => {
      const message = error?.response?.data?.Message;
      notifications.error({ message: message || 'Failed to undo assembly' });
    };

    undoChanges({ AssemblyId: Number(assemblyId) }, { onSuccess, onError });
  };

  const finishAssembly = useCallback(
    (req: Omit<CompleteAssemblyRequest, 'AssemblyId'>) => {
      if (assemblyId === undefined) {
        return;
      }

      const onSuccess = () => {
        notifications.success({ message: 'Assembly has been successfully completed' });
      };
      const onError = (error: FailedAssemblyResponse) => {
        const message = error?.response?.data?.Message;
        const defaultMessage = 'Failed to complete assembly';
        notifications.error({ message: message || defaultMessage });
      };

      completeAssembly({ AssemblyId: Number(assemblyId), ...req }, { onSuccess, onError });
    },
    [assemblyId, completeAssembly, notifications]
  );

  const handleCompleteAssembly = () => {
    if (useMetrcV2 && isProcessingJob) {
      // Show modal to enter waste amounts
      setIsCompleteAssemblyModalOpen(true);
    } else {
      // Complete assembly without waste amounts
      finishAssembly({});
    }
  };

  return {
    closeCompleteAssemblyModal: () => setIsCompleteAssemblyModalOpen(false),
    errors,
    finishAssembly,
    handleCompleteAssembly,
    handleStartAssembly,
    handleUndoAssembly,
    hasAssemblyBeenStarted: assemblyStatusId === Status.IN_PROGRESS,
    isCompleteAssemblyModalOpen,
    isCompleteButtonDisabled,
    isCompleteButtonLoading: isCompletingAssembly,
    isStartButtonDisabled,
    isStartButtonLoading: isStartingAssembly,
    isUndoButtonDisabled,
    isUndoButtonLoading: isUndoingChanges,
    outputs,
  };
}
