import type { FetchManyResource } from '../../types/api';
import type { GetInventoryStatusesQuery } from 'src/gql/graphql';

export type InventoryStatus = ReturnType<typeof extendInventoryStatus>;

type InventoryStatusPre = FetchManyResource<GetInventoryStatusesQuery>;

export function extendInventoryStatus(inventoryStatus?: InventoryStatusPre) {
  return {
    InventoryStatusId: inventoryStatus?.id,
    InventoryStatus: inventoryStatus?.invStatusDesc,
  };
}
