import { formatLabResults } from 'src/app/utils/format-lab-results';

import type { BatchesWithLabsFragment as BTL } from 'src/gql/graphql';

export type BatchWithLabs = ReturnType<typeof extendBatchWithLabs>;

export const extendBatchWithLabsMap = {
  BatchId: (bt: BTL) => bt.id,
  PlantGroup: (bt: BTL) => bt.batchLotNumber,
  StrainName: (bt: BTL) => bt.batchStrainNavigation?.strainName,
  THC: (bt: BTL) => formatLabResults({ labResults: bt.tblBatchThcs, labKey: 'thc' }),
  THCa: (bt: BTL) => formatLabResults({ labResults: bt.tblBatchThcs, labKey: 'thca' }),
  CBD: (bt: BTL) => formatLabResults({ labResults: bt.tblBatchThcs, labKey: 'cbd' }),
  CBDa: (bt: BTL) => formatLabResults({ labResults: bt.tblBatchThcs, labKey: 'cbda' }),
} as const;

type ExtendedBatchWithLabsMap = typeof extendBatchWithLabsMap;

type ExtendedBatchWithLabs = {
  [Key in keyof ExtendedBatchWithLabsMap]: ExtendedBatchWithLabsMap[Key] extends (arg: any) => infer T ? T : never;
};

export function extendBatchWithLabs(batch: BTL) {
  const extendedValues = Object.entries(extendBatchWithLabsMap).reduce((acc, [key, fn]) => {
    acc[key] = fn(batch);
    return acc;
  }, {});
  return {
    ...batch,
    ...(extendedValues as ExtendedBatchWithLabs),
  };
}
