import { useQuery } from '@tanstack/react-query';

import { ERROR_FETCHING_ASSEMBLY_MSG } from 'src/app/pages/manufacturing/assemblies/constants';
import { fetchOne } from 'src/app/queries/graphql';
import { useNotificationActions } from 'src/app/state/notifications';
import { GetAssemblyDocument } from 'src/gql/graphql';

import { assemblyKeys } from './query-key-factory';

import type { GetAssemblyQueryVariables } from 'src/gql/graphql';

type UseBOMQueryParameters = {
  assemblyId: GetAssemblyQueryVariables['id'];
  enabled?: boolean;
  onError?: (error: unknown) => void;
  staleTime?: number;
};

export type AssemblyDetailResponse = ReturnType<typeof useAssemblyQuery>['data'];
export type AssemblyOutputItems = NonNullable<NonNullable<AssemblyDetailResponse>['assemblyOutputItems']>;
export type AssemblyOutputItem = NonNullable<AssemblyOutputItems[number]>;

export function useAssemblyQuery({ assemblyId, enabled, onError }: UseBOMQueryParameters) {
  const notifications = useNotificationActions();
  return useQuery({
    queryFn: async () => fetchOne(GetAssemblyDocument, { key: 'id', value: assemblyId }),
    queryKey: assemblyKeys.one(assemblyId),
    useErrorBoundary: true,
    enabled,
    onError: (value) => {
      notifications.error({ message: ERROR_FETCHING_ASSEMBLY_MSG });
      onError?.(value);
    },
  });
}
