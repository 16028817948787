import type { ChangeEvent } from 'react';
import React, { useMemo, useState } from 'react';

import styled from 'styled-components';

import Units from 'src/app_deprecated/utils/UnitConversion';

import { QuantityInput } from 'src/app/components/form-elements/inputs';
import { Input } from 'src/app/components/lib/input';
import { Modal } from 'src/app/components/lib/modal';

import { useGetRequiredUnitTypes } from './useGetRequiredUnitTypes';

import type { CompleteAssemblyRequest } from 'src/app/queries/manufacturing/types';

type CompleteAssemblyModalProps = {
  onClose: () => void;
  onFinish?: (wasteAmounts: Omit<CompleteAssemblyRequest, 'AssemblyId'>) => void;
  open?: boolean;
};

export function CompleteAssemblyModal({ onClose, onFinish, open = false }: CompleteAssemblyModalProps) {
  // Local state

  const [wasteNote, setWasteNote] = useState<string>('');
  const [quantityWasteAmount, setQuantityWasteAmount] = useState<number>();
  const [quantityWasteUnitId, setQuantityWasteUnitId] = useState<number>(Units.QUANTITY);
  const [volumeWasteAmount, setVolumeWasteAmount] = useState<number>();
  const [volumeWasteUnitId, setVolumeWasteUnitId] = useState<number>(Units.MILLILITERS);
  const [weightWasteAmount, setWeightWasteAmount] = useState<number>();
  const [weightWasteUnitId, setWeightWasteUnitId] = useState<number>(Units.GRAMS);

  // Derived state

  const { requiredUnitTypes } = useGetRequiredUnitTypes();
  const isQuantityWasteRequired = requiredUnitTypes.has(Units.UnitTypes.QUANTITY);
  const isVolumeWasteRequired = requiredUnitTypes.has(Units.UnitTypes.VOLUME);
  const isWeightWasteRequired = requiredUnitTypes.has(Units.UnitTypes.WEIGHT);

  const isFinishButtonDisabled = useMemo(() => {
    if (isQuantityWasteRequired && !quantityWasteAmount) {
      return true;
    }
    if (isVolumeWasteRequired && !volumeWasteAmount) {
      return true;
    }
    if (isWeightWasteRequired && !weightWasteAmount) {
      return true;
    }
    return wasteNote === '';
  }, [
    isQuantityWasteRequired,
    isVolumeWasteRequired,
    isWeightWasteRequired,
    quantityWasteAmount,
    volumeWasteAmount,
    weightWasteAmount,
    wasteNote,
  ]);

  // Handlers

  const handleFinish = () => {
    onFinish?.({
      QuantityWasteAmount: quantityWasteAmount,
      QuantityWasteUnitId: quantityWasteUnitId,
      VolumeWasteAmount: volumeWasteAmount,
      VolumeWasteUnitId: volumeWasteUnitId,
      WasteNote: wasteNote,
      WeightWasteAmount: weightWasteAmount,
      WeightWasteUnitId: weightWasteUnitId,
    });
  };

  return (
    <Modal
      Content={
        <ContentContainer>
          <Message>To create these packages and finish the processing job, enter the waste weight below.</Message>
          <QuantityInput
            disabled={!isQuantityWasteRequired}
            label='Quantity:'
            labelPlacement='start'
            required={isQuantityWasteRequired}
            unitTypeId={Units.UnitTypes.QUANTITY}
            unitValue={quantityWasteUnitId?.toString() ?? '0'}
            value={quantityWasteAmount ?? ''}
            onChange={({ value }) => {
              const newAmount = value ? parseFloat(value) : undefined;
              setQuantityWasteAmount(newAmount);
            }}
            onUnitChange={({ unitId }) => setQuantityWasteUnitId(Number(unitId))}
          />
          <QuantityInput
            disabled={!isVolumeWasteRequired}
            label='Volume:'
            labelPlacement='start'
            required={isVolumeWasteRequired}
            unitTypeId={Units.UnitTypes.VOLUME}
            unitValue={volumeWasteUnitId?.toString() ?? '0'}
            value={volumeWasteAmount ?? ''}
            onChange={({ value }) => {
              const newAmount = value ? parseFloat(value) : undefined;
              setVolumeWasteAmount(newAmount);
            }}
            onUnitChange={({ unitId }) => setVolumeWasteUnitId(Number(unitId))}
          />
          <QuantityInput
            disabled={!isWeightWasteRequired}
            label='Weight:'
            labelPlacement='start'
            required={isWeightWasteRequired}
            unitTypeId={Units.UnitTypes.WEIGHT}
            unitValue={weightWasteUnitId?.toString() ?? '0'}
            value={weightWasteAmount ?? ''}
            onChange={({ value }) => {
              const newAmount = value ? parseFloat(value) : undefined;
              setWeightWasteAmount(newAmount);
            }}
            onUnitChange={({ unitId }) => setWeightWasteUnitId(Number(unitId))}
          />
          <Input
            label='Note:'
            labelPlacement='start'
            placeholder='Enter reason for waste'
            required
            value={wasteNote}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setWasteNote(e.target.value)}
          />
        </ContentContainer>
      }
      contentPadding='32px'
      open={open}
      primaryActions={[{ label: 'Finish', onClick: handleFinish, disabled: isFinishButtonDisabled }]}
      title='Complete Assembly'
      onClose={onClose}
    />
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const Message = styled.div`
  color: var(--color-grayscale-black);
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
