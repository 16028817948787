import React from 'react';

import styled from 'styled-components';

import { DataGrid } from '..';

import type { BohDataGridProps } from '..';

export function ModalDataGrid({
  autoWidth = false,
  hideActionsColumn = true,
  ...props
}: BohDataGridProps & { autoWidth?: boolean }) {
  return <StyledDataGrid $autoWidth={autoWidth} displayBorder hideActionsColumn={hideActionsColumn} {...props} />;
}

const StyledDataGrid = styled(DataGrid)<{ $autoWidth: boolean }>`
  .MuiDataGrid-main {
    box-shadow: none;
  }

  .MuiDataGrid-footerContainer {
    border-top: 0;
  }
  .MuiDataGrid-cell {
    padding: var(--sizes-40);
  }

  .MuiDataGrid-columnHeader {
    padding-left: var(--sizes-40);
  }
  .MuiDataGrid-columnHeader[data-field='__check__'] {
    padding: 0px !important;
  }

  ${({ $autoWidth }) =>
    $autoWidth &&
    `
    .MuiDataGrid-columnHeaders {
      position: static;
    }

    .MuiDataGrid-virtualScroller {
      margin-top: 0px !important;
    }
  `}
`;
