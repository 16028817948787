import React from 'react';

import styled, { css } from 'styled-components';

import { TimesIcon } from '../../icons/times-icon';

import { AlertBannerStyle, AlertBannerStyles } from './alert-banner.styles';

export type AlertBannerProps = {
  hasBorder?: boolean;
  icon?: JSX.Element;
  marginBottom?: string;
  marginTop?: string;
  onClose?: () => void;
  style?: AlertBannerStyles;
  text: string;
  title?: string;
  width?: string;
};

export function AlertBanner(props: AlertBannerProps) {
  const { style, icon, title, text, marginTop, marginBottom, onClose, width, hasBorder } = props;
  let { background, textColor } = AlertBannerStyle.default;
  const { displayIcon } = AlertBannerStyle.default;
  let renderedIcon = displayIcon;

  if (!!style && Object.values(AlertBannerStyles).includes(style)) {
    const styleKey = AlertBannerStyles[style];
    // eslint-disable-next-line prefer-destructuring
    background = AlertBannerStyle[styleKey].background;

    // eslint-disable-next-line prefer-destructuring
    textColor = AlertBannerStyle[styleKey].textColor;
    renderedIcon = AlertBannerStyle[styleKey].displayIcon;
  }

  if (icon) {
    renderedIcon = icon;
  }

  return (
    <AlertBannerContainer
      $background={background}
      $hasBorder={hasBorder}
      $marginBottom={marginBottom}
      $marginTop={marginTop}
      $textColor={textColor}
      $width={width}
      aria-label='Alert banner'
      data-testid='alert-banner-item'
    >
      <AlertBannerTextContainer textColor={textColor}>
        {renderedIcon && <AlertBannerIcon data-testid='alert-banner-icon'>{renderedIcon}</AlertBannerIcon>}
        <AlertBannerText>
          {title && <AlertBannerTitle>{title}</AlertBannerTitle>}
          {text}
        </AlertBannerText>
        {onClose && (
          <CloseButton data-testid='onclose-button' onClick={onClose}>
            <StyledTimesIcon />
          </CloseButton>
        )}
      </AlertBannerTextContainer>
    </AlertBannerContainer>
  );
}

const AlertBannerContainer = styled.div<{
  $background?: string;
  $hasBorder?: boolean;
  $marginBottom?: string;
  $marginTop?: string;
  $textColor?: string;
  $width?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;

  background: ${({ $background }) => $background || 'var(--color-gray-20)'};
  border-radius: 8px;
  margin-top: 32px;
  margin-top: ${({ $marginTop }) => $marginTop || '32px'};
  margin-bottom: 32px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '32px'};

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}

  ${({ $hasBorder, $textColor }) =>
    $hasBorder &&
    css`
      border: 1px solid ${$textColor};
    `}
`;

const AlertBannerTextContainer = styled.div<{ textColor?: string }>`
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex: 2;
  color: ${({ textColor }) => textColor || 'var(--color-grayscale-black)'};
`;

const AlertBannerIcon = styled.div`
  margin-top: 0 !important;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

const AlertBannerText = styled.div``;

const AlertBannerTitle = styled.div`
  font-weight: 600;
  margin-right: 4px;
  display: inline-block;
`;

const CloseButton = styled.div`
  flex: 1;
  text-align: right;
  width: 16px;
  height: 16px;
`;

const StyledTimesIcon = styled(TimesIcon)`
  cursor: pointer;
`;
