import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

export const parseSelectedIds = (values: string): number[] => values.split(',').map((id) => Number(id));
export const parseSelectedValues = (values: string): string[] => values.split(',');

type LookupKey = number | string;

const mapToDropdownOptions = <T>(
  selectedIds: LookupKey[],
  allOptions: T[],
  getKey: (option: T) => LookupKey,
  mapToDropdown: (option: T) => DropdownMenuOption
): DropdownMenuOption[] =>
  selectedIds
    .map((id) => {
      const option = allOptions.find((opt) => getKey(opt) === id);
      return option ? mapToDropdown(option) : null;
    })
    .filter((option): option is DropdownMenuOption => option !== null);

type ParseFunction = (values: string) => (number | string)[];

export const getMultiSelectedOptions = <T>(
  values: string,
  allOptions: T[],
  parseFn: ParseFunction,
  getKey: (option: T) => number | string,
  mapToDropdown: (option: T) => DropdownMenuOption
): DropdownMenuOption[] => {
  const selectedIds = parseFn(values);
  return mapToDropdownOptions(selectedIds, allOptions, getKey, mapToDropdown);
};
