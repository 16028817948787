import React, { useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import GeneralActions from 'src/app_deprecated/actions/GeneralActions';

import { LinkButton } from 'src/app/components/lib/button/link-button';
import { DropdownSingle } from 'src/app/components/lib/dropdown';
import { generalAtom } from 'src/app/state/general';
import { getRoomOptions } from 'src/app/utils/rooms';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

type TerminalDetails = {
  RoomId?: number | null;
  TemindalId?: number | null;
  TerminalName?: string | null;
};

export type Room = {
  CheckOutRoom: string | null;
  CultivationStage: string | null;
  DispensaryLimitedRoom: string | null;
  DispensaryRestrictedRoom: string | null;
  EcommerceRoom: string | null;
  ExamRoom: string | null;
  ExternalId: string | null;
  InspectionRoom: string | null;
  InventoryRoom: string | null;
  IsMsuOwned: boolean | null;
  LocationType: string | null;
  MedicationRoom: string | null;
  MenuList: [];
  PosRoom: string | null;
  PreOrderRoom: string | null;
  PublishToMenus: boolean | null;
  QuarantineRoom: string | null;
  RoomArea: number | null;
  RoomAreaUnits: string | null;
  RoomId: number | null;
  RoomName: string | null;
  RoomPurpose: string | null;
  TerminalDetails: TerminalDetails[];
  Terminals: [];
  VaultRoom: string | null;
  WaitingRoom: string | null;
};

export type RoomsDropdownProps = {
  automationId?: string;
  disabled?: boolean;
  filterOutRoomId?: number;
  getLabel?: boolean;
  helpText?: string;
  hideNonInventoryRooms?: boolean;
  label?: string;
  labelPlacement?: 'start' | 'top';
  onChange?: (value: DropdownMenuOption | number | string) => void;
  refreshButtonAutomationId?: string;
  required?: boolean;
  resetButtonAutomationId?: string;
  rooms?: Room[];
  searchAutomationId?: string;
  value?: number | string;
};

function RoomsDropdown({
  automationId,
  filterOutRoomId,
  onChange,
  disabled,
  label = 'Room:',
  value,
  required = false,
  hideNonInventoryRooms = false,
  labelPlacement,
  helpText = 'Select room',
  getLabel = false,
  refreshButtonAutomationId,
  resetButtonAutomationId,
  searchAutomationId,
  ...props
}: RoomsDropdownProps) {
  const { rooms } = useRecoilValue(generalAtom);
  const options = props.rooms?.length ? props.rooms : rooms || [];

  useEffect(() => {
    if (!rooms || !rooms.length) {
      GeneralActions.getRooms();
    }
  }, []);

  const filteredOptions = getRoomOptions({ rooms: options, hideNonInventoryRooms, filterOutRoomId });

  const displayValue = filteredOptions?.find?.((option) => option.id === String(value))?.id ?? '';

  return (
    <DropdownSingle
      automationId={automationId}
      disabled={disabled}
      footerContent={
        <LinkButton
          automationId={refreshButtonAutomationId}
          key={refreshButtonAutomationId}
          label='Refresh'
          onClick={() => {
            GeneralActions.getRooms(true);
          }}
        />
      }
      helpText={helpText}
      label={label}
      labelPlacement={labelPlacement}
      options={filteredOptions}
      required={required}
      resetButtonAutomationId={resetButtonAutomationId}
      searchAutomationId={searchAutomationId}
      setValue={(val) => onChange(getLabel ? val : val.id)}
      value={displayValue}
    />
  );
}

export default RoomsDropdown;
