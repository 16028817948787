import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

export enum UrlParams {
  SEARCH = 'search',
  STATUS = 'status',
}

export enum Status {
  INCOMPLETE = 0,
  IN_PROGRESS = 1,
  COMPLETE = 2,
}

export enum CostType {
  Product = 1,
  Calculated = 2,
  Other = 3,
}

export const ASSEMBLY_LIST_PAGE_TITLE = 'Assemblies';
export const NEW_ASSEMBLY_PAGE_TITLE = 'New Assembly';
export const SEARCH_ASSEMBLIES_PLACEHOLDER = 'Search assemblies...';
export const ERROR_FETCHING_ASSEMBLY_MSG = 'Error fetching assembly data';
export const ASSEMBLY_STATUS_OPTIONS: DropdownMenuOption[] = [
  { value: Status.INCOMPLETE, label: 'Incomplete', id: Status.INCOMPLETE },
  { value: Status.IN_PROGRESS, label: 'In Progress', id: Status.IN_PROGRESS },
  { value: Status.COMPLETE, label: 'Complete', id: Status.COMPLETE },
];
// Info Messages
export const PROCESSING_JOB_INFO =
  'This information collected in the associated Bill of Material will be used to create or associate a processing job type with a new processing job when starting this assembly.';
// Error Messages
export const NAME_LENGTH_MESSAGE = 'Name must be less than 200 characters';
export const REQUIRED_BOM_MESSAGE = 'Bill of Materials is required';
export const START_DATE_REQUIRED_MESSAGE = 'Estimated Start Date is required';
export const PRODUCT_REQUIRED_MESSAGE = 'Product is required';
export const ROOM_REQUIRED_MESSAGE = 'Room is required';
export const VENDOR_REQUIRED_MESSAGE = 'Vendor is required';
export const PACKAGE_ID_REQUIRED_MESSAGE = 'Package ID is required';
export const BATCH_REQUIRED_MESSAGE = 'Batch is required';

export const EMPTY_OUTPUTS_HEADER = 'No outputs';
export const EMPTY_OUTPUTS_BODY = 'Select a bill of materials above to build this assembly with.';
export const FAILED_TO_LOAD_BOM = 'Failed to load bill of materials';
