import _ from 'lodash';

/* eslint-disable prettier/prettier */
const ratios = [
  { fromUnitId: 1, toUnitId: 1, ratio: 1.0 },
  { fromUnitId: 2, toUnitId: 2, ratio: 1.0 },
  { fromUnitId: 3, toUnitId: 3, ratio: 1.0 },
  { fromUnitId: 4, toUnitId: 4, ratio: 1.0 },
  { fromUnitId: 5, toUnitId: 5, ratio: 1.0 },
  { fromUnitId: 6, toUnitId: 6, ratio: 1.0 },
  { fromUnitId: 7, toUnitId: 7, ratio: 1.0 },
  { fromUnitId: 8, toUnitId: 8, ratio: 1.0 },
  { fromUnitId: 9, toUnitId: 9, ratio: 1.0 },
  { fromUnitId: 2, toUnitId: 4, ratio: 0.00026417 },
  { fromUnitId: 2, toUnitId: 5, ratio: 0.001 },
  { fromUnitId: 2, toUnitId: 9, ratio: 0.033814 },
  { fromUnitId: 4, toUnitId: 2, ratio: 3785.41 },
  { fromUnitId: 4, toUnitId: 5, ratio: 3.78541 },
  { fromUnitId: 4, toUnitId: 9, ratio: 128.0 },
  { fromUnitId: 5, toUnitId: 4, ratio: 0.264172 },
  { fromUnitId: 5, toUnitId: 2, ratio: 1000.0 },
  { fromUnitId: 5, toUnitId: 9, ratio: 33.814 },
  { fromUnitId: 9, toUnitId: 4, ratio: 0.0078125 },
  { fromUnitId: 9, toUnitId: 5, ratio: 0.0295735 },
  { fromUnitId: 9, toUnitId: 2, ratio: 29.5735 },
  { fromUnitId: 3, toUnitId: 6, ratio: 0.00220462 },
  { fromUnitId: 3, toUnitId: 7, ratio: 1000.0 },
  { fromUnitId: 3, toUnitId: 8, ratio: 0.035274 },
  { fromUnitId: 6, toUnitId: 3, ratio: 453.592 },
  { fromUnitId: 6, toUnitId: 7, ratio: 453592.0 },
  { fromUnitId: 6, toUnitId: 8, ratio: 16.0 },
  { fromUnitId: 7, toUnitId: 3, ratio: 0.001 },
  { fromUnitId: 7, toUnitId: 6, ratio: 0.0000022 },
  { fromUnitId: 7, toUnitId: 8, ratio: 0.00003527 },
  { fromUnitId: 8, toUnitId: 3, ratio: 28.3495 },
  { fromUnitId: 8, toUnitId: 6, ratio: 0.0625 },
  { fromUnitId: 8, toUnitId: 7, ratio: 28349.5 },
  { fromUnitId: 10, toUnitId: 3, ratio: 1000.0 },
  { fromUnitId: 10, toUnitId: 6, ratio: 2.204642 },
  { fromUnitId: 10, toUnitId: 7, ratio: 1000000.0 },
  { fromUnitId: 10, toUnitId: 8, ratio: 35.274 },
  { fromUnitId: 3, toUnitId: 10, ratio: 0.001 },
  { fromUnitId: 6, toUnitId: 10, ratio: 0.453592 },
  { fromUnitId: 7, toUnitId: 10, ratio: 0.000001 },
  { fromUnitId: 8, toUnitId: 10, ratio: 0.0283495 },
  { fromUnitId: 10, toUnitId: 10, ratio: 1.0 },
];
/* eslint-enable prettier/prettier */

export { ratios as conversionRatios };

export default {
  QUANTITY: 1,
  MILLILITERS: 2,
  GRAMS: 3,
  GALLONS: 4,
  LITERS: 5,
  POUNDS: 6,
  MILLIGRAMS: 7,
  OUNCES: 8,
  FLUID_OUNCES: 9,
  KILOGRAMS: 10,
  UnitTypes: {
    QUANTITY: 1,
    WEIGHT: 2,
    VOLUME: 3,
  } as const,
  convertQuantity(qty, fromUnitId, toUnitId) {
    const x = _.find(ratios, (r) => r.fromUnitId === fromUnitId && r.toUnitId === toUnitId);
    if (x) {
      return x.ratio * qty;
    }
    return NaN;
  },
  getMultiplier(product, multiplierType) {
    if (product.UnitTypeId !== 1) {
      return 1;
    }

    let muliplier = product.Grams;
    if (multiplierType === 'NetWeight') {
      muliplier = product.NetWeight;
    } else if (multiplierType === 'FlowerEquivalent') {
      muliplier = product.FlowerEquivalent;
    } else {
      muliplier = product.Grams;
    }

    return muliplier;
  },
  calculateQuantity(fromUnitId, fromQuantity, toUnitId, toProductGrams = null, fromProductGrams = null) {
    if (fromUnitId === toUnitId) {
      return {
        quantity: fromQuantity,
        unitId: toUnitId,
      };
    }

    if (toUnitId === this.QUANTITY && !!toProductGrams) {
      const fromGrams = this.convertQuantity(fromQuantity, fromUnitId, 3);
      const fromQty = _.round(fromGrams / toProductGrams, 5);
      return {
        quantity: fromQty,
        unitId: this.QUANTITY,
      };
    }

    if (fromUnitId === this.QUANTITY && !!fromProductGrams) {
      const toGrams = fromQuantity * fromProductGrams;
      const toQuantity = this.convertQuantity(toGrams, this.GRAMS, toUnitId);
      return {
        quantity: toQuantity,
        unitId: toUnitId,
      };
    }

    let quantity = this.convertQuantity(fromQuantity, fromUnitId, toUnitId);
    if (!quantity || isNaN(quantity)) {
      quantity = '';
    }
    return {
      quantity,
      unitId: toUnitId,
    };
  },
  getUnitTypeId(unitId) {
    if (unitId === 1) {
      return 1;
    }
    if ([3, 6, 7, 8, 10].indexOf(unitId) > -1) {
      return 2;
    }
    if ([2, 4, 5, 9].indexOf(unitId) > -1) {
      return 3;
    }
  },
  renderUnit(val: any, abbrev: string): string {
    if (['', null, undefined, NaN].indexOf(val) > -1) {
      return '';
    }

    if (['', null, undefined, 'qty'].indexOf(abbrev) > -1) {
      return val.toFixed(0);
    }

    return `${val.toFixed(2)} ${abbrev}`;
  },
  defaultUnitIds: {
    // 1: this.QUANTITY,
    // 2: this.GRAMS,
    // 3: this.MILLILITERS,
  },
  abbreviations: {
    1: 'qty',
    2: 'ml',
    3: 'g',
    4: 'Gal',
    5: 'L',
    6: 'lb',
    7: 'mg',
    8: 'oz',
    9: 'fl oz',
    10: 'kg',
    11: '%',
  },
  names: {
    1: 'quantity',
    2: 'Milliliter',
    3: 'Gram',
    4: 'Gallon',
    5: 'Liter',
    6: 'Pounds',
    7: 'Milligram',
    8: 'Ounce',
    9: 'Fluid Ounce',
    10: 'Kilogram',
  },
  unitNameToID: {
    qty: 1,
    ea: 1,
    quantity: 1,
    each: 1,
    ml: 2,
    milliliter: 2,
    g: 3,
    gram: 3,
    mg: 7,
    milligram: 7,
    oz: 8,
    ounce: 8,
  },
  abbreviationsTHCCBD: {
    1: 'mg',
    2: '%',
    3: 'mg per g',
    4: 'N.D.',
    5: '<LOQ',
  },
  abbreviationsTHCCBDNameToUnit: {
    mg: 1,
    '%': 2,
    pct: 2,
    'mg per g': 3,
    'mg/g': 3,
    'N.D.': 4,
    '<LOQ': 5,
  },
} as const;
