export type LocProfile = {
  [key in Features]: boolean;
} & {
  DebitProcessor: string;
  DefaultCustomerTypeId: number;
  DefaultDeliveryWindowEnd: string;
  DefaultDeliveryWindowStart: string;
  IntegratedState: string;
  LicenseTypeIds: Record<string, unknown>[];
  UseLabPackageDate: boolean;
  UseMETRCUseByDate: boolean;
};

export enum Features {
  AcceptCanPay = 'AcceptCanPay',
  AcceptHypur = 'AcceptHypur',
  AcceptLinx = 'AcceptLinx',
  APIActionsQueued = 'APIActionsQueued',
  AuditCustomerInfoAccess = 'AuditCustomerInfoAccess',
  BulkRegisterTransactions = 'BulkRegisterTransactions',
  BulkUploadLabData = 'BulkUploadLabData',
  CanadaBarcodeScanning = 'CanadaBarcodeScanning',
  CanUseMassDPHIntegration = 'CanUseMassDPHIntegration',
  CheckMetrcOnHoldInventory = 'CheckMetrcOnHoldInventory',
  CheckOutCheck = 'CheckOutCheck',
  CheckOutCreditCard = 'CheckOutCreditCard',
  CheckOutDebit = 'CheckOutDebit',
  CombinePackages = 'CombinePackages',
  CustomerPaymentSurcharge = 'CustomerPaymentSurcharge',
  CustomerVisitReason = 'CustomerVisitReason',
  DiscountWizardEnablement = 'DiscountWizardEnablement',
  DualPricing = 'DualPricing',
  EcomV2 = 'EcomV2',
  EnableAGCOReport = 'EnableAGCOReport',
  EnableAutoMatchingDuringReceive = 'EnableAutoMatchingDuringReceive',
  EnableDelivery = 'EnableDelivery',
  EnableManualPayments = 'EnableManualPayments',
  EnableNutrientTracking = 'EnableNutrientTracking',
  EnablePlantSearch = 'EnablePlantSearch',
  EnableUntrackedInventoryTransfers = 'EnableUntrackedInventoryTransfers',
  FixedTablesBeta = 'FixedTablesBeta',
  FormValidation = 'FormValidation',
  GeneriPayG2 = 'GeneriPayG2',
  HasBioTrack = 'HasBioTrack',
  HasDutchiePay = 'HasDutchiePay',
  HasJory = 'HasJory',
  HasLooker = 'HasLooker',
  HasLookerExplorer = 'HasLookerExplorer',
  HasMCDMS = 'HasMCDMS',
  HasMETRC = 'HasMETRC',
  HasMMUR = 'HasMMUR',
  HasPaymentsHub = 'HasPaymentsHub',
  HasPaymentsHubWhiteLabel = 'HasPaymentsHubWhiteLabel',
  HasPaynetworx = 'HasPaynetworx',
  HasPercentPaymentFees = 'HasPercentPaymentFees',
  HasPlanetPayments = 'HasPlanetPayments',
  HasPurchasOrders = 'HasPurchasOrders',
  HasReThink = 'HasReThink',
  HasUtahICS = 'HasUtahICS',
  HasVCCB = 'HasVCCB',
  HasVirginiaBioTrack = 'HasVirginiaBioTrack',
  HideMarketingHighlights = 'HideMarketingHighlights',
  InvReconUpdateInBulk = 'InvReconUpdateInBulk',
  IsLeaflyEnabled = 'IsLeaflyEnabled',
  IsLicensed = 'IsLicensed',
  IsWeedMapsEnabled = 'IsWeedMapsEnabled',
  LocationBasedDelivery = 'LocationBasedDelivery',
  LocationSyncTool = 'LocationSyncTool',
  LoyaltyAsCartDiscount = 'LoyaltyAsCartDiscount',
  LoyaltyAsDiscount = 'LoyaltyAsDiscount',
  ManagerPinRequiredInventoryAdjustmentThreshold = 'ManagerPinRequiredInventoryAdjustmentThreshold',
  Manufacturing = 'Manufacturing',
  MedAndRecShareBasePrice = 'MedAndRecShareBasePrice',
  // TODO: remove with deletion of pos.traceability.display-metrc-batch-mode-option.rollout
  MetrcBatchMode = 'MetrcBatchMode',
  METRCFinishPackages = 'METRCFinishPackages',
  NewIAManufacturingModule = 'NewIAManufacturingModule',
  NoDoublePreOrder = 'NoDoublePreOrder',
  NYSPMP = 'NYSPMP',
  OrdersbyProduct = 'OrdersbyProduct',
  PlantReconciliation = 'PlantReconciliation',
  POSStatus = 'POSStatus',
  RecFlowerEquiv = 'RecFlowerEquiv',
  ScanParentPackageSerialNumber = 'ScanParentPackageSerialNumber',
  SendWetWeightToInventory = 'SendWetWeightToInventory',
  ShowBulkCustomerPiiWipeTool = 'ShowBulkCustomerPiiWipeTool',
  ShowCultivationIntegrationReconcilation = 'ShowCultivationIntegrationReconcilation',
  ShowCultivationTax = 'ShowCultivationTax',
  ShowExciseTaxOnReceive = 'ShowExciseTaxOnReceive',
  ShowExternalLoyalty = 'ShowExternalLoyalty',
  ShowLeafLogixLoyalty = 'ShowLeafLogixLoyalty',
  ShowPinDebitNonCashAdjustment = 'ShowPinDebitNonCashAdjustment',
  ShowSourcePackage = 'ShowSourcePackage',
  ShowStateBypass = 'ShowStateBypass',
  ShowZeroInventory = 'ShowZeroInventory',
  TaxInclusivePricing = 'TaxInclusivePricing',
  TrackImmaturePlantsIndividually = 'TrackImmaturePlantsIndividually',
  TrackInventoryIds = 'TrackInventoryIds',
  UseAdilas = 'UseAdilas',
  UseAlpineIQ = 'UseAlpineIQ',
  UseBioMauris = 'UseBioMauris',
  UseBioTrack = 'UseBioTrack',
  UseCCRS = 'UseCCRS',
  UseDistillationMethod = 'UseDistillationMethod',
  UseEmailMarketing = 'UseEmailMarketing',
  UseFeesDonations = 'UseFeesDonations',
  UseFlowerEquivalencyDefinition = 'UseFlowerEquivalencyDefinition',
  UseFyllo = 'UseFyllo',
  UseIDScan = 'UseIDScan',
  UseInfusions = 'UseInfusions',
  UseLeaf = 'UseLeaf',
  UseLeafLink = 'UseLeafLink',
  UseManufacturers = 'UseManufacturers',
  UseMCDMS = 'UseMCDMS',
  UseMETRC = 'UseMETRC',
  UseMETRCUseByDate = 'UseMETRCUseByDate',
  UseMMUR = 'UseMMUR',
  UseMn = 'UseMn',
  UseMultiPackageCreator = 'UseMultiPackageCreator',
  UseMultiStopManifests = 'UseMultiStopManifests',
  UseNewPOS = 'UseNewPOS',
  UseOCS = 'UseOCS',
  UseOnFleet = 'UseOnFleet',
  UseOrderFulfill = 'UseOrderFulfill',
  UsePinLogin = 'UsePINLogin',
  UsePMP = 'UsePMP',
  UseQuickBooks = 'UseQuickBooks',
  UseSkylightDebitIntegration = 'UseSkylightDebitIntegration',
  UseSpinDebitIntegration = 'UseSpinDebitIntegration',
  UseSpringBig = 'UseSpringBig',
  UseSprout = 'UseSprout',
  UseSSO = 'UseSSO',
  UseTookan = 'UseTookan',
  UseTranCloudDebitIntegration = 'UseTranCloudDebitIntegration',
  UseTrym = 'UseTrym',
  UseUtahICS = 'UseUtahICS',
  UseVCCB = 'UseVCCB',
  UseVirginiaBioTrack = 'UseVirginiaBioTrack',
  UseWorkWave = 'UseWorkWave',
  ViewOrgManagement = 'ViewOrgManagement',
  WeedmapsV2 = 'WeedmapsV2',
  WholesaleCarsAndDrivers = 'WholesaleCarsAndDrivers',
}
