/**
 * Launch Darkly flags
 * @example Usage:
 * import { LD } from 'src/app/constants/launch-darkly-flags';
 * import { useLDFlag } from 'src/app/hooks/use-ld-flag';
 *
 * const thingRollout = useLDFlag(LD.THING_ROLLOUT, false);
 * or
 * const thingRollout = useLDFlag<boolean>(LD.THING_ROLLOUT);
 *
 * @function useLDFlag - src/app/hooks/use-ld-flag.ts
 *
 * Deprecation/Removal Steps:
 * 1. Add example deprecated comment above the flag you are removing.
 * 2. Right click key and select "Find All References" to find all usages.
 * 3. Remove the flag from the enum.
 *
 * @see Naming Conventions
 * https://dutchie.atlassian.net/wiki/spaces/EN/pages/19934543881/LaunchDarkly+Flag+Naming+Convention
 * `{charter}.{module/team}.{name}.{rollout|experiment|entitlement|operational}`
 */
export enum LD {
  /**
   * @deprecated Fully enabled
   */
  A_THING_ROLLOUT_DEPRECATED_EXAMPLE = 'backoffice.platform.deprecated-example.rollout',
  ASYNC_REPORTS_ROLLOUT = 'pos.backoffice.async-reports.rollout',
  BILL_OF_MATERIALS_ROLLOUT = 'backoffice.bill-of-materials-and-assemblies.rollout',
  BULK_UPDATE_CUSTOMER_PAGINATION = 'pos.backoffice.bulk-update-pagination.rollout',
  BULKINVENTORYV3 = 'pos.backoffice-tools.bulk-updater.bulkupdaterinventoryV3.rollout',
  CREATE_BROWSER_ROUTER_ROLLOUT = 'backoffice.update-router-to-create-browser-router.rollout',
  CULTIVATION_REFACTOR_ROLLOUT = 'pos.backoffice.cultivation-refactor.rollout',
  CUSTOMER_ALLOTMENT_OVERRIDE_ROLLOUT = 'pos.backoffice-allotment-override.rollout',
  CUSTOMER_DEDUPE_KILLSWITCH_ROLLOUT = 'pos.backoffice.customer-dedupe-tool-killswitch.operational',
  CUSTOMERS_CAMPAIGN_UI_BARGRAPH_FIX_ROLLOUT = 'core.customers.campaign-ui-bargraph-fix.rollout',
  CUSTOMERS_PROJECT_CHUNK_ROLLOUT = 'pos.backoffice.customers.project-chunk.rollout',
  CUSTOMERS_QUERY_BUILDER_CHUNK_ROLLOUT = 'pos.backoffice.customers.project-chunk.query-builder.rollout',
  CUSTOMERS_SEGMENTS_REPORTS_DEMO_DATA_ROLLOUT = 'pos.backoffice-customers.segments-overview-demo-data.rollout',
  CUSTOMERS_SEGMENTS_REPORTS_ROLLOUT = 'pos.backoffice-customers.segments-overview.rollout',
  CUSTOMERS_SEGMENTS_V2 = 'core.customers.segmentsV2.rollout',
  DEBOUNCE_CUSTOMERS = 'pos.backoffice.debounce-customers.rollout',
  DISABLE_USER_PASSWORD_LOGIN = 'sso.disable-user-password-login',
  DISCOUNT_PAYMENT_RESTRICTIONS = 'core.cats.discount-payment-restrictions.operational',
  DISCOUNT_WIZARD = 'core.cats-pos.discount-wizard.rollout',
  DISPLAY_METRC_BATCH_MODE_OPTION = 'pos.traceability.display-metrc-batch-mode-option.rollout',
  FINANCIALS_TAB_ROLLOUT = 'pos.backoffice.financials-tab.rollout',
  EMAIL_MARKETING_PERMISSIONS_ROLLOUT = 'pos.backoffice-email-marketing-permissions.rollout',
  FIX_PBB_FEE_PERMISSIONS_ROLLOUT = 'fintech.retail-payments.fix-pbb-fee-permissions.rollout',
  GENERAL_BACKOFFICE_HEADER_BANNER = 'backoffice.platform.general-header-banner.entitlement',
  GENERIPAY_BIRCHMOUNT = 'fintech.retail-payments.birchmount-gift-card-redemption.rollout',
  GENERIPAY_SPIN = 'fintech.retail-payments.spin-generipay-adapter.rollout',
  GENERIPAY_WEDGE = 'fintech.retail-payments.wedge-generipay-adapter.rollout',
  GET_INTEGRATIONS_V5 = 'pos.get-integrations-v5.rollout',
  GQL_ERROR_MITIGATION = 'pos.backoffice.gql-error-mitigation.rollout',
  HOMEPAGE_MARKETING = 'pos.backoffice.homepage.experiment',
  INVENTORY_AUDIT_V2_ADDITIONAL_FIELDS = 'backoffice.product.inventory-audit-brand-batch-strain.rollout',
  INVENTORY_REPORTS_V2_ROLLOUT = 'platform.reports.inventory-performance-report-v2.rollout',
  INVENTORY_STORE_DEPRECATION = 'pos.backoffice.deprecate-get-inventory.rollout',
  KILL_SWITCH_BY_DOMAIN_OR_PAGE = 'pos.backoffice.kill-switch-by-domain.killswitch',
  LOG_OUT_ON_SESSION_REVOCATION = 'pos.backoffice.log-out-on-session-revocation.rollout',
  LOGIN_MARKETING = 'pos.backoffice.login.experiment',
  MANUAL_EXTERNAL_LOYALTY_DISCOUNTS = 'core.cats-pos.manual-external-loyalty-discounts.rollout',
  METRC_RETAIL_ID = 'pos.traceability.metrc-retail-id.rollout',
  MOVE_METRC_PULL_TOOL_ROLLOUT = 'pos.backoffice.move-metrc-pull-tool.rollout',
  NON_SANDBOX_BANNER_ALERT = 'backoffice.platform.non-sandbox-banner-alert.rollout',
  PAYMENTS_HUB_DEVICE_SERIAL_VALIDATION = 'fintech.retail-payments.hub-device-serial-validation.rollout',
  PAYMENTS_HUB_UPSELLS_POC = 'fintech.retail-payments.dutchie-hub-upsell-poc.rollout',
  POS_TRACEABILITY_BIOTRACK_REQUEST_DATE = 'pos.traceability.biotrack-request-date.rollout',
  PROJECT_CHUNK_CAMPAIGNS = 'core.chunk-tiger-team.mailjet-campaigns.rollout',
  PUBLIC_API_KEY_BULK_ACTION_ROLLOUT = 'publicapi.superuser-bulk-actions.rollout',
  PUBLIC_API_KEY_DELETE_BUTTON_ROLLOUT = 'publicapi.superuser-delete-button.rollout',
  REFETCH_INVENTORY_ON_LOC_CHANGE = 'pos.backoffice.refetch-inventory-on-loc-change.rollout',
  REGISTER_WEB_USB_ENABLED = 'pos.register.settings-ui.rollout',
  SEGMENT_DISCOUNTS = 'pos.backoffice.customers.segment-discounts.rollout',
  SEPARATE_PRINT_COMMANDS = 'pos.register.separate-print-commands.rollout',
  SERVER_MIGRATION_CONFIG = 'pos.platform.database-migration-configs.operational',
  SERVER_PAGINATED_DROPDOWN_FIX = 'backoffice.server-paginated-downdown-fix.rollout',
  SHOW_LOC_IN_USER_EVENT_LOGS = 'pos.platform.add-location-to-user-event-logs',
  SMALL_BUSINESS_PACKS = 'pos.tools.small-business-packs',
}
