import React from 'react';

import styled from 'styled-components';

import { Button } from 'src/app/components/lib/button';
import { Content, Header } from 'src/app/layout/error/styled-components';

import { useDecagon } from '../header/decagon/use-decagon';

type ErrorMessageProps = {
  additionalContent?: React.ReactElement;
  errorMessage?: string;
};

export default function ErrorMessage({ errorMessage, additionalContent }: ErrorMessageProps) {
  const { decagon } = useDecagon();

  const handleClick = () => {
    decagon?.open();
  };

  return (
    <Card>
      <Header>Something went wrong</Header>
      <Content>
        {errorMessage ?? "We'd be grateful if you could report this error as your feedback is very important to us."}
      </Content>
      <ContactSupportButton
        hasMarginBottom={!!additionalContent}
        label='Contact support'
        variant={additionalContent ? 'secondary' : 'primary'}
        onClick={handleClick}
      />
      {additionalContent}
    </Card>
  );
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: var(--sizes-100);
  border-radius: 12px;
  background-color: var(--color-gray-10);
  color: var(--color-gray-80);
  font-size: 0.875rem;
  line-height: 1.43;
  width: 100%;
`;

const ContactSupportButton = styled(Button)<{ hasMarginBottom: boolean }>`
  margin-bottom: ${({ hasMarginBottom }) => (hasMarginBottom ? 'var(--sizes-40)' : '0')};
`;
