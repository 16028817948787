import { isArray } from 'lodash';

/* Enumerations */

export enum LabUnitId {
  MG = 1,
  PERCENT = 2,
  MG_G = 3,
  ND = 4,
  LOQ = 5,
}

export const labUnitIdToDescription: Record<LabUnitId, string> = {
  [LabUnitId.LOQ]: '<LOQ',
  [LabUnitId.MG]: 'mg',
  [LabUnitId.MG_G]: 'mg/g',
  [LabUnitId.ND]: 'n.d.',
  [LabUnitId.PERCENT]: '%',
} as const;

/* Types and helpers */

export type LabResults = {
  [key: `${string}Value`]: number | null;
  [key: `${string}Unit`]: LabUnitId | null;
} | null;

type FormatLabResultParams = {
  labKey: string;
  labResults?: LabResults | LabResults[] | null;
};

const parseLabValueAndUnit = ({
  labResults,
  labKey,
}: FormatLabResultParams): { unitId: LabUnitId | null; value: number | null } => {
  if (!labResults) {
    return { value: null, unitId: null };
  }

  const labs = isArray(labResults) ? labResults[0] : labResults;
  const valueKey = `${labKey.toLowerCase()}Value` as const;
  const unitKey = `${labKey.toLowerCase()}Unit` as const;

  return { value: labs?.[valueKey] ?? null, unitId: labs?.[unitKey] ?? null };
};

/* Main function */

/**
 * Formats lab results by lab metric (e.g. THC, CBD, etc.).
 * The lab results format matches what is returned from graphQL queries.
 * @param labResults - The lab results object or array of lab results.
 * @param labKey - The lab metric key (case-insensitive).
 * @returns The formatted lab result string.
 */
export function formatLabResults({ labResults, labKey }: FormatLabResultParams): string {
  const { value, unitId } = parseLabValueAndUnit({ labResults, labKey });

  // If the unit id is ND or LOQ, return the description only
  if (unitId === LabUnitId.LOQ || unitId === LabUnitId.ND) {
    return labUnitIdToDescription[unitId];
  }

  // If the value is null and the unit id is not ND or LOQ, return empty string
  if (value === null || unitId === null) {
    return '';
  }

  const formatValue = (value: number) => {
    const rounded = value.toFixed(2);
    return parseFloat(rounded);
  };

  // Otherwise, return the formatted lab result
  return `${formatValue(value)} ${labUnitIdToDescription[unitId]}`;
}
