import { useQuery } from '@tanstack/react-query';

import { fetchData } from 'src/app/queries';

import type { QueryKey } from '@tanstack/react-query';

const permissionsQueryKey: QueryKey = ['permissions'];

const GET_PERMISSIONS = 'api/permissions/get';

type PermissionsResponse = number[];

export function usePermissionsQuery<T = number[]>(params?: {
  enabled?: boolean;
  select?: (data: number[]) => T;
  staleTime?: number;
}) {
  const { enabled = true, staleTime = Infinity, select } = params ?? {};

  return useQuery({
    queryKey: permissionsQueryKey,
    queryFn: () => fetchData<PermissionsResponse>(GET_PERMISSIONS),
    enabled,
    staleTime,
    select,
  });
}
