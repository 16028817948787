import React, { lazy, Suspense } from 'react';

import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';

const V1 = lazy(() => import('./v1'));
const V2 = lazy(() => import('./v2'));

const componentMap = {
  V1,
  V2,
};

export function Marketing() {
  const flag = useLDFlag<{ variation: string }>(LD.LOGIN_MARKETING);
  const Component = componentMap[flag?.variation ?? 'V1'] ?? V1;

  return (
    <Suspense fallback={<div />}>
      <Component />
    </Suspense>
  );
}
