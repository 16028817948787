import { useQuery, useQueries } from '@tanstack/react-query';

import {
  fetchMany,
  paginatedExportCommonQueryProps,
  paginatedExportNumberOfRequests,
  paginatedExportPageProps,
} from 'src/app/queries/graphql';
import { GetBatchesWithLabsDocument } from 'src/gql/graphql';

import { extendBatchWithLabs } from './extend';
import { batchKeys } from './query-key-factory';

import type { AbridgedFilterInput, QueryProps } from '../../types/gql-filtering-and-pagination';
import type { UseQueryOptionsForUseQueries } from 'src/app/queries/graphql';
import type { BatchesWithLabsFragment, TblBatchFilterInput, TblBatchSortInput } from 'src/gql/graphql';

export type UseBatchesQueryProps = QueryProps<AbridgedFilterInput<TblBatchFilterInput>, TblBatchSortInput>;

export function useBatchesWithLabsQuery(queryProps: UseBatchesQueryProps) {
  return useQuery({
    queryKey: batchKeys.many(queryProps),
    queryFn: async () => {
      const batchesWithLabsData = await fetchMany(GetBatchesWithLabsDocument, queryProps);
      const items = batchesWithLabsData?.items?.filter(Boolean) as BatchesWithLabsFragment[];

      return {
        ...batchesWithLabsData,
        items: items.map((item) => extendBatchWithLabs(item)),
      };
    },
    keepPreviousData: true,
  });
}

type UseBatchesQueriesProps = {
  queryProps: QueryProps<AbridgedFilterInput<TblBatchFilterInput>, TblBatchSortInput>;
  totalCount: number;
};

export function useBatchesWithLabsQueries({ queryProps, totalCount }: UseBatchesQueriesProps) {
  const queries: UseQueryOptionsForUseQueries[] = [];
  const numberOfRequests = paginatedExportNumberOfRequests(totalCount);

  for (let i = 0; i < numberOfRequests; i += 1) {
    const alteredQueryProps = { ...queryProps, ...paginatedExportPageProps(i) };
    queries.push({
      queryKey: batchKeys.many(alteredQueryProps),
      queryFn: () => fetchMany(GetBatchesWithLabsDocument, alteredQueryProps),
      ...paginatedExportCommonQueryProps,
    });
  }

  return useQueries({ queries });
}
