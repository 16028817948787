import { useQuery } from '@tanstack/react-query';

import { ERROR_FETCHING_BOM_MSG } from 'src/app/pages/manufacturing/bill-of-materials/constants';
import { fetchOne } from 'src/app/queries/graphql';
import { useNotificationActions } from 'src/app/state/notifications';
import { GetBillOfMaterialDocument } from 'src/gql/graphql';

import { bomKeys } from './query-key-factory';

import type { QueryClient } from '@tanstack/react-query';
import type { GetBillOfMaterialQueryVariables } from 'src/gql/graphql';

type UseBOMQueryParameters = {
  bomId: GetBillOfMaterialQueryVariables['id'];
  enabled?: boolean;
  onError?: (error: unknown) => void;
  staleTime?: number;
};
export type GetBillOfMaterialDetailResponse = Awaited<ReturnType<typeof getBillOfMaterial>>;
export type BillOfMaterialDetailResponse = ReturnType<typeof useBillOfMaterialQuery>['data'];

export function useBillOfMaterialQuery({ bomId, enabled, onError }: UseBOMQueryParameters) {
  const notifications = useNotificationActions();
  return useQuery({
    queryFn: async () => fetchOne(GetBillOfMaterialDocument, { key: 'id', value: bomId }),
    queryKey: bomKeys.one(bomId),
    useErrorBoundary: true,
    enabled,
    onError: (value) => {
      notifications.error({ message: ERROR_FETCHING_BOM_MSG });
      onError?.(value);
    },
  });
}

export async function getBillOfMaterial(bomId: number, queryClient: QueryClient) {
  const queryFn = async () => fetchOne(GetBillOfMaterialDocument, { key: 'id', value: bomId });
  const data = await queryClient.fetchQuery({ queryFn, staleTime: 0, queryKey: bomKeys.one(bomId) });
  return data;
}
