import React, { useCallback, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import { useTabsWithQueryParams } from 'src/app/hooks/use-tabs-with-query-params';
import { useYupFormValidation } from 'src/app/hooks/use-yup-field-validation';
import { TABS } from 'src/app/pages/manufacturing/shared/constants';
import { useUpsertAssembly } from 'src/app/queries/manufacturing/upsert-assembly';
import { routes } from 'src/app/routes';
import { useNotificationActions } from 'src/app/state/notifications';

import { getAreQuantitiesValid, mapFormDataToUpsertPayload } from '../utils';

import { assemblySchema } from './schema';
import { AssemblyDetails } from './tabs/detail';
import { AssemblyDocuments } from './tabs/documents';
import { AssemblyProcessingJob } from './tabs/processing-jobs';
import { useAssemblyFormData } from './use-assembly-form-data';
import { useAssemblyFormStateContext } from './use-form-state';

import type { AssemblyDetailFormProps } from '.';

export const useAssemblyForm = ({ formType }: AssemblyDetailFormProps) => {
  const navigate = useNavigate();
  const navigateBackToListPage = () => navigate(routes.manufacturing.assemblies);

  // Form Data Fetching
  const {
    assemblyData,
    assemblyNotFound,
    integrations: { UseMetrcV2, UseMetrcProcessingJobs },
    locationSettings: { defaultCostType },
    dropdownOptions,
    isLoading: isLoadingFormData,
    hasError,
  } = useAssemblyFormData({ formType });
  const isEditing = formType === 'edit';

  const [{ formData, context, isDirty, isReadonly }, dispatch] = useAssemblyFormStateContext();
  const showBlockNavigationModal = useLDFlag(LD.CREATE_BROWSER_ROUTER_ROLLOUT, false);

  const canViewProcessingJobs = UseMetrcV2 && UseMetrcProcessingJobs;

  // Constants
  const tabs = [
    {
      label: TABS.details.label,
      queryParam: TABS.details.queryParam,
      renderTab: () => <AssemblyDetails dropdownOptions={dropdownOptions} />,
    },
    {
      label: TABS.documents.label,
      queryParam: TABS.documents.queryParam,
      renderTab: () => <AssemblyDocuments />,
    },
    ...(canViewProcessingJobs
      ? [
          {
            label: TABS.processingJobs.label,
            queryParam: TABS.processingJobs.queryParam,
            renderTab: () => <AssemblyProcessingJob />,
          },
        ]
      : []),
  ];

  // Hooks
  const { assemblyId } = useParams();
  const notifications = useNotificationActions();
  const { activeTab, setActiveTab } = useTabsWithQueryParams(tabs.map((t) => t.queryParam));

  const { mutate: upsertAssembly, isLoading: isSavingAssembly } = useUpsertAssembly();

  const onValidation = useCallback((errors) => dispatch({ type: 'set-errors', payload: errors }), [dispatch]);

  const { isValid: isFormDataValid } = useYupFormValidation({
    schema: assemblySchema,
    formData,
    onValidation,
    context,
  });

  const areQuantitiesValid = getAreQuantitiesValid(formData.outputs);

  // Derived state

  const isLoading = isLoadingFormData || isSavingAssembly;
  const isSaving = isSavingAssembly;
  const isValid = isFormDataValid && areQuantitiesValid;

  // Initialize state when editing
  useEffect(() => {
    if (isEditing && !isLoadingFormData && !hasError && assemblyData) {
      dispatch({ type: 'initialize-edit-assembly-state', payload: assemblyData });
    }
  }, [isEditing, isLoadingFormData, hasError, assemblyData, dispatch]);

  // Set form context
  useEffect(() => {
    dispatch({ type: 'set-context', payload: { useMetrcV2: UseMetrcV2, defaultCostType } });
  }, [dispatch, UseMetrcV2, defaultCostType]);

  const onSubmit = () => {
    const action = isEditing ? 'updated' : 'created';
    // Mutation Callbacks
    const onSuccess = () => {
      notifications.success({ message: `Assembly has been successfully ${action}` });
      dispatch({ type: 'set-is-dirty', payload: false });
      navigateBackToListPage();
    };
    const onError = (error) => {
      const message = error?.response?.data?.Message;
      const defaultMessage = `Failed to ${action.slice(0, -1)} Assembly`;
      notifications.error({ message: message || defaultMessage });
    };

    // Call Mutations
    const payload = mapFormDataToUpsertPayload({
      assemblyId: isEditing ? Number(assemblyId) : undefined,
      billOfMaterialsId: formData.billOfMaterialsId ?? 0,
      estimatedStartDate: formData.estimatedStartDate ?? new Date(),
      name: formData.name,
      outputs: formData.outputs,
    });
    upsertAssembly(payload, { onSuccess, onError });
  };

  return {
    activeTab,
    assemblyData,
    assemblyNotFound,
    showBlockNavigationModal,
    setActiveTab,
    dropdownOptions,
    onSubmit,
    navigateBackToListPage,
    tabs,
    isValid,
    isDirty,
    isLoading,
    hasError,
    isSaving,
    isReadonly,
  };
};
