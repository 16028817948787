import { useEffect, useState, useRef, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { FilterOperator } from 'src/app/queries/types/gql-filtering-and-pagination';
import { settingsAtom } from 'src/app/state/settings';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

import type { GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid-pro';
import type { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import type { ServerPaginatedTables } from 'src/app/constants/table-names-paginated';
import type {
  Direction,
  FilterDefinition,
  FilterModel,
  QueryProps,
  SortItem,
} from 'src/app/queries/types/gql-filtering-and-pagination';

/**
 * Base filter model definition, extended to allow for interfacing with state.
 */
export type TableFilterDefinition<T> = FilterDefinition<T> & { filterKey?: string };

export type UseServerTableControlsProps<TFilter, TSort> = {
  filterDefinitions: TableFilterDefinition<TFilter>[];
  initialSort: SortItem<TSort>[];
  tableName: ServerPaginatedTables;
};

export type TableProps = {
  filterMode: 'server';
  initialState: GridInitialStatePro;
  keepNonExistentRowsSelected: true;
  name: string;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  page: number;
  pageSize: number;
  paginationMode: 'server';
  rowsLoadingMode: 'server';
  sortingMode: 'server';
  usingServerTableControls: true;
};

export type UseServerTableControlsReturn<TFilter, TSort> = {
  getFilterValue: (key: string) => FilterDefinition<TFilter>['initialValue'];
  queryProps: QueryProps<TFilter, TSort>;
  setFilterValue: (key: string, value: FilterDefinition<TFilter>['initialValue']) => void;
  tableProps: TableProps;
};

export function useServerTableControlsWithModal<TFilter, TSort>({
  initialSort,
  filterDefinitions,
  tableName,
}: UseServerTableControlsProps<TFilter, TSort>): UseServerTableControlsReturn<TFilter, TSort> {
  const user = useRecoilValue(userDispensariesAtom);
  const {
    tableSettings,
    selectedLocation: { LocId },
  } = user;

  const userSettings = useRecoilValue(settingsAtom);
  const { RowsPerTable } = userSettings;
  const rowsPerTable: number = typeof RowsPerTable === 'string' ? parseInt(RowsPerTable, 10) : RowsPerTable;

  const [sortFields, setSortFields] = useState<string[]>([]);
  const [sortDirections, setSortDirections] = useState<Direction[]>([]);
  const [pageSize, setPageSize] = useState<number>(rowsPerTable);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const initialFilterValues = useMemo(
    () =>
      filterDefinitions.reduce<Record<string, FilterDefinition<TFilter>['initialValue']>>(
        (acc, { filterKey, initialValue }) => {
          const hasInitialValue = initialValue !== undefined;
          if (filterKey && hasInitialValue) {
            acc[filterKey] = initialValue;
          }
          return acc;
        },
        {}
      ),
    [filterDefinitions]
  );
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const currentTable = tableSettings.find((_table) => _table.table === tableName);
  const savedSort = currentTable?.layout?.filter((_column) => !!_column.sort.sort);

  const prevLocId = useRef(LocId);

  // Reset state when location changes
  useEffect(() => {
    if (prevLocId.current !== LocId) {
      setSortFields([]);
      setSortDirections([]);
      setCurrentPage(1);
    }
    prevLocId.current = LocId;
  }, [LocId]);

  // Compute sort from initial, saved, or state
  let computedSort: SortItem<TSort>[] = [...initialSort];
  if (sortFields.length) {
    computedSort = sortFields.map((field, index) => ({
      key: field as keyof TSort,
      direction: sortDirections[index],
    }));
  } else if (savedSort?.length) {
    computedSort = savedSort.map((_column) => ({
      key: _column.sort.field as keyof TSort,
      direction: _column.sort.sort as Direction,
    }));
  }

  const computedSortModel: GridSortItem[] = computedSort.map((_sort) => ({
    field: String(_sort.key),
    sort: (_sort.direction ? _sort.direction.toLowerCase() : 'asc') as GridSortDirection,
  }));

  function getFilterValue(key: string) {
    return filterValues[key] ?? [];
  }

  function setFilterValue(key: string, value: (typeof filterValues)[keyof typeof filterValues]) {
    setFilterValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSortModelChange(_sortModel: GridSortModel) {
    const newSortFields = _sortModel.map((_sort) => _sort.field);
    const newSortDirections = _sortModel.map((_sort) => _sort.sort as Direction);

    setSortFields(newSortFields);
    setSortDirections(newSortDirections);
  }

  const filterModel: FilterModel<TFilter>[] = (filterDefinitions ?? []).map(
    ({ filterKey, fields, operator, logicalOperator, initialValue, customValueToApi, field, oneToManyFields }) => ({
      customValueToApi,
      fields,
      field,
      operator: operator!,
      oneToManyFields,
      logicalOperator,
      value: getValueBasedOnOperator({ filterKey, fields, operator, logicalOperator, initialValue }) ?? undefined,
    })
  );

  function getValueBasedOnOperator({ filterKey, operator, initialValue }: TableFilterDefinition<TFilter>) {
    if (operator === FilterOperator.IN) {
      const values = filterValues[filterKey ?? ''] ?? '';
      if (Array.isArray(values) && values.length) {
        return values.map((v) => v).filter(Boolean) as typeof values;
      }
      if (Array.isArray(initialValue) && initialValue.length) {
        return initialValue.map((v) => v).filter(Boolean) as typeof initialValue;
      }
      return initialValue;
    }
    return filterValues[filterKey ?? ''];
  }

  return {
    getFilterValue,
    setFilterValue,
    queryProps: {
      page: currentPage - 1,
      pageSize,
      filterModel,
      sort: computedSort,
      tableName,
    },
    tableProps: {
      initialState: {
        sorting: {
          sortModel: computedSortModel,
        },
      },
      onPageChange: (newPage) => setCurrentPage(newPage + 1),
      onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
      onSortModelChange,
      paginationMode: 'server',
      rowsLoadingMode: 'server',
      sortingMode: 'server',
      filterMode: 'server',
      page: currentPage - 1,
      pageSize,
      keepNonExistentRowsSelected: true,
      name: tableName,
      usingServerTableControls: true,
    },
  };
}
