import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { Divider, Stack } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ContentCard } from 'src/app/components/lib/content-card';
import { ControlBar, ControlGroup } from 'src/app/components/lib/control-bar';
import { MenuItem } from 'src/app/components/lib/menu-item/menu-item';
import { Select } from 'src/app/components/lib/select';
import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import { useGetSegmentDemographicQuery } from 'src/app/queries/segment/get-segment-demographics';

import { DemographicTableRow } from './demographic-table-row';
import {
  DEMOGRAPHICS_BREAKDOWN_TYPE,
  DemographicTypeOption,
  DemographicTypes,
  ProductBreakdownMetrics,
  ProductMetricOption,
} from './utils/consts';
import { getDemographicChartColor } from './utils/styles-helpers';

import type { DemographicReportRow } from './utils/types';

export function SegmentDemographics() {
  const [breakdownType, setBreakdownType] = useState<string>('');
  const [breakdownMetric, setBreakdownMetric] = useState<string>('');
  const [breakdownData, setBreakdownData] = useState<DemographicReportRow[]>([
    {
      groupName: '',
      metric: '',
      metricValue: '',
      topMetricPercentage: 0,
    },
  ]);
  const [currentHoverIndex, setCurrentHoverIndex] = useState<number | undefined>();
  const { segmentId = '' } = useParams<{ segmentId: string }>();

  // LD Flags
  const isDemographicDummyDataEnabled = useLDFlag(LD.CUSTOMERS_SEGMENTS_REPORTS_DEMO_DATA_ROLLOUT, false);

  // Queries

  const { data: segmentReportBreakdown } = useGetSegmentDemographicQuery({
    segmentId: Number(segmentId),
    isDummyDataEnabled: isDemographicDummyDataEnabled,
  });

  // Chart Logic for labels and data
  function GetSubtext(type: string) {
    let result = 'Select a group';
    const isFilteredByAge = breakdownType === DemographicTypeOption.Age;

    if (currentHoverIndex === undefined) {
      return breakdownType ? `by ${isFilteredByAge ? ' age group' : 'location'}` : 'Select a group';
    }

    const groupName: string = breakdownData?.at(currentHoverIndex || 0)?.groupName || '';
    const titleValue: string = breakdownData?.at(currentHoverIndex || 0)?.metricValue || '';

    if (type === DEMOGRAPHICS_BREAKDOWN_TYPE.Category || type === DEMOGRAPHICS_BREAKDOWN_TYPE.Products) {
      result = isFilteredByAge
        ? `age ${groupName} frequently purchased ${titleValue}`
        : `frequently purchased ${titleValue} in ${groupName}`;
    }
    if (type === DEMOGRAPHICS_BREAKDOWN_TYPE.Usage) {
      result = isFilteredByAge
        ? `age ${groupName} primarily prefer to ${titleValue} cannabis`
        : `primarily prefer to ${titleValue} cannabis in ${groupName}`;
    }
    if (type === DEMOGRAPHICS_BREAKDOWN_TYPE.CustomerTypes) {
      result = isFilteredByAge ? `age ${groupName} are ${titleValue} users` : `are ${titleValue} users in ${groupName}`;
    }

    return result;
  }

  function GetLabel(type: string): string {
    const result = type ? ProductMetricOption[type] : 'Select a metric';

    const percentage: number = breakdownData?.at(currentHoverIndex || 0)?.topMetricPercentage || 0;

    if (currentHoverIndex || currentHoverIndex === 0) {
      return `${percentage}%`;
    }

    return result;
  }

  const getBackgroundColor = useMemo(
    () => (index: number) =>
      breakdownData && breakdownData?.length > 0 && (currentHoverIndex === undefined || currentHoverIndex === index)
        ? getDemographicChartColor(index)
        : '#a0a7ab',
    [currentHoverIndex, breakdownData]
  );

  const segmentBreakdownData = useMemo(
    () => ({
      labels: breakdownData?.map((type) => type.groupName),
      datasets: [
        {
          data: breakdownData?.map((type) => type?.topMetricPercentage),
          backgroundColor: breakdownData?.map((type, index) => getBackgroundColor(index)),
          opacity: 0.5,
          hoverOffset: 4,
        },
      ],
    }),
    [getBackgroundColor, breakdownData]
  );

  const data = useMemo(
    () => ({
      ...segmentBreakdownData,
      text: GetLabel(breakdownMetric) ?? '',
      subtext: GetSubtext(breakdownMetric) ?? '',
    }),
    [breakdownMetric, segmentBreakdownData, breakdownData, currentHoverIndex]
  );

  const options = useMemo(
    () => ({
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      onHover: (_evt: any, item: any[] | string) => {
        if (segmentReportBreakdown) {
          if (item.length > 0) {
            setCurrentHoverIndex(item[0]._index);
          } else {
            setCurrentHoverIndex(undefined);
          }
        }
      },
      cutoutPercentage: 80,
      maintainAspectRatio: false,
    }),
    [segmentReportBreakdown]
  );

  // Wrap Text
  // This function is used to correctly center and wrap the doughnuts subtext.
  // If you pass in a string that is too long, longer than the doughnuts width it will
  // overlap the the doughnut. To correct this this function will break the text into and array of
  // substrings and so that the sub strings can be stacked correctly.
  const wrapText = (ctx, text, maxWidth) => {
    const lines = [] as string[];
    if (text) {
      const words = text.split(' ') as string[];

      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const { width } = ctx.measureText(`${currentLine} ${word}`);
        if (width < maxWidth) {
          currentLine += ` ${word}`;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);
    }
    return lines;
  };

  // Plugin object requires it's own custom ID.
  // This element is being used to pass in a custom plugin to the chart doughnut object.
  // This is due to the fact that if you overide ChartJS's prototype object in more than
  // one place it will crash the component anywhere it is being referenced.
  // To avoid this you will need to create a custom plugin that will update the current
  // context correctly.
  //
  // This usage will help override ChartJS's subtext styles and center a custom label and text.
  const plugin = [
    {
      id: 'my-doughnut-text-plugin',
      beforeDraw(chart) {
        const { ctx } = chart;
        const { width } = chart;
        const { height } = chart;

        ctx.font = 'normal 500 29px Matter';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#121516';

        const { text, subtext } = chart.config.data;
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);

        ctx.font = `normal 100 15px Matter`;
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#A0A7AB';
        const subtextX = Math.round((width - ctx.measureText(subtext).width) / 2);
        const subtextY = height / 2 + 25;

        const words = wrapText(ctx, subtext, 200);

        if (words.length > 1) {
          for (let index = 0; index < words.length; index++) {
            const subtextXPostion = Math.round((width - ctx.measureText(words[index]).width) / 2);
            ctx.fillText(words[index], subtextXPostion, subtextY + 25 * index);
          }
        } else {
          ctx.fillText(words[0], subtextX, subtextY);
        }

        ctx.save();
      },
    },
  ];

  const handleMouseEnter = useCallback((index: number | undefined) => {
    if (index === undefined) {
      setCurrentHoverIndex(undefined);
    } else {
      setCurrentHoverIndex(index);
    }
  }, []);

  // Hooks
  useEffect(() => {
    if (breakdownType || breakdownMetric) {
      const isFilteredByAge = breakdownType === DemographicTypeOption.Age;
      const selectedValues = isFilteredByAge
        ? segmentReportBreakdown?.ageDemographicReport
        : segmentReportBreakdown?.locationDemographicReport;

      const filteredList = selectedValues?.filter((report) => report?.metric === breakdownMetric);
      setBreakdownData(filteredList || []);
    }
  }, [breakdownType, breakdownMetric, segmentReportBreakdown]);

  return (
    <StyledContentCard
      automationId='segment-demographics-breakdown'
      headerActions={
        <StyledControlBar>
          <ControlGroup>
            <StyledSelect
              id='segment-report-type-selector-desktop'
              placeholder='Select a group'
              value={breakdownType}
              onChange={({ target: { value } }) => {
                if (value) {
                  setBreakdownType(value);
                }
              }}
            >
              {DemographicTypes.map((metric) => (
                <MenuItem key={metric.value} value={metric.key}>
                  {metric.value}
                </MenuItem>
              ))}
            </StyledSelect>
          </ControlGroup>
          <ControlGroup>
            <StyledSelect
              id='segment-report-metric-selector-desktop'
              placeholder='Select a metric'
              value={breakdownMetric}
              onChange={({ target: { value } }) => {
                if (value) {
                  setBreakdownMetric(value);
                }
              }}
            >
              {ProductBreakdownMetrics.map((type) => (
                <MenuItem key={type.value} value={type.key}>
                  {type.value}
                </MenuItem>
              ))}
            </StyledSelect>
          </ControlGroup>
        </StyledControlBar>
      }
      marginBottom='32px'
      title='Segment demographics'
    >
      <StyledControlBarSmall>
        <StyledStack direction='row' spacing={2}>
          <StyleControlGroup>
            <StyledSelect
              id='segment-report-type-selector-mobile'
              placeholder='Select a group'
              value={breakdownType}
              onChange={({ target: { value } }) => {
                if (value) {
                  setBreakdownType(value);
                }
              }}
            >
              {DemographicTypes.map((metric) => (
                <MenuItem key={metric.value} value={metric.key}>
                  {metric.value}
                </MenuItem>
              ))}
            </StyledSelect>
          </StyleControlGroup>
          <StyleControlGroup>
            <StyledSelect
              id='segment-report-metric-selector-mobile'
              placeholder='Select a metric'
              value={breakdownMetric}
              onChange={({ target: { value } }) => {
                if (value) {
                  setBreakdownMetric(value);
                }
              }}
            >
              {ProductBreakdownMetrics.map((type) => (
                <MenuItem key={type.value} value={type.key}>
                  {type.value}
                </MenuItem>
              ))}
            </StyledSelect>
          </StyleControlGroup>
        </StyledStack>
      </StyledControlBarSmall>
      <Stack
        alignItems='center'
        direction={{ sm: 'column', md: 'row' }}
        divider={<Divider flexItem orientation='vertical' />}
        spacing={6}
      >
        <StyledDoughnutContainer>
          <Doughnut data={data} height={300} options={options} plugins={plugin} width={300} />
        </StyledDoughnutContainer>
        <FilterList>
          {breakdownMetric && breakdownType
            ? breakdownData?.map((type, index) => (
                <DemographicTableRow
                  bulletColor={getDemographicChartColor(index)}
                  index={index}
                  isGrayedOut={currentHoverIndex !== undefined && index !== currentHoverIndex}
                  key={type?.groupName}
                  keyValue={type?.groupName}
                  title={type?.metricValue}
                  value={type?.topMetricPercentage}
                  onMouseEnter={handleMouseEnter}
                />
              ))
            : null}
        </FilterList>
      </Stack>
    </StyledContentCard>
  );
}

const StyledContentCard = styled(ContentCard)`
  .content-card--card-content-article {
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledDoughnutContainer = styled.div`
  margin: 20px;
`;

const StyledControlBar = styled(ControlBar)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledControlBarSmall = styled(ControlBar)`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const StyledSelect = styled(Select)`
  margin-top: 1.2em;

  @media screen and (min-width: 768px) {
    margin-left: 1em;
  }

  .MuiSelect-select {
    padding: var(--sizes-20) var(--sizes-30);
    font: var(--font-extra-small-12pt-semibold);
  }
`;

const FilterList = styled.div`
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
`;

const StyledStack = styled(Stack)`
  width: 100%;
`;

const StyleControlGroup = styled(Stack)`
  width: 100%;
`;
