import { ServerPaginatedTables } from 'src/app/constants/table-names-paginated';

import { fieldMap } from './use-conditional-fields';

import type { ConditionalFields } from './use-conditional-fields';
import type { UserSetting } from 'src/app/queries/settings/get-user-settings';

export type ColumnSetting = {
  name: string;
  visible: boolean;
};

export function getConditionalFieldsFromUserSettings(
  settings: UserSetting[] | undefined
): ConditionalFields | undefined {
  if (settings === undefined) {
    return undefined;
  }
  const initialConditionalFields = {};
  const tableSettings = settings?.find(
    (setting) => setting.SettingType === 'table' && setting.Setting === ServerPaginatedTables.InventoryPackagesList
  );
  const columnSettings: Map<string, boolean> = new Map(
    JSON.parse(tableSettings?.Value ?? '[]').map((column: ColumnSetting) => [column.name, column.visible])
  );
  for (const [variable, fields] of Object.entries(fieldMap)) {
    initialConditionalFields[variable as keyof ConditionalFields] = !fields.some((field) => columnSettings.get(field));
  }

  return initialConditionalFields as ConditionalFields;
}
