import React from 'react';

import { useRecoilValue } from 'recoil';

import InventoryStore from 'src/app_deprecated/stores/InventoryStore';

import { LinkButton } from 'src/app/components/lib/button/link-button';
import { DropdownMultiple } from 'src/app/components/lib/dropdown';
import { inventoryAtom } from 'src/app/state/inventory';

type TagsDropdownProps = {
  automationId?: string;
  disabled?: boolean;
  helpText?: string;
  label?: string;
  labelPlacement?: 'start' | 'top';
  onChange: (ids: number[]) => void;
  refreshButtonAutomationId?: string;
  required?: boolean;
  searchAutomationId?: string;
  selectAllButtonAutomationId?: string;
  selectNoneButtonAutomationId?: string;
  value: number[];
};

export function TagsDropdown(props: TagsDropdownProps) {
  const {
    automationId,
    disabled,
    helpText,
    label,
    labelPlacement,
    refreshButtonAutomationId,
    required,
    searchAutomationId,
    selectAllButtonAutomationId,
    selectNoneButtonAutomationId,
    value,
    onChange,
  } = props;
  const { tags } = useRecoilValue(inventoryAtom);

  const options = tags.map(({ TagId, TagName }) => ({
    id: String(TagId),
    label: TagName,
  }));

  const parsedValue = value.map((id) => ({ id: String(id), label: '' }));

  function handleChange(payload) {
    const parsedPayload = payload.map(({ id }) => parseInt(id, 10));
    onChange(parsedPayload);
  }

  return (
    <DropdownMultiple
      automationId={automationId}
      disabled={disabled}
      footerContent={
        <LinkButton
          automationId={refreshButtonAutomationId}
          label='Refresh'
          onClick={() => {
            void InventoryStore.refreshTags();
          }}
        />
      }
      helpText={helpText}
      label={label}
      labelPlacement={labelPlacement}
      options={options}
      required={required}
      searchAutomationId={searchAutomationId}
      selectAllButtonAutomationId={selectAllButtonAutomationId}
      selectNoneButtonAutomationId={selectNoneButtonAutomationId}
      setValue={handleChange}
      value={parsedValue}
    />
  );
}
