import React, { useState } from 'react';

import i18next from 'i18next';

import { StrainDropdown } from 'src/app/components/form-elements/dropdowns/strain';
import { BatchIdInput } from 'src/app/components/form-elements/inputs/batch-id-input';
import { FormSection } from 'src/app/components/lib/form';
import { Modal } from 'src/app/components/lib/modal';
import { useCreateBatch } from 'src/app/queries/cultivation/create-lot-no';
import { useNotificationActions } from 'src/app/state/notifications';

type CreateNewBatchModalProps = {
  initialStrainId?: string;
  onClose: (newBatch?: { BatchId: number; BatchNo: string }) => void;
};

export function CreateNewBatchModal({ initialStrainId, onClose }: CreateNewBatchModalProps) {
  const notifications = useNotificationActions();

  const [batchName, setBatchName] = useState('');
  const [strainId, setStrainId] = useState<string>(initialStrainId ?? '');

  const { mutateAsync: createBatch, isLoading: isCreatingBatch } = useCreateBatch();

  const isValid = strainId.length > 0 && batchName.length > 0;

  const createNewBatch = async () => {
    if (!isValid || isCreatingBatch) {
      return;
    }

    try {
      const newBatch = await createBatch({ BatchName: batchName, StrainId: Number(strainId) });
      notifications.success({ message: 'Batch created successfully' });
      onClose(newBatch);
    } catch (e) {
      notifications.error({ message: 'Failed to create new batch' });
    }
  };

  return (
    <Modal
      Content={
        <FormSection>
          <StrainDropdown
            automationId='create-batch_strain-id'
            disabled={isCreatingBatch}
            required
            value={strainId}
            // This handler always returns a string but is typed incorrectly
            onChange={(value) => setStrainId(String(value))}
          />
          <BatchIdInput
            automationId='create-batch_batch-id'
            label='Batch name:'
            required
            value={batchName}
            onChange={setBatchName}
          />
        </FormSection>
      }
      open
      primaryActions={[
        {
          label: 'Create',
          onClick: () => void createNewBatch(),
          disabled: !isValid || isCreatingBatch,
          loading: isCreatingBatch,
        },
      ]}
      title={i18next.t('Create new batch')}
      width='656px'
      onBack={onClose}
      onClose={onClose}
    />
  );
}
