import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';

import { useNonSandboxBannerIsVisible } from './non-sandbox-banner';
import { HEADER_HEIGHT, LARGE_BANNER_HEIGHT, SMALL_BANNER_HEIGHT } from './shared';

import type { GeneralHeaderConfig } from './general-header-banner';

export function useHeightOffset() {
  const generalBannerFlag = useLDFlag<GeneralHeaderConfig>(LD.GENERAL_BACKOFFICE_HEADER_BANNER, {
    visible: false,
  });
  const nonSandboxBannerRollout = useLDFlag<boolean>(LD.NON_SANDBOX_BANNER_ALERT, false);
  const nonSandboxBannerIsVisible = useNonSandboxBannerIsVisible();
  const serverMigrationConfig = useLDFlag<{ backofficeURL?: string }>(LD.SERVER_MIGRATION_CONFIG);

  let offset = HEADER_HEIGHT;

  if (generalBannerFlag?.visible || serverMigrationConfig?.backofficeURL?.length) {
    offset += LARGE_BANNER_HEIGHT;
  }

  if (nonSandboxBannerRollout && nonSandboxBannerIsVisible) {
    offset += SMALL_BANNER_HEIGHT;
  }

  return `${offset}px`;
}
