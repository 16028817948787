// import type { MutableRefObject } from 'react';

// import type { GridColDef } from '@mui/x-data-grid-pro';
// import type { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { fieldMap } from '../queries/graphql/inventory-packages/hooks/use-conditional-fields';

import type {
  FieldNode,
  DocumentNode,
  SelectionNode,
  DefinitionNode,
  OperationDefinitionNode,
  VariableDefinitionNode,
} from 'graphql';

export type ExcludableFields = { fieldsToExclude?: string[] };
// type ExtendedGrilColDef = ExcludableFields & GridColDef;

export function filterDocumentNode<T extends DocumentNode>(documentNode: T, fieldsToRemove: string[]): T {
  function filterSelections(selections: ReadonlyArray<SelectionNode>, path = ''): SelectionNode[] {
    return selections
      .filter((selection): boolean => {
        const fullPath = getFullPath(selection, path);
        // fullPath.split('.').slice(2).join('.') is likely needed if we want to build all queries dynamically. Leaving for now.
        return !fieldsToRemove.includes(fullPath);
      })
      .map((selection): SelectionNode | null => {
        const fullPath = getFullPath(selection, path);

        if ((selection as FieldNode).selectionSet && (selection as FieldNode).selectionSet!.selections) {
          const newSelections = filterSelections((selection as FieldNode).selectionSet!.selections, fullPath);

          if (newSelections.length === 0) {
            return null;
          }

          (selection as FieldNode).selectionSet!.selections = newSelections;
        }
        return selection;
      })
      .filter(Boolean) as SelectionNode[];
  }

  function getFullPath(selection: SelectionNode, path: string): string {
    let newPath = path;
    if ((selection as FieldNode).name) {
      newPath = newPath ? `${newPath}.${(selection as FieldNode).name.value}` : (selection as FieldNode).name.value;
    }
    return newPath;
  }

  documentNode.definitions.forEach((def) => {
    const definition = def as DefinitionNode & { selectionSet?: { selections: SelectionNode[] } };
    if (definition.selectionSet?.selections) {
      definition.selectionSet.selections = filterSelections(definition.selectionSet.selections);
    }
  });

  // using the imported fieldMap,
  // if any of the fields for a given variable are being excluded due to the LD directive,
  // we need to remove that field's associated variable from the query to avoid a 400 error
  try {
    const operations = documentNode.definitions.find(
      (definition) => definition.kind === 'OperationDefinition'
    ) as OperationDefinitionNode;
    const variables = operations?.variableDefinitions as VariableDefinitionNode[];
    Object.entries(fieldMap).forEach(([variable, fields]) => {
      if (fields.some((field) => fieldsToRemove.includes(field))) {
        const index = variables?.findIndex((documentVariable) => documentVariable.variable.name.value === variable);
        if (index !== undefined && index > -1) {
          variables?.splice(index, 1);
        }
      }
    });
  } catch (e) {
    console.error(e);
  }

  return documentNode;
}

// only needed for dynamic gql builder in sync with the datagrid (unused for now)
// export function filterHiddenColumnsFromDocumentNode<T extends DocumentNode>(
//   documentNode: T,
//   gridApiRef: MutableRefObject<GridApiPro>,
//   columns: GridColDef[]
// ): T {
//   const fieldsToRemove = extractHiddenColumnFields(columns, gridApiRef);
//   return filterDocumentNode(documentNode, fieldsToRemove);
// }
// function extractHiddenColumnFields(columns: ExtendedGrilColDef[], gridApiRef: MutableRefObject<GridApiPro>): string[] {
//   const gridCols = gridApiRef.current?.getAllColumns?.();
//   const arrayOfArrays = columns?.map((c) => {
//     const col = gridCols?.find((gc) => gc.field === c.field);
//     if (!col?.hide) {
//       return [];
//     }
//     return c?.fieldsToExclude ? c.fieldsToExclude : [c?.field ?? ''];
//   });
//   return arrayOfArrays?.reduce((acc, val) => acc.concat(val), []) ?? [];
// }
