import { useCallback, useMemo, useState } from 'react';

import { buildInputPackageLabel } from 'src/app/pages/manufacturing/assemblies/utils';

import { maxPageSize } from '..';
import { Direction, FilterOperator, LogicalOperator } from '../../types/gql-filtering-and-pagination';

import { useInfinitePackagesQuery } from './get-many';

import type { QueryProps } from '../../types/gql-filtering-and-pagination';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';
import type { PackageDropDownAttributesFragment, PackageFilterInput, PackageSortInput } from 'src/gql/graphql';

type UsePackageDropdownDataParameters = {
  dynamicFilters?: NonNullable<QueryProps<PackageFilterInput, PackageSortInput>['filterModel']>;
  enabled?: boolean;
  // Injected items are useful if you need to add items to the dropdown that are not fetched from the server due to pagination
  // For instance, if you have 2 items selected that are not included in the list of packages fetched from the server
  injectedItems?: DropdownMenuOption[];
};
export function usePackagesDropdownData({
  injectedItems = [],
  enabled = true,
  dynamicFilters = [],
}: UsePackageDropdownDataParameters) {
  const [filter, setFilter] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  // InfiniteQueryProps
  const infiniteQueryProps: QueryProps<PackageFilterInput, PackageSortInput> = useMemo(
    () => ({
      applyUserId: true,
      filterModel: [
        ...dynamicFilters,
        {
          fields: ['quantity'],
          logicalOperator: LogicalOperator.AND,
          operator: FilterOperator.GREATER_THAN,
          value: true,
          customValueToApi: (val) => (val ? 0 : ''),
        },
        {
          value: filter,
          fields: ['serialNumber'],
          operator: FilterOperator.CONTAINS,
          logicalOperator: LogicalOperator.OR,
        },
      ],
      page: 0,
      pageSize,
      sort: [{ key: 'serialNumber', direction: Direction.ASC }],
    }),
    [dynamicFilters, filter, pageSize]
  );

  const mapOptions = useCallback(
    (packages: PackageDropDownAttributesFragment[]) =>
      packages.map(({ packageId, serialNumber, quantity = null, batch, room }) => ({
        id: packageId,
        label: buildInputPackageLabel({
          serialNumber,
          quantity,
          roomName: room?.roomNo ?? '',
          batchName: batch?.batchName ?? '',
        }),
      })),
    []
  );

  const {
    data: packagesData,
    isFetching: isFetchingInfinitePackages,
    error: infinitePackagesError,
    fetchNextPage,
    morePagesExist,
    fetchAllPackages,
  } = useInfinitePackagesQuery(infiniteQueryProps, enabled);

  const infiniteDropDownOptions: DropdownMenuOption[] = useMemo(() => {
    if (infinitePackagesError || isFetchingInfinitePackages || !packagesData) {
      return [];
    }
    const options = packagesData.filter((d) => !!d);
    return mapOptions(options);
  }, [infinitePackagesError, isFetchingInfinitePackages, mapOptions, packagesData]);

  // Map of packageId to package data, this is useful if you need to find associated data for a package
  // after the package has been selected based on the packageId
  const infiniteDropDownMap = useMemo(() => {
    const map = new Map<number, PackageDropDownAttributesFragment>();
    packagesData?.forEach((packageData) => {
      if (packageData) {
        map.set(packageData.packageId, packageData);
      }
    });
    return map;
  }, [packagesData]);

  const mergedOptions = useMemo(() => {
    if (injectedItems.length === 0) {
      return infiniteDropDownOptions;
    }
    // Deduplicate options
    const seenIds = new Set();
    const deduplicatedOptions = [...injectedItems, ...infiniteDropDownOptions].filter((option) => {
      if (seenIds.has(option.id)) {
        return false; // Skip duplicates
      }
      seenIds.add(option.id);
      return true;
    });

    return deduplicatedOptions.sort((a, b) => a.label.localeCompare(b.label));
  }, [injectedItems, infiniteDropDownOptions]);

  const onSelectAll = useCallback(async () => {
    setIsLoading(true);
    const result = await fetchAllPackages();
    const options = result.filter((d) => !!d);
    const newOptions = mapOptions(options);
    setPageSize(maxPageSize);
    setIsLoading(false);
    return newOptions;
  }, [fetchAllPackages, mapOptions]);

  const memoizedResponse = useMemo(
    () => ({
      error: infinitePackagesError,
      isFetching: isFetchingInfinitePackages || isLoading,
      options: mergedOptions,
      morePagesExist,
      fetchNextPage,
      setFilter,
      onSelectAll,
      infiniteDropDownMap,
    }),
    [
      infinitePackagesError,
      isFetchingInfinitePackages,
      isLoading,
      mergedOptions,
      morePagesExist,
      fetchNextPage,
      setFilter,
      onSelectAll,
      infiniteDropDownMap,
    ]
  );
  return memoizedResponse;
}
