import React from 'react';

type IconProps = {
  color?: string;
  height?: string;
};

export function CircleLeftArrowIcon({ color = 'black', height = '24' }: IconProps): JSX.Element {
  return (
    <svg
      fill='none'
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      width={height}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect fill='white' height={height} transform={`translate(${height} ${height}) rotate(-180)`} width={height} />
      <path
        d='M4.45019 12L12.0002 12C13.1048 12 14.0002 11.1046 14.0002 10V10'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='{1.5}'
      />
      <path d='M4 14L2 12L4 10' fill={color} />
      <path d='M4 14L2 12L4 10L4 14Z' stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='{1.5}' />
      <path
        d='M7 8C7.88477 6.47486 9.25049 5.28289 10.8853 4.60896C12.5202 3.93503 14.3328 3.81679 16.042 4.27259C17.7513 4.72839 19.2616 5.73275 20.3389 7.12991C21.4161 8.52706 22 10.2389 22 12C22 13.7611 21.4161 15.4729 20.3389 16.8701C19.2616 18.2672 17.7513 19.2716 16.042 19.7274C14.3328 20.1832 12.5202 20.065 10.8853 19.391C9.25049 18.7171 7.88477 17.5251 7 16'
        stroke={color}
        strokeLinecap='round'
        strokeWidth='{1.5}'
      />
    </svg>
  );
}
