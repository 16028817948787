import React from 'react';

type PlainCheckProps = {
  color?: string;
  size?: number;
};

export function PlainCheck({ color = 'var(--color-grayscale-white)', size = 16 }: PlainCheckProps) {
  return (
    <svg fill='none' height={size} viewBox='0 0 16 16' width={size} xmlns='http://www.w3.org/2000/svg'>
      <g id='Check' transform='matrix(1,0,0,1,-0.799985,0)'>
        <path
          d='M13.007,4.793C13.194,4.98 13.3,5.235 13.3,5.5C13.3,5.765 13.194,6.019 13.007,6.207L8,11.207C7.812,11.394 7.557,11.5 7.292,11.5C7.026,11.5 6.771,11.394 6.584,11.207L4.581,9.207C4.399,9.018 4.298,8.766 4.3,8.503C4.302,8.241 4.407,7.99 4.593,7.805C4.779,7.62 5.03,7.514 5.292,7.512C5.555,7.51 5.808,7.611 5.997,7.793L7.292,9.086L11.591,4.793C11.778,4.605 12.033,4.5 12.299,4.5C12.564,4.5 12.819,4.605 13.007,4.793Z'
          fill={color}
          id='vector'
        />
      </g>
    </svg>
  );
}
