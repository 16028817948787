import { createContext, useContext, useReducer } from 'react';

import { noop } from 'lodash';

import { useBOMPermissions } from 'src/app/hooks/use-bom-permissions';
import {
  applyBomToAssemblyState,
  initializeDefaultAssemblyState,
  initializeEditAssemblyState,
} from 'src/app/pages/manufacturing/assemblies/utils';
import { computeIsDirty } from 'src/app/pages/manufacturing/shared/utils';

import type { DocumentSchema, ProcessingJobTypeTemplateSchema } from '../../shared/schema';
import type { CostType } from '../constants';
import type { InputItem, InputPackage, OutputItem } from '../types';
import type { AssemblyDetailResponse } from 'src/app/queries/graphql/assemblies/get-one';
import type { GetBillOfMaterialDetailResponse } from 'src/app/queries/graphql/bill-of-materials/get-one';

export type SchemaContext = {
  defaultCostType?: CostType;
  useMetrcV2?: boolean;
};

export type AssemblyFormState = {
  assemblyStatusId: number;
  billOfMaterialsId: number | null;
  documents: DocumentSchema[];
  estimatedStartDate: Date | null;
  name: string;
  outputs: OutputItem[];
  processingJobTypeTemplate: ProcessingJobTypeTemplateSchema;
};

export type AssemblyState = {
  context: SchemaContext;
  errors: Record<string, string>;
  formData: AssemblyFormState;
  initialFormData: AssemblyFormState;
  isDirty: boolean;
  isReadonly: boolean;
};

export type InitializeEditStateAction = {
  payload: AssemblyDetailResponse;
  type: 'initialize-edit-assembly-state';
};

export type SetErrorsAction = {
  payload: Record<string, string>;
  type: 'set-errors';
};

export type SetContextAction = {
  payload: SchemaContext;
  type: 'set-context';
};

export type ResetFormAction = {
  payload: undefined;
  type: 'reset-form';
};

export type SetIsDirtyAction = {
  payload: boolean;
  type: 'set-is-dirty';
};

export type ApplyBOMAction = {
  payload: NonNullable<GetBillOfMaterialDetailResponse>;
  type: 'apply-bom';
};

export type SetValueAction<K extends keyof AssemblyFormState> = {
  payload: { key: K; value: AssemblyFormState[K] };
  type: 'set-value';
};

export type SetOutputValueAction<K extends keyof OutputItem> = {
  payload: { key: K; outputIndex: number; value: OutputItem[K] };
  type: 'set-output-value';
};

export type SetInputValueAction<K extends keyof InputItem> = {
  payload: { inputIndex: number; key: K; outputIndex: number; value: InputItem[K] };
  type: 'set-input-value';
};

export type ApplyValueToAllInputsAction<K extends keyof InputItem> = {
  payload: { key: K; outputIndex: number; value: InputItem[K] };
  type: 'apply-value-to-all-inputs';
};

export type SetInputPackagesAction = {
  payload: { inputIndex: number; outputIndex: number; value: InputPackage[] };
  type: 'set-input-packages';
};

export type SetInputPackageValueAction<K extends keyof InputPackage> = {
  payload: { inputIndex: number; inputPackageIndex: number; key: K; outputIndex: number; value: InputPackage[K] };
  type: 'set-input-package-value';
};

export type RemoveInputPackageAction = {
  payload: { inputIndex: number; inputPackageIndex: number; outputIndex: number };
  type: 'remove-input-package';
};

export type Action =
  | ApplyBOMAction
  | ApplyValueToAllInputsAction<keyof InputItem>
  | InitializeEditStateAction
  | RemoveInputPackageAction
  | ResetFormAction
  | SetContextAction
  | SetErrorsAction
  | SetInputPackagesAction
  | SetInputPackageValueAction<keyof InputPackage>
  | SetInputValueAction<keyof InputItem>
  | SetIsDirtyAction
  | SetOutputValueAction<keyof OutputItem>
  | SetValueAction<keyof AssemblyFormState>;

export function reducer(state: AssemblyState, action: Action): AssemblyState {
  const { payload, type } = action;
  switch (type) {
    case 'initialize-edit-assembly-state': {
      const initialData = initializeEditAssemblyState(payload);
      return {
        ...state,
        formData: initialData,
        initialFormData: initialData,
        errors: {},
        isDirty: false,
      };
    }
    case 'apply-bom': {
      const newFormData = applyBomToAssemblyState(payload, state.formData, state.context);
      return {
        ...state,
        formData: { ...newFormData },
        isDirty: computeIsDirty({ ...newFormData }, state.initialFormData),
      };
    }
    case 'set-errors':
      return {
        ...state,
        errors: { ...payload },
      };

    case 'set-is-dirty':
      return {
        ...state,
        isDirty: payload,
      };

    case 'set-context':
      return {
        ...state,
        context: { ...payload },
      };
    case 'reset-form':
      return {
        ...state,
        formData: state.initialFormData,
        errors: {},
        isDirty: false,
      };
    case 'set-value': {
      const updatedFormData = { ...state.formData, [payload.key]: payload.value };
      return {
        ...state,
        formData: updatedFormData,
        isDirty: computeIsDirty(updatedFormData, state.initialFormData),
      };
    }
    case 'set-output-value': {
      const { outputIndex, key, value } = payload;
      const updatedOutputs = state.formData.outputs.map((output, index) =>
        index === outputIndex ? { ...output, [key]: value } : output
      );
      return {
        ...state,
        formData: { ...state.formData, outputs: updatedOutputs },
        isDirty: computeIsDirty({ ...state.formData, outputs: updatedOutputs }, state.initialFormData),
      };
    }
    case 'set-input-value': {
      const { inputIndex, key, outputIndex, value } = payload;
      const updatedOutputs = state.formData.outputs.map((output, index) =>
        index === outputIndex
          ? {
              ...output,
              inputItems: output.inputItems.map((inputItem, i) =>
                i === inputIndex ? { ...inputItem, [key]: value } : inputItem
              ),
            }
          : output
      );
      return {
        ...state,
        formData: { ...state.formData, outputs: updatedOutputs },
        isDirty: computeIsDirty({ ...state.formData, outputs: updatedOutputs }, state.initialFormData),
      };
    }
    case 'apply-value-to-all-inputs': {
      const { key, value, outputIndex } = payload;
      const updatedOutputs = state.formData.outputs.map((output, index) =>
        index === outputIndex
          ? {
              ...output,
              inputItems: output.inputItems.map((inputItem) => ({ ...inputItem, [key]: value })),
            }
          : output
      );
      return {
        ...state,
        formData: { ...state.formData, outputs: updatedOutputs },
        isDirty: computeIsDirty({ ...state.formData, outputs: updatedOutputs }, state.initialFormData),
      };
    }
    case 'set-input-packages': {
      const { inputIndex, outputIndex, value } = payload;
      const updatedOutputs = state.formData.outputs.map((output, index) =>
        index === outputIndex
          ? {
              ...output,
              inputItems: output.inputItems.map((inputItem, i) =>
                i === inputIndex ? { ...inputItem, assemblyInputPackages: value } : inputItem
              ),
            }
          : output
      );
      return {
        ...state,
        formData: { ...state.formData, outputs: updatedOutputs },
        isDirty: computeIsDirty({ ...state.formData, outputs: updatedOutputs }, state.initialFormData),
      };
    }
    case 'set-input-package-value': {
      const { inputIndex, inputPackageIndex, outputIndex, key, value } = payload;
      const updatedOutputs = state.formData.outputs.map((output, index) =>
        index === outputIndex
          ? {
              ...output,
              inputItems: output.inputItems.map((inputItem, i) =>
                i === inputIndex
                  ? {
                      ...inputItem,
                      assemblyInputPackages: inputItem.assemblyInputPackages.map((inputPackage, j) =>
                        j === inputPackageIndex ? { ...inputPackage, [key]: value } : inputPackage
                      ),
                    }
                  : inputItem
              ),
            }
          : output
      );
      return {
        ...state,
        formData: { ...state.formData, outputs: updatedOutputs },
        isDirty: computeIsDirty({ ...state.formData, outputs: updatedOutputs }, state.initialFormData),
      };
    }
    case 'remove-input-package': {
      const { inputIndex, inputPackageIndex, outputIndex } = payload;
      const updatedOutputs = state.formData.outputs.map((output, index) =>
        index === outputIndex
          ? {
              ...output,
              inputItems: output.inputItems.map((inputItem, i) =>
                i === inputIndex
                  ? {
                      ...inputItem,
                      assemblyInputPackages: inputItem.assemblyInputPackages.filter((_, j) => j !== inputPackageIndex),
                    }
                  : inputItem
              ),
            }
          : output
      );
      return {
        ...state,
        formData: { ...state.formData, outputs: updatedOutputs },
        isDirty: computeIsDirty({ ...state.formData, outputs: updatedOutputs }, state.initialFormData),
      };
    }
    default:
      return state;
  }
}

export function useAssemblyFormState() {
  const { canEditAssemblies } = useBOMPermissions();
  const initialFormData = initializeDefaultAssemblyState();
  return useReducer(reducer, {
    formData: initialFormData,
    initialFormData,
    errors: {},
    context: {},
    isDirty: false,
    isReadonly: !canEditAssemblies,
  });
}

export const AssemblyFormStateContext = createContext<ReturnType<typeof useAssemblyFormState>>([
  {
    formData: initializeDefaultAssemblyState(),
    initialFormData: initializeDefaultAssemblyState(),
    errors: {},
    context: {},
    isDirty: false,
    isReadonly: false,
  },
  noop,
]);

export function useAssemblyFormStateContext() {
  return useContext(AssemblyFormStateContext);
}
