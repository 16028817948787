import React from 'react';

import styled from 'styled-components';

import { PlainCheck } from 'src/app/components/icons/plain-check';
import { Accordion } from 'src/app/components/lib/accordion';
import { RedErrorBadge } from 'src/app/components/lib/badge-v2';
import { Checkbox } from 'src/app/components/lib/checkbox';
import { Status } from 'src/app/pages/manufacturing/assemblies/constants';

import { buildInputHeaderTitle, getIsInputQuantityFulfilled } from '../../../../utils';
import { useAssemblyFormStateContext } from '../../../use-form-state';

import { InputPackages } from './input-packages';

import type { InputItem } from '../../../../types';

export function Inputs({ outputIndex }: { outputIndex: number }) {
  const [{ formData, isReadonly }, dispatch] = useAssemblyFormStateContext();
  const isAssemblyInProgress = formData.assemblyStatusId === Status.IN_PROGRESS;
  const output = formData.outputs[outputIndex];
  const inputs = output?.inputItems ?? [];
  const accordionInputs = inputs.map((inputItem) => ({
    ...inputItem,
    disabled: isReadonly || inputItem.skip || output.skip,
  }));

  const setInputValue = <K extends keyof InputItem>(inputIndex: number, key: K, value: InputItem[K]) => {
    dispatch({ type: 'set-input-value', payload: { outputIndex, inputIndex, key, value } });
  };
  return (
    <InputsContainer>
      <InputsHeader>Inputs</InputsHeader>
      <Accordion
        caratPosition='right'
        containerStyleOverrides={{ backgroundColor: 'var(--color-gray-10)' }}
        contentStyleOverrides={{
          borderTop: 'none',
          paddingTop: '0px',
        }}
        defaultExpanded={[0]}
        headerStyleOverrides={(isOpen) => ({
          padding: 'var(--sizes-50)',
          ...(isOpen ? { paddingBottom: 'var(--sizes-30)' } : {}),
        })}
        items={accordionInputs}
        multiExpand
        renderContent={(_, inputIndex) => <InputPackages inputIndex={inputIndex} outputIndex={outputIndex} />}
        renderHeaderLeft={(inputItem) => {
          const { prefix, suffix } = buildInputHeaderTitle(inputItem);
          const isQuantityFulfilled = getIsInputQuantityFulfilled(inputItem);
          return (
            <InputTitle>
              <InputPrefix>{prefix}</InputPrefix>
              <InputStaticText>Amount needed:</InputStaticText>
              <InputSuffixContainer>
                <InputSuffix isFulfilled={isQuantityFulfilled}>{suffix}</InputSuffix>
                {isQuantityFulfilled && <PlainCheck color='var(--color-green-60)' size={24} />}
              </InputSuffixContainer>
              {inputItem.skip && <RedErrorBadge label='Skipped' size='small' />}
            </InputTitle>
          );
        }}
        renderHeaderRight={(inputItem, inputIndex) => (
          <Checkbox
            checked={inputItem.skip}
            disabled={isAssemblyInProgress || isReadonly || output.skip}
            label='Skip input'
            value={inputItem.skip}
            onChange={({ target }) => {
              setInputValue(inputIndex, 'skip', target.checked);
              setInputValue(inputIndex, 'assemblyInputPackages', []);
            }}
          />
        )}
      />
    </InputsContainer>
  );
}

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const InputTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const InputPrefix = styled.div`
  color: var(--color-grayscale-black);
  /* Text/Default/Semibold */
  font: var(--font-regular-14pt-semibold);
  line-height: 20px; /* 142.857% */
`;

const InputStaticText = styled.div`
  color: var(--color-grayscale-black);
  text-align: right;
  /* Text/Small/Regular */
  font-family: Matter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: 0.065px;
`;

const InputSuffixContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InputSuffix = styled.div<{ isFulfilled?: boolean }>`
  color: ${({ isFulfilled }) => (isFulfilled ? 'var(--color-green-60)' : 'var(--color-grayscale-black)')};
  /* Text/Small/Semibold */
  font-family: Matter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  letter-spacing: 0.065px;
`;

export const InputsHeader = styled.div`
  color: var(--color-grayscale-black);
  font-feature-settings: 'calt' off;
  /* Text/Large/Semibold */
  font: var(--font-large-16pt-semibold);
  line-height: 24px; /* 150% */
`;

export const InputsAccordionHeader = styled.div`
  color: var(--color-grayscale-black);
  /* Text/Default/Semibold */
  font: var(--font-regular-14pt-semibold);
  line-height: 20px; /* 142.857% */
`;
