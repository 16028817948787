import { useMemo } from 'react';

import { useInventoryStatusesQuery } from 'src/app/queries/graphql/inventory-statuses/get-many';

export function useStatusesDropdownData() {
  const { data: statuses, isFetching, error } = useInventoryStatusesQuery();

  const statusOptions = useMemo(() => {
    if (statuses === undefined) {
      return [];
    }
    const statusesWithNone = [{ label: 'None', value: '' }].concat(
      statuses.map((status) => ({
        label: status.InventoryStatus ?? '',
        value: String(status.InventoryStatusId),
      }))
    );
    return statusesWithNone;
  }, [statuses]);

  const data = useMemo(
    () => ({
      isFetching,
      error,
      options: statusOptions,
    }),
    [statusOptions, isFetching, error]
  );

  return data;
}
