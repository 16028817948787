// commenting to resolve jest error
// see: https://github.com/istanbuljs/v8-to-istanbul/issues/198

export const inventoryPackagesKeys = {
  all: ['inventoryPackages'] as const,
  many: (queryProps?: any, type = 'list') => [...inventoryPackagesKeys.all, type].concat(queryProps ?? []),
  one: (id: number) => [...inventoryPackagesKeys.all, 'detail', id] as const,
};

export const inventoryPackagesGlobalSearchKeys = {
  all: ['inventoryPackagesGlobalSearch'] as const,
  many: (queryProps?: any) => [...inventoryPackagesGlobalSearchKeys.all, 'list'].concat(queryProps ?? []),
};
