import _ from 'lodash';

import { isCanadaLoc } from './locale';

import type { UserDispensariesAtom } from '../state/user-dispensaries';
import type { InventoryPackageAttributesFragment } from 'src/gql/graphql';

export function formatDate(value: any, dateTime = true, user: UserDispensariesAtom | false = false) {
  if (!value || value === '') {
    return '';
  }

  let val = value;
  if (typeof value === 'string') {
    val = new Date(value);
  }

  const locale = user && isCanadaLoc({ user }) ? 'en-CA' : 'en-US';
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: dateTime ? 'numeric' : undefined,
    minute: dateTime ? 'numeric' : undefined,
    second: dateTime ? 'numeric' : undefined,
  }).format(val);
}

export function formatMoney(value: number | string, fallback?: number | string): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const formattedVal = typeof value === 'string' ? value.replaceAll(',', '') : value;
  let workingVal = Number(formattedVal);
  if (Number.isNaN(workingVal)) {
    const fallBackNumber = Number(fallback);
    if (Number.isNaN(fallBackNumber)) {
      workingVal = 0;
    } else {
      workingVal = fallBackNumber;
    }
  }

  return formatter.format(workingVal);
}

export const formatNumberWithCommas = (number: number): string => {
  const num = Number(number);
  return num.toLocaleString('en');
};

export const formatNumberAsMoneyWithCommas = (number: number): string => {
  const num = Number(number);
  return num.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const proudCamelCase = (str) =>
  str
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

export function normalizeYesNo(value) {
  if (value.toLowerCase() === 'yes') {
    return 'Yes';
  }

  if (value.toLowerCase() === 'no') {
    return 'No';
  }

  return value;
}

export const formatToMilitaryTime = (twelveHourFormatTimeString: string) => {
  const [time, modifier] = twelveHourFormatTimeString.split(' ');
  let hours = time.split(':')[0];
  const minutes = time.split(':')[1];
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = String(parseInt(hours, 10) + 12);
  }
  return `${hours}:${minutes}`;
};

export function toPascalCase(str: string) {
  return _.startCase(_.camelCase(str)).replace(/ /g, '');
}

export function updateKeysToPascalCase(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(updateKeysToPascalCase);
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const pascalKey = toPascalCase(key);
      return [pascalKey, updateKeysToPascalCase(value)];
    })
  );
}

type FormatLabResultParams = {
  pkg: InventoryPackageAttributesFragment;
  unitKey: string;
  valueKey: string;
};

/**
 * @deprecated We should switch to using the new formatLabResults({ labResults, labKey }) helper
 * @see {@link src/app/utils/format-lab-results.ts} for the new implementation
 */
export function formatLabResult({ pkg, unitKey, valueKey }: FormatLabResultParams): string {
  const value = pkg.batch?.tblBatchThcs?.length ? pkg.batch?.tblBatchThcs[0]?.[valueKey] : null;
  const unitId = pkg.batch?.tblBatchThcs?.length ? pkg.batch?.tblBatchThcs[0]?.[unitKey] : null;

  if (value === null && unitId !== 4 && unitId !== 5) {
    return '';
  }

  switch (unitId) {
    case 5:
      return '<LOQ';
    case 4:
      return 'n.d.';
    case 3:
      return `${String(value?.toFixed(2))} mg/g`;
    case 2:
      return `${String(value?.toFixed(2))} %`;
    case 1:
      return `${String(value?.toFixed(2))} mg`;
    default:
      return '';
  }
}

export function formatOxfordCommaList(words: string[]): string {
  const nonEmptyWords = words.filter((word) => word);
  if (nonEmptyWords.length === 0) {
    return '';
  }
  if (nonEmptyWords.length === 1) {
    return words[0];
  }

  if (nonEmptyWords.length === 2) {
    return `${nonEmptyWords[0]} and ${nonEmptyWords[1]}`;
  }

  const lastWord = nonEmptyWords.pop() ?? '';
  return `${nonEmptyWords.join(', ')}, and ${lastWord}`;
}

export function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}
