import React from 'react';

import styled from 'styled-components';

import { CircleLeftArrowIcon } from 'src/app/components/icons/circle-left-arrow-icon';
import { Accordion } from 'src/app/components/lib/accordion';
import { RedErrorBadge } from 'src/app/components/lib/badge-v2';
import { Button } from 'src/app/components/lib/button';
import { ControlGroup } from 'src/app/components/lib/control-bar';
import { Divider } from 'src/app/components/lib/divider';
import { EMPTY_OUTPUTS_BODY, EMPTY_OUTPUTS_HEADER } from 'src/app/pages/manufacturing/assemblies/constants';
import { buildOutputHeaderTitle } from 'src/app/pages/manufacturing/assemblies/utils';
import { EmptyMessage, CardHeader } from 'src/app/pages/manufacturing/shared/layout';

import { CompleteAssemblyModal } from '../../../modals/complete-assembly/complete-assembly';

import { Inputs } from './inputs';
import { OutputDetail } from './output-detail';
import { useOutputs } from './use-outputs';

import type { useAssemblyFormData } from 'src/app/pages/manufacturing/assemblies/detail/use-assembly-form-data';

type OutputsProps = {
  dropdownOptions: ReturnType<typeof useAssemblyFormData>['dropdownOptions'];
  isLoading: boolean;
};

export function Outputs({ isLoading, dropdownOptions }: OutputsProps) {
  const {
    closeCompleteAssemblyModal,
    errors,
    finishAssembly,
    handleCompleteAssembly,
    handleStartAssembly,
    handleUndoAssembly,
    hasAssemblyBeenStarted,
    isCompleteAssemblyModalOpen,
    isCompleteButtonDisabled,
    isCompleteButtonLoading,
    isStartButtonDisabled,
    isStartButtonLoading,
    isUndoButtonDisabled,
    isUndoButtonLoading,
    outputs,
  } = useOutputs();

  return (
    <OutputsContainer>
      <OutputsHeader data-testid='assembly-outputs-header'>
        <CardHeader>
          <CircleLeftArrowIcon />
          Outputs
        </CardHeader>
        <ControlGroup>
          {hasAssemblyBeenStarted ? (
            <Button
              buttonSize='small'
              disabled={isUndoButtonDisabled}
              label='Undo changes'
              loading={isUndoButtonLoading}
              variant='secondary'
              onClick={handleUndoAssembly}
            />
          ) : (
            <Button
              buttonSize='small'
              disabled={isStartButtonDisabled}
              label='Start'
              loading={isStartButtonLoading}
              onClick={handleStartAssembly}
            />
          )}
          <Divider variant='vertical-flex' />
          <Button
            buttonSize='small'
            disabled={isCompleteButtonDisabled}
            label='Complete'
            loading={isCompleteButtonLoading}
            onClick={handleCompleteAssembly}
          />
        </ControlGroup>
      </OutputsHeader>
      <OutputsBody>
        {outputs.length > 0 ? (
          <Accordion
            caratPosition='right'
            contentStyleOverrides={{
              borderTop: 'none',
              padding: 'var(--sizes-30) var(--sizes-50)',
              gap: 'var(--sizes-70)',
            }}
            defaultExpanded={[0]}
            headerStyleOverrides={() => ({ padding: 'var(--sizes-50)' })}
            items={outputs}
            multiExpand
            renderContent={(_, outputIndex) => (
              <>
                <OutputDetail dropdownOptions={dropdownOptions} outputIndex={outputIndex} />
                <Inputs outputIndex={outputIndex} />
              </>
            )}
            renderHeaderLeft={(outputItem, outputIndex) => (
              <>
                {buildOutputHeaderTitle(outputItem)}
                {(!!errors.outputItems || !!errors[`outputItems[${outputIndex}] `]) && (
                  <RedErrorBadge label='Incomplete' size='small' />
                )}
              </>
            )}
          />
        ) : (
          <EmptyMessage body={EMPTY_OUTPUTS_BODY} header={EMPTY_OUTPUTS_HEADER} isLoading={isLoading} />
        )}
        <CompleteAssemblyModal
          open={isCompleteAssemblyModalOpen}
          onClose={closeCompleteAssemblyModal}
          onFinish={finishAssembly}
        />
      </OutputsBody>
    </OutputsContainer>
  );
}

export const OutputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--sizes-30);
  border: 1px solid var(--color-gray-20);
  background: var(--color-grayscale-white);
`;

export const OutputsHeader = styled.div`
  display: flex;
  padding: var(--sizes-40);
  align-items: center;
  gap: var(--sizes-50);
  justify-content: space-between;
  align-self: stretch;
  font: var(--font-large-16pt-semibold);
`;

export const OutputsBody = styled.div`
  border-top: 1px solid var(--color-gray-20);
  display: flex;
  padding: var(--sizes-70);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
