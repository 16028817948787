import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postData } from 'src/app/queries';
import { assemblyKeys } from 'src/app/queries/graphql/assemblies/query-key-factory';

import type { FailedAssemblyResponse, UndoAssemblyRequest } from './types';

export const endpoint = 'api/manufacturing/assembly/undo';

export type UndoAssemblyResponse = {
  Result: boolean;
};

export const useUndoAssembly = () => {
  const queryClient = useQueryClient();
  return useMutation<UndoAssemblyResponse, FailedAssemblyResponse, UndoAssemblyRequest>({
    mutationKey: ['undo-assembly'],
    mutationFn: (payload) => postData({ payload, endpoint }),
    onSuccess: () => void queryClient.resetQueries({ queryKey: assemblyKeys.all }),
  });
};
