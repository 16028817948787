import type { Room } from 'src/app/components/form-elements/dropdowns/rooms-dropdown';
import type { Bench as Subroom } from 'src/app/state/cultivation';

type GetRoomOptions = ({
  rooms,
  filterOutRoomId,
  hideNonInventoryRooms,
}: {
  filterOutRoomId?: number;
  hideNonInventoryRooms: boolean;
  rooms: Room[];
}) => {
  id: string;
  label: string;
  subtitle: boolean;
}[];

type GetSubroomOptions = ({
  subrooms,
  roomId,
  filterByRoom,
  filterOutSubroomId,
}: {
  filterByRoom: boolean;
  filterOutSubroomId?: number;
  roomId: number;
  subrooms: Subroom[];
}) => {
  id: string;
  label: string;
}[];

// when filterRoomId is passed, filters out that room,
// when hideNonInventoryRooms is true, filter out rooms that are not inventory rooms
export const getRoomOptions: GetRoomOptions = ({ rooms, hideNonInventoryRooms, filterOutRoomId }) => {
  const filterFn = (room: Room) =>
    (filterOutRoomId ? room.RoomId !== filterOutRoomId : true) &&
    (hideNonInventoryRooms ? /^yes$/i.test(room.InventoryRoom ?? '') && room.RoomName?.length : room?.RoomName?.length);

  const mapFn = (room: Room) => ({
    label: room.RoomName ?? '',
    subtitle: false,
    id: String(room.RoomId),
  });

  return rooms.filter(filterFn).map(mapFn);
};

// when a roomId is passed & filterByRoom is true, filters out subrooms that are not in the room
// when filterOutSubroomId is passed, filters out that subroom
export const getSubroomOptions: GetSubroomOptions = ({ subrooms, roomId, filterByRoom, filterOutSubroomId }) => {
  const roomFilter = (subroom: Subroom) => !roomId || (filterByRoom ? subroom.RoomId === roomId : true);

  const subroomFilter = (subroom: Subroom) => !filterOutSubroomId || subroom.BenchId !== filterOutSubroomId;

  const mapToOption = (subroom: Subroom) => ({
    id: String(subroom.BenchId),
    label: subroom.Bench,
  });

  return subrooms.filter(roomFilter).filter(subroomFilter).map(mapToOption);
};
