import React, { useEffect, useState } from 'react';

import { MultiFilterSelect } from 'src/app/components/lib/multi-filter-select';
import { monthOptions } from 'src/app/utils/months';

import { getMultiSelectedOptions, parseSelectedIds } from './utils';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

type MonthOptionDropdownProps = {
  disabled?: boolean;
  onChange: (value: (number | string)[]) => void;
  values: string;
};

export type MonthOption = {
  label: string;
  value: number;
};

export function MonthOptionDropdown(props: MonthOptionDropdownProps) {
  const { onChange, values, disabled = false } = props;

  const getMultiSelectedOptionsForMonthOptions = (
    values: string,
    allMonthOptions: MonthOption[]
  ): DropdownMenuOption[] =>
    getMultiSelectedOptions(
      values,
      allMonthOptions,
      parseSelectedIds,
      (option) => option.value,
      (option) => ({
        label: option.label,
        id: option.value.toString(),
      })
    );

  const [label, setLabel] = useState('Select a month');
  const [monthOptionDropdownSelection, setMonthOptionsDropdownSelection] = useState<DropdownMenuOption[]>(
    getMultiSelectedOptionsForMonthOptions(values, monthOptions)
  );

  useEffect(() => {
    if (monthOptionDropdownSelection.length !== 0 && monthOptionDropdownSelection.length < 4) {
      const monthOptionsLabelList = monthOptionDropdownSelection.map((item) => item.label).join(', ');
      setLabel(monthOptionsLabelList);
    } else {
      setLabel('Select a month');
    }
  }, [monthOptionDropdownSelection]);

  const handleMonthOptionsFilterChange = (values: DropdownMenuOption[]) => {
    setMonthOptionsDropdownSelection(values);

    const customerTypeDropdownSelection = values.map((option) => option.id);
    onChange(customerTypeDropdownSelection);
  };

  return (
    <MultiFilterSelect
      activeFilter={monthOptionDropdownSelection}
      automationId='month-options-dropdown_multi-filter-select'
      disabled={disabled}
      label={label}
      options={monthOptions.map((monthOptions) => ({
        label: String(monthOptions.label),
        id: String(monthOptions.value),
      }))}
      onChange={handleMonthOptionsFilterChange}
    />
  );
}
