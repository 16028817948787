import _ from 'lodash';
import { atom, selectorFamily, useRecoilValue } from 'recoil';

import UserStore from '../../app_deprecated/stores/UserStore';

import type { Features, LocProfile } from './types/loc-profile';
import type { GridSortItem } from '@mui/x-data-grid-pro';

type LspSettings = Record<string, unknown> & {
  InventoryAdjustmentThreshold: number | null;
  SessionTimeoutMinutes: number | null;
};

export type SelectedLocation = {
  HasPermission: boolean;
  IsBioTrackIntegrated: boolean;
  IsLive: boolean;
  IsSandbox: boolean;
  LicenseTypeIds: Record<string, unknown>[];
  LocId: string;
  Logo: string;
  Name: string;
  State: string;
  WhichUnitCost: string;
  WhichUnitPrice: string; // I think this could be a union like: 'Product' | 'Catalog'
};

export type SelectedCompany = {
  Logo: string;
  LspId: number;
  LspName: string;
  oldLogo: string;
};

export type UserDispensariesData = {
  SessionId: string;
};

export type TableColumnSetting = {
  align: string;
  exportColumn: boolean;
  exportRender: boolean;
  hidden: boolean;
  name: string;
  order: number;
  sort: GridSortItem & {
    format?: boolean;
    type?: string;
  };
  td?: {
    format: boolean;
    formatType: boolean;
    style: Record<string, string>;
    wrapNewline: boolean;
  };
  th?: {
    format: boolean;
    style: Record<string, string>;
  };
  title: string;
  visible: boolean;
  width?: number;
};

export type TableSetting = {
  layout: TableColumnSetting[];
  table: string;
};

export type ValidatedFormField = {
  CustomerOption: number;
  DisplayName: string;
  FormName: string;
  ReactName: string;
  SystemRequired: boolean;
  ValidatedFormFieldId: number;
};

export type ValidatedFormFieldLite = {
  CustomerOption: number;
  FormName: string;
  ReactName: string;
  SystemRequired: boolean;
  ValidatedFormFieldId: number;
};

export type Feature = {
  Category?: string;
  DisplayName?: string;
  FeatureDescription?: string;
  FeatureId: number;
  FeatureName: string;
  IsEnabled: boolean;
  IsOption: boolean;
};

export type CustomerType = {
  CustomerType: string;
  CustomerTypeId: number;
  IsMedical: boolean;
  IsRetail: boolean;
};

export type UserDispensariesAtom = {
  companies: any[];
  customerTypes: CustomerType[];
  data: UserDispensariesData;
  features: Feature[];
  integrations: any | boolean;
  locations: any[];
  locProfile: LocProfile;
  lspSettings: LspSettings | any;
  selectedCompany: SelectedCompany;
  selectedLocation: SelectedLocation;
  tableSettings: TableSetting[];
  validatedForms: ValidatedFormField[];
};

export const userDispensariesAtom = atom<UserDispensariesAtom>({
  key: 'userDispensaries',
  default: {
    features: [],
    locations: [],
    customerTypes: [],
    selectedLocation: {
      LocId: '',
      HasPermission: false,
      Logo: '',
      IsLive: false,
      IsSandbox: true,
      LicenseTypeIds: [],
      Name: '',
      State: '',
      IsBioTrackIntegrated: false,
      WhichUnitCost: '',
      WhichUnitPrice: '',
    },
    companies: [],
    selectedCompany: {
      LspName: '',
      LspId: 0,
      Logo: '',
      oldLogo: '',
    },
    locProfile: {
      AcceptCanPay: false,
      AcceptHypur: false,
      AcceptLinx: false,
      APIActionsQueued: false,
      AuditCustomerInfoAccess: false,
      BulkRegisterTransactions: false,
      BulkUploadLabData: false,
      CanUseMassDPHIntegration: false,
      CanadaBarcodeScanning: false,
      CheckOutCheck: false,
      CheckOutCreditCard: false,
      CheckOutDebit: false,
      CheckMetrcOnHoldInventory: false,
      CombinePackages: false,
      CustomerVisitReason: false,
      DebitProcessor: '',
      DefaultCustomerTypeId: 2, // Recreational
      DefaultDeliveryWindowEnd: '',
      DefaultDeliveryWindowStart: '',
      DualPricing: false,
      DiscountWizardEnablement: false,
      EcomV2: false,
      EnableAGCOReport: false,
      EnableAutoMatchingDuringReceive: false,
      EnableDelivery: false,
      EnablePlantSearch: true,
      FormValidation: false,
      GeneriPayG2: false,
      HasBioTrack: false,
      EnableUntrackedInventoryTransfers: false,
      HasJory: false,
      HasLooker: false,
      HasLookerExplorer: false,
      HasMETRC: false,
      EnableManualPayments: false,
      HasPaymentsHub: false,
      HasPercentPaymentFees: false,
      HasPurchasOrders: false,
      HasReThink: false,
      HasVCCB: false,
      HasUtahICS: false,
      HasMMUR: false,
      HasVirginiaBioTrack: false,
      HideMarketingHighlights: false,
      InvReconUpdateInBulk: false,
      IsLeaflyEnabled: false,
      IsLicensed: false,
      IsWeedMapsEnabled: false,
      IntegratedState: '',
      LicenseTypeIds: [],
      LocationSyncTool: false,
      LoyaltyAsCartDiscount: false,
      ManagerPinRequiredInventoryAdjustmentThreshold: false,
      Manufacturing: false,
      MedAndRecShareBasePrice: false,
      MetrcBatchMode: false,
      METRCFinishPackages: false,
      NewIAManufacturingModule: false,
      NoDoublePreOrder: false,
      NYSPMP: false,
      OrdersbyProduct: false,
      PlantReconciliation: false,
      RecFlowerEquiv: false,
      ShowBulkCustomerPiiWipeTool: false,
      ShowCultivationIntegrationReconcilation: false,
      ShowCultivationTax: false,
      ShowExciseTaxOnReceive: false,
      ShowExternalLoyalty: false,
      ShowLeafLogixLoyalty: false,
      ShowStateBypass: false,
      SendWetWeightToInventory: false,
      TaxInclusivePricing: false,
      TrackInventoryIds: false,
      UseAdilas: false,
      UseAlpineIQ: false,
      UseBioMauris: false,
      UseBioTrack: false,
      UseCCRS: false,
      UseDistillationMethod: false,
      UseFeesDonations: false,
      UseFlowerEquivalencyDefinition: false,
      UseFyllo: false,
      UseTrym: false,
      UseIDScan: false,
      UseInfusions: false,
      UseLeaf: false,
      UseLeafLink: false,
      UseManufacturers: false,
      UseEmailMarketing: false,
      HasMCDMS: false,
      UseMCDMS: false,
      UseMETRC: false,
      UseMMUR: false,
      UseMn: false,
      UseMultiPackageCreator: false,
      UseMultiStopManifests: false,
      UseNewPOS: false,
      UseOnFleet: false,
      UseOrderFulfill: false,
      UseLabPackageDate: false,
      UsePMP: false,
      UseQuickBooks: false,
      UseSkylightDebitIntegration: false,
      UseSpinDebitIntegration: false,
      UseSpringBig: false,
      UseSprout: false,
      UseTookan: false,
      UseTranCloudDebitIntegration: false,
      UseWorkWave: false,
      UseVCCB: false,
      UseUtahICS: false,
      ViewOrgManagement: false,
      WeedmapsV2: false,
      WholesaleCarsAndDrivers: false,
      ShowPinDebitNonCashAdjustment: false,
      ShowSourcePackage: false,
      HasPlanetPayments: false,
      HasPaynetworx: false,
      UseSSO: false,
      FixedTablesBeta: false,
      ShowZeroInventory: false,
      LoyaltyAsDiscount: false,
      LocationBasedDelivery: false,
      HasDutchiePay: false,
      POSStatus: false,
      UseOCS: false,
      CustomerPaymentSurcharge: false,
      UseVirginiaBioTrack: false,
      HasPaymentsHubWhiteLabel: false,
      UsePINLogin: false,
      UseMETRCUseByDate: false,
      EnableNutrientTracking: false,
      TrackImmaturePlantsIndividually: false,
      ScanParentPackageSerialNumber: false,
    },
    lspSettings: {
      InventoryAdjustmentThreshold: null,
      SessionTimeoutMinutes: null,
    },
    integrations: false,
    data: {
      SessionId: '',
    },
    tableSettings: [],
    validatedForms: [],
  },
  effects_UNSTABLE: [useSyncStateEffect],
});

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListenerForUserState() {
    const {
      locations,
      selectedLocation,
      companies,
      customerTypes,
      selectedCompany,
      locProfile,
      lspSettings,
      data,
      features,
      integrations,
      tableSettings,
      validatedForms, // SHOULD THIS EVENTUALLY BE ITS OWN QUERY/MUTATION WITH RQ? i.e. using the `get_validated_forms` endpoint
    } = UserStore.getState();

    setSelf({
      locations,
      selectedLocation,
      companies,
      customerTypes,
      selectedCompany,
      locProfile,
      lspSettings,
      data,
      features,
      integrations,
      tableSettings,
      validatedForms,
    });
  }

  if (trigger === 'get') {
    changeListenerForUserState(); // initial call
  }

  // Subscribe to remote storage changes and update the atom value
  UserStore.addChangeListener(changeListenerForUserState);

  // Cleanup remote storage subscription
  return () => UserStore.removeChangeListener(changeListenerForUserState);
}

const featureCheck = selectorFamily({
  key: 'locFeatureCheck',
  get:
    (key: { keys: Features | Features[]; operator: 'and' | 'or' }) =>
    ({ get }) => {
      const { keys, operator } = key;
      const { locProfile, selectedLocation } = get(userDispensariesAtom);

      if (typeof keys === 'string') {
        return !!locProfile[keys] || !!selectedLocation[keys];
      }

      if (operator === 'or') {
        return _.some(keys, (feat) => !!locProfile[feat]) || _.some(keys, (feat) => !!selectedLocation[feat]);
      }

      return _.every(keys, (feat) => !!locProfile[feat]) || _.every(keys, (feat) => !!selectedLocation[feat]);
    },
});

/**
 * Verify that an loc has feature(s) defined on locProfile or selectedLocation
 * @param keys single or array of feature keys
 * @param operator determine if the check should be for all or any features passed in
 * @returns true | false
 */
export function useFeatureCheck(keys: Features | Features[], operator: 'and' | 'or' = 'and') {
  return useRecoilValue(featureCheck({ keys, operator }));
}
