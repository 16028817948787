import { useMemo, useState } from 'react';

import { Direction, FilterOperator, LogicalOperator } from 'src/app/queries/types/gql-filtering-and-pagination';

import { maxPageSize } from '..';

import { useInfiniteBillOfMaterialsQuery } from './get-many';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';
import type { QueryProps } from 'src/app/queries/types/gql-filtering-and-pagination';
import type { BillOfMaterialFilterInput, BillOfMaterialSortInput } from 'src/gql/graphql';

export function useBillOfMaterialDropdownData() {
  const [filter, setFilter] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);

  const queryProps: QueryProps<BillOfMaterialFilterInput, BillOfMaterialSortInput> = useMemo(
    () => ({
      filterModel: [
        {
          value: filter,
          fields: ['name'],
          operator: FilterOperator.CONTAINS,
          logicalOperator: LogicalOperator.OR,
        },
        {
          value: false,
          fields: ['isDeleted'],
          operator: FilterOperator.EQUALS,
          logicalOperator: LogicalOperator.AND,
        },
      ],
      page: 0,
      pageSize,
      sort: [{ key: 'name', direction: Direction.ASC }],
    }),
    [filter, pageSize]
  );

  const {
    data: billOfMaterials,
    isFetching,
    error,
    fetchNextPage,
    morePagesExist,
    fetchAllBillOfMaterials,
  } = useInfiniteBillOfMaterialsQuery(queryProps);

  function mapOptions(items: ReturnType<typeof useInfiniteBillOfMaterialsQuery>['data']): DropdownMenuOption[] {
    return items.map((bom) => ({
      label: bom.name ?? '',
      id: bom.billOfMaterialsId ?? 0,
      value: bom.billOfMaterialsId,
      key: `${bom.billOfMaterialsId}`,
    }));
  }

  const vendorOptions = useMemo(() => mapOptions(billOfMaterials), [billOfMaterials]);

  async function onSelectAll() {
    setIsLoading(true);
    const result = await fetchAllBillOfMaterials();
    const newOptions = mapOptions(result);
    setPageSize(maxPageSize);
    setIsLoading(false);
    return newOptions;
  }

  return {
    error,
    isFetching: isFetching || isLoading,
    fetchNextPage,
    options: vendorOptions,
    morePagesExist,
    setFilter,
    onSelectAll,
  };
}
