import React, { useState, useEffect } from 'react';

import i18next from 'i18next';
import styled from 'styled-components';

import UserStore from 'src/app_deprecated//stores/UserStore';
import { endPoints } from 'src/app_deprecated/constants/InventoryConstants';
import AjaxPromises from 'src/app_deprecated/utils/AjaxPromises';

import { Input, InputAdornment, TableInput } from 'src/app/components/lib/input';

import { LinkButton } from '../../lib/button';

export type PackageIdInputProps = {
  automationId?: string;
  disabled?: boolean;
  disablePackageIdGeneration?: boolean;
  gridColumns?: number;
  label?: string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  onBlur?: (e) => void;
  onChange: (e) => void;
  onFocus?: (e) => void;
  placeholder?: string;
  product?: any;
  required?: boolean;
  systemGenerated?: boolean;
  tableVariant?: boolean;
  tooltip?: string;
  value: number | string;
};

export function PackageIdInput(props: PackageIdInputProps) {
  const [generating, setGenerating] = useState(false);
  const {
    automationId,
    product,
    value,
    label,
    labelPlacement,
    required,
    disabled,
    gridColumns,
    onChange,
    onBlur,
    onFocus,
    placeholder = '',
    tableVariant,
    disablePackageIdGeneration = false,
    systemGenerated,
    tooltip,
  } = props;

  useEffect(() => {
    if (generating) {
      const handleGenerate = async () => {
        const data = UserStore.getApiData({
          StrainID: product?.StrainId || null,
          ProductId: product?.ProductId || null,
        });
        try {
          const resp = await AjaxPromises.POST(endPoints.GENERATE_PACKAGE_ID, data);
          if (resp.Result) {
            const SerialNumber = resp.Data[0].SerialNo;
            onChange(SerialNumber);
          }
        } catch {
        } finally {
          setGenerating(false);
        }
      };
      void handleGenerate();
    }
  }, [generating]);

  const generateSerialNumber = async () => {
    if (!value) {
      setGenerating(true);
    }
  };

  const setSerialNumber = (event) => {
    const SerialNumber = event.target.value;
    onChange(SerialNumber);
  };

  const InputElement = tableVariant ? TableInput : Input;
  const LinkButtonElement = tableVariant ? TableLinkButton : LinkButton;

  return (
    <InputElement
      automationId={automationId}
      className='form-control form-control--package-id'
      data-testid='package-id_input_text'
      disabled={disabled || systemGenerated}
      endAdornment={
        !disabled && (
          <InputAdornment position='end'>
            <LinkButtonElement
              automationId='generate-package-id-button'
              disabled={generating || !!value || disablePackageIdGeneration || systemGenerated}
              label={i18next.t('Generate')}
              loading={generating}
              onClick={generateSerialNumber}
            />
          </InputAdornment>
        )
      }
      gridColumns={gridColumns}
      label={label}
      labelPlacement={labelPlacement}
      placeholder={placeholder}
      required={required}
      tooltip={tooltip}
      type='text'
      value={systemGenerated ? 'System generated' : value}
      onBlur={(e: any) => {
        if (onBlur) {
          onBlur(e.target.value);
        }
      }}
      onChange={setSerialNumber}
      onFocus={(e: any) => {
        if (onFocus) {
          onFocus(e.target.value);
        }
      }}
    />
  );
}

const TableLinkButton = styled(LinkButton)`
  font: var(--font-extra-small-12pt-semibold);
  padding: var(--sizes-10) 10px var(--sizes-10) var(--sizes-20);
`;
