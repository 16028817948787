import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { Features } from 'src/app/state/types/loc-profile';
import { useFeatureCheck } from 'src/app/state/user-dispensaries';
import { GetPackageDocument, GetPackagesDocument, GetPackagesV2Document, GetPackageV2Document } from 'src/gql/graphql';

export const BASE_SCANNABLE_FIELDS = ['parentPackageSerialNumber', 'serialNumber'];
export const BASE_SEARCHABLE_AUDIT_FIELDS = [
  'package.parentPackageSerialNumber',
  'product.whseProductsDescription',
  'serialNo',
];
export const BASE_SEARCHABLE_FIELDS = [
  'parentPackageSerialNumber',
  'product.whseProductsProductNo',
  'product.whseProductsDescription',
  'serialNumber',
  'sourceSerialNumber',
  'batch.batchLotNumber',
  'batch.batchStrainNavigation.strainName',
  'product.strain.strainName',
  'metrclabTestingStatus',
];

export function useDeterministicDocumentData() {
  const includeParentPackageSerialNumber = useFeatureCheck(Features.ScanParentPackageSerialNumber);

  const flags = useFlags();
  const metrcRetailIds = flags?.['pos.traceability.metrc-retail-id.rollout'] ?? false;

  return useMemo(() => {
    const getApplicablePackageDocument = () => {
      if (metrcRetailIds) {
        return GetPackageV2Document;
      }
      return GetPackageDocument;
    };

    const getApplicablePackagesDocument = () => {
      if (metrcRetailIds) {
        return GetPackagesV2Document;
      }
      return GetPackagesDocument;
    };

    return {
      ApplicablePackageDocument: getApplicablePackageDocument(),
      ApplicablePackagesDocument: getApplicablePackagesDocument(),
      includeParentPackageSerialNumber,
    };
  }, [includeParentPackageSerialNumber, metrcRetailIds]);
}
