import Units from 'src/app_deprecated/utils/UnitConversion';

import { useAssemblyFormStateContext } from 'src/app/pages/manufacturing/assemblies/detail/use-form-state';

import type { OutputItem } from '../../../types';

type UnitTypes = (typeof Units.UnitTypes)[keyof typeof Units.UnitTypes];

export function getRequiredUnitTypes(outputs: OutputItem[]) {
  return outputs.reduce<Set<UnitTypes>>((acc, output) => {
    const outputUnitType = Units.getUnitTypeId(output.unitId);
    if (outputUnitType) {
      acc.add(outputUnitType);
    }

    const inputUnitTypes = output.inputItems.reduce<Set<UnitTypes>>((acc, input) => {
      const inputUnitType = Units.getUnitTypeId(input.unitId);
      if (inputUnitType) {
        acc.add(inputUnitType);
      }

      const inputPackageUnitTypes = input.assemblyInputPackages.reduce<Set<UnitTypes>>((acc, pkg) => {
        const packageUnitType = Units.getUnitTypeId(pkg.unitId);
        if (packageUnitType) {
          acc.add(packageUnitType);
        }

        return acc;
      }, new Set<UnitTypes>());

      inputPackageUnitTypes.forEach((unitType) => acc.add(unitType));

      return acc;
    }, new Set<UnitTypes>());

    inputUnitTypes.forEach((unitType) => acc.add(unitType));

    return acc;
  }, new Set<UnitTypes>());
}

export function useGetRequiredUnitTypes() {
  const [{ formData }] = useAssemblyFormStateContext();
  const requiredUnitTypes = getRequiredUnitTypes(formData.outputs);
  return { requiredUnitTypes };
}
