import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import { bomKeys } from '../graphql/bill-of-materials/query-key-factory';

import type { AxiosError } from 'axios';

export type UpdateBOMRetirementStatusPayload = {
  BillOfMaterialId: number;
  Status: RetirementStatus;
};

const endpoint = 'api/manufacturing/bill-of-material/';

export enum RetirementStatus {
  RETIRE = 'retire',
  UNRETIRE = 'unretire',
}

export type UpdateBOMRetirementStatusResponse = {
  Data: null;
  Message: string;
  Result: boolean;
};

export function useUpdateBOMRetirementStatus() {
  const queryClient = useQueryClient();
  return useMutation<
    UpdateBOMRetirementStatusResponse,
    AxiosError<{ Data: string; Message: string }>,
    UpdateBOMRetirementStatusPayload
  >({
    mutationKey: ['update-bom-retirement-status'],
    mutationFn: (payload) => postData({ payload, endpoint: `${endpoint}${payload.Status}` }),
    onSuccess: () => void queryClient.resetQueries({ queryKey: bomKeys.all }),
  });
}
