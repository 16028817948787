import { useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useAssemblyQuery } from 'src/app/queries/graphql/assemblies/get-one';
import { useBillOfMaterialDropdownData } from 'src/app/queries/graphql/bill-of-materials/use-dropdown-data';
import { useStatusesDropdownData } from 'src/app/queries/graphql/inventory-statuses/use-dropdown-data';
import { routes } from 'src/app/routes';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

import { CostType } from '../constants';

import type { AssemblyDetailFormProps } from '.';

export function useAssemblyFormData({ formType }: AssemblyDetailFormProps) {
  const { integrations, selectedLocation } = useRecoilValue(userDispensariesAtom);
  const { WhichUnitCost = '' } = selectedLocation;
  const defaultCostType =
    WhichUnitCost in CostType ? CostType[WhichUnitCost as keyof typeof CostType] : CostType.Product;
  const { UseMetrcV2, UseMetrcProcessingJobs } = integrations ?? {};
  const navigate = useNavigate();
  const navigateBackToListPage = () => navigate(routes.manufacturing.assemblies);
  const isEditing = formType === 'edit';
  const { assemblyId } = useParams();
  const enableFetch = useMemo(
    () => isEditing && assemblyId !== undefined && !Number.isNaN(Number(assemblyId)),
    [assemblyId, isEditing]
  );
  // Fetch the existing assembly data if we are editing
  const {
    data: assemblyData,
    isLoading: isLoadingAssemblyData,
    isError: isAssemblyDataError,
  } = useAssemblyQuery({
    assemblyId: Number(assemblyId),
    enabled: enableFetch,
    onError: navigateBackToListPage,
  });
  // Fetch a list of BOMs for the lsp to hydrate the BOM dropdown
  const bomDropDownData = useBillOfMaterialDropdownData();
  // Fetch a list of inventory statuses used in the output items
  const {
    options: inventoryStatusOptions,
    isFetching: isFetchingInventoryStatuses,
    error: errorLoadingInventoryStatuses,
  } = useStatusesDropdownData();

  const isLoading = useMemo(
    () => (isLoadingAssemblyData && isEditing) || isFetchingInventoryStatuses || bomDropDownData.isFetching,
    [isLoadingAssemblyData, isEditing, isFetchingInventoryStatuses, bomDropDownData.isFetching]
  );

  const hasErrors = useMemo(
    () => isAssemblyDataError || errorLoadingInventoryStatuses || bomDropDownData.error,
    [isAssemblyDataError, errorLoadingInventoryStatuses, bomDropDownData.error]
  );

  const assemblyNotFound = useMemo(() => enableFetch && isAssemblyDataError, [enableFetch, isAssemblyDataError]);

  return {
    assemblyData,
    assemblyNotFound,
    dropdownOptions: {
      inventoryStatusOptions,
      bomDropDownData,
    },
    integrations: { UseMetrcV2, UseMetrcProcessingJobs },
    locationSettings: {
      defaultCostType,
    },
    isLoading,
    hasError: hasErrors,
  };
}
