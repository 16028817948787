import type { ReactNode } from 'react';
import React from 'react';

import styled from 'styled-components';

import { Loader } from '../loader';

import { CardContainer, CardHeader, CardTitle, CardContent, CardFooter, CardSlot } from './content-card.styles';

export type ContentCardProps = {
  addContentPadding?: boolean;
  automationId?: string;
  children: ReactNode;
  className?: string;
  contentColor?: string;
  footerPrimary?: ReactNode;
  footerSecondary?: ReactNode;
  headerActions?: ReactNode;
  isLoading?: boolean;
  marginBottom?: string;
  marginTop?: string;
  title?: string;
  titleAdornment?: ReactNode;
  titleBorderBottom?: string;
};

export function ContentCard({
  isLoading = false,

  automationId,
  children,
  contentColor,
  footerPrimary,
  footerSecondary,
  headerActions,
  title = '',
  titleAdornment,
  className = '',
  marginTop,
  marginBottom,
  titleBorderBottom,
  addContentPadding = true,
}: ContentCardProps) {
  const hasFooterContent = Boolean(footerSecondary) || Boolean(footerPrimary);
  const hasHeaderContent = Boolean(title) || Boolean(titleAdornment);

  return (
    <CardContainer $marginBottom={marginBottom} className={className} data-testid={automationId}>
      {hasHeaderContent && (
        <CardHeader $borderBottom={titleBorderBottom} className='content-card--card-header'>
          <div className='content-card--label-container'>
            <CardTitle className='content-card--card-title'>{title}</CardTitle>
            {Boolean(titleAdornment) && titleAdornment}
          </div>
          <CardSlot className='content-card--label-container'>{headerActions}</CardSlot>
        </CardHeader>
      )}
      <CardContent
        $addContentPadding={addContentPadding}
        $contentColor={contentColor}
        $marginTop={marginTop}
        className='content-card--card-content-article'
      >
        {isLoading && (
          <StyledWrapper>
            <Loader size='3x' variant='grey' />
          </StyledWrapper>
        )}
        {!isLoading && children}{' '}
      </CardContent>
      {hasFooterContent && (
        <CardFooter>
          <CardSlot className='content-card--footer-secondary-actions'>{footerSecondary}</CardSlot>
          <CardSlot className='content-card--footer-primary-actions'>{footerPrimary}</CardSlot>
        </CardFooter>
      )}
    </CardContainer>
  );
}

const StyledWrapper = styled.div`
  background-color: var(--color-brand-primary-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--sizes-70);
  width: 100%;
  height: 300px;
`;
