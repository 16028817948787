import React, { useEffect, useState } from 'react';

import { AccordionChevron } from '../../icons/accordion-chevron';

import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionHeaderLeft,
  AccordionHeaderRight,
  AccordionWrapper,
} from './accordion.styles';

type AccordionProps<T> = {
  caratPosition?: 'left' | 'right';
  containerStyleOverrides?: React.CSSProperties;
  contentStyleOverrides?: React.CSSProperties;
  defaultExpanded?: number[];
  headerStyleOverrides?: (isOpen: boolean) => React.CSSProperties;
  items: (T & { disabled?: boolean })[];
  multiExpand?: boolean;
  onToggle?: (index: number, isOpen: boolean) => void;
  renderContent: (item: T, index: number, isOpen: boolean) => React.ReactNode;
  renderHeaderLeft?: (item: T, index: number, isOpen: boolean) => React.ReactNode;
  renderHeaderRight?: (item: T, index: number, isOpen: boolean) => React.ReactNode;
};

/**
 * A flexible accordion component that allows toggling of multiple or single sections.
 *
 * @template T - The type of the items displayed in the accordion.
 *
 * @param {AccordionProps<T>} props - The props for the Accordion component.
 * @param {T[]} props.items - The items to render within the accordion.
 * @param {number[]} [props.defaultExpanded=[]] - Array of indices specifying which items should be expanded by default.
 * @param {'left' | 'right'} [props.caratPosition='left'] - The position of the chevron in the accordion header.
 * @param {boolean} [props.multiExpand=false] - Determines whether multiple accordion items can be expanded at the same time.
 * @param {React.CSSProperties} [props.containerStyleOverrides] - Optional styles to apply to the accordion container.
 * @param {React.CSSProperties} [props.contentStyleOverrides] - Optional styles to apply to the content of each accordion item.
 * @param {(isOpen: boolean) => React.CSSProperties} [props.headerStyleOverrides] - Optional function to apply styles to the header of each accordion item.
 * @param {(index: number, isOpen: boolean) => void} [props.onToggle] - Callback triggered when an accordion item is toggled.
 * It passes the index of the item and its new expanded/collapsed state.
 * @param {(item: T, index: number, isOpen: boolean) => React.ReactNode} props.renderContent - Function to render the content of each accordion item.
 * @param {(item: T, index: number, isOpen: boolean) => React.ReactNode} [props.renderHeaderLeft] - Function to render the left section of the accordion header (e.g., a title).
 * @param {(item: T, index: number, isOpen: boolean) => React.ReactNode} [props.renderHeaderRight] - Function to render the right section of the accordion header (e.g., actions or buttons).
 *
 * @returns {JSX.Element} The rendered accordion component.
 */
export function Accordion<T>({
  items,
  renderHeaderLeft,
  renderHeaderRight,
  renderContent,
  defaultExpanded = [],
  containerStyleOverrides = {},
  contentStyleOverrides = {},
  headerStyleOverrides = () => ({}),
  multiExpand = false,
  caratPosition = 'left',
  onToggle,
}: AccordionProps<T>) {
  const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>(() =>
    items.reduce<Record<number, boolean>>((acc, item, index) => {
      acc[index] = item?.disabled ? false : defaultExpanded.includes(index);
      return acc;
    }, {})
  );

  // this is used for dynamic accordion items to ensure they get added to the state
  useEffect(() => {
    setExpandedItems((prevState) => {
      // Only update the state if there's a difference in the items length
      if (items.length === Object.keys(prevState).length) {
        return prevState; // No need to update if the number of items hasn't changed
      }

      return items.reduce<Record<number, boolean>>((acc, _, index) => {
        acc[index] = prevState[index] ?? defaultExpanded.includes(index);
        return acc;
      }, {});
    });
  }, [items, defaultExpanded]);

  const toggleItem = (index: number) => {
    setExpandedItems((prevState) => {
      const isOpen = !prevState[index];

      const newState = multiExpand
        ? { ...prevState, [index]: isOpen }
        : Object.keys(prevState).reduce<Record<number, boolean>>((acc, key) => {
            acc[+key] = +key === index ? isOpen : false;
            return acc;
          }, {});

      onToggle?.(index, newState[index]);
      return newState;
    });
  };

  return (
    <AccordionWrapper data-testid='accordion_wrapper'>
      {items.map((item, index) => {
        const isExpanded = item?.disabled ? false : expandedItems[index];
        const key = `${index}-${isExpanded ? 'expanded' : 'closed'}-${item.disabled ? 'disabled' : 'enabled'}`;
        return (
          <AccordionContainer key={key} style={containerStyleOverrides}>
            <AccordionHeader
              aria-controls={`accordion_content_${index}`}
              aria-expanded={isExpanded}
              data-testid={`accordion_header_${index}`}
              role='button'
              style={headerStyleOverrides(isExpanded)}
              onClick={() => {
                if (!item?.disabled) {
                  toggleItem(index);
                }
              }}
            >
              {caratPosition === 'left' && (
                <AccordionChevron data-testid={`accordion_chevron_${index}`} isOpen={isExpanded} />
              )}
              {/* Optional AccordionHeader Left */}
              {!!renderHeaderLeft && (
                <AccordionHeaderLeft>{renderHeaderLeft(item, index, isExpanded)}</AccordionHeaderLeft>
              )}

              {/* Optional AccordionHeaderRight */}
              {!!renderHeaderRight && (
                <AccordionHeaderRight onClick={(e) => e.stopPropagation()}>
                  {renderHeaderRight(item, index, isExpanded)}
                </AccordionHeaderRight>
              )}
              {caratPosition === 'right' && (
                <AccordionChevron data-testid={`accordion_chevron_${index}`} isOpen={isExpanded} />
              )}
            </AccordionHeader>

            {/* Accordion Content */}
            <AccordionContent
              aria-labelledby={`accordion_header_${index}`}
              data-testid={`accordion_content_${index}`}
              id={`accordion_content_${index}`}
              isOpen={isExpanded}
              role='region'
              style={isExpanded ? contentStyleOverrides : {}}
            >
              {renderContent(item, index, isExpanded)}
            </AccordionContent>
          </AccordionContainer>
        );
      })}
    </AccordionWrapper>
  );
}
