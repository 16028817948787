import { RetirementStatus } from 'src/app/queries/manufacturing/update-bom-retirement-status';

export const NAME_LENGTH_MESSAGE = 'Name must be less than 100 characters';
export const BOM_SEARCH_PLACEHOLDER = 'Search BOMs...';
export const BOM_LIST_PAGE_HEADER = 'Bill of materials';
export const ERROR_FETCHING_BOM_MSG = 'Error fetching BOM';
export const SHOW_RETIRED_BOMS_CHECKBOX_LABEL = 'Show retired BOMs';

// Create BOM Constants
export const CREATE_BOM_PAGE_TITLE = 'Create new BOM';
// Retire BOM Constants
export const RETIRE_BOM_MODAL_TITLE = 'Retire Bill of Material';
export const RETIRE_BOM_MODAL_BODY_TEXT = 'Are you sure you want to retire this Bill of Material?';
export const RETIRE_BOM_DEFAULT_ERR_MSG = 'Error retiring BOM';
export const RETIRE_BOM_SUCCESS_MSG = 'Bill of Material retired successfully';
// Unretire BOM Constants
export const UNRETIRE_BOM_MODAL_TITLE = 'Unretire Bill of Material';
export const UNRETIRE_BOM_MODAL_BODY_TEXT = 'Are you sure you want to unretire this Bill of Material?';
export const UNRETIRE_BOM_DEFAULT_ERR_MSG = 'Error unretiring BOM';
export const UNRETIRE_BOM_SUCCESS_MSG = 'Bill of Material unretired successfully';
// Retire BOM Message Map
export const messageMap = {
  [RetirementStatus.RETIRE]: {
    helpText: RETIRE_BOM_MODAL_BODY_TEXT,
    title: RETIRE_BOM_MODAL_TITLE,
    successMsg: RETIRE_BOM_SUCCESS_MSG,
    errorMsg: RETIRE_BOM_DEFAULT_ERR_MSG,
  },
  [RetirementStatus.UNRETIRE]: {
    helpText: UNRETIRE_BOM_MODAL_BODY_TEXT,
    title: UNRETIRE_BOM_MODAL_TITLE,
    successMsg: UNRETIRE_BOM_SUCCESS_MSG,
    errorMsg: UNRETIRE_BOM_DEFAULT_ERR_MSG,
  },
};

// FORM Validation Constants
export const UNIT_REQUIRED_MESSAGE = 'Unit is required';
export const PRODUCT_ID_REQUIRED_MESSAGE = 'A product selection is required';
export const PRODUCT_TYPE_ID_REQUIRED_MESSAGE = 'A category is required';
export const PROCESSING_STEPS_REQUIRED_MESSAGE = 'Processing Steps are required when processing job is enabled';
export const PROCESSING_STEPS_MAX_LENGTH_MESSAGE = 'Processing Steps cannot exceed 2500 characters';
export const EMPTY_OUTPUTS_HEADER = 'No outputs added';
export const EMPTY_OUTPUTS_BODY = 'Specify the products that this BOM will create once executed.';
export const EMPTY_INPUTS_HEADER = 'No inputs added';
export const EMPTY_INPUTS_BODY =
  'Add inputs to specify the products and their required amounts that are needed to create this output.';
export const COPY_INPUTS_ERROR_TOO_FEW_OUTPUTS = 'You must have more than one output added to copy these inputs.';
export const COPY_INPUTS_ERROR_NO_INPUTS = 'You must have at least one input added to copy inputs.';
export const COPY_INPUTS_SUCCESS = 'Copy inputs to other outputs';
export const PROCESSING_JOB_INFO_BANNER =
  'When enabled, starting an assembly associated with this BOM will generate a Metrc Processing Job Type for the license linked to the location where the assembly is being started.';

export const ADD_BY_CATEGORY_ACTION = 'Add by category';
export const ADD_BY_PRODUCT_ACTION = 'Add by product';
