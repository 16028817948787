import { useMemo } from 'react';

import { usePermissionsQuery } from 'src/app/queries/permissions/get-permissions';
import { Permissions } from 'src/app/state/user-permissions';

export function useInventoryPermissions() {
  const { data: permissions } = usePermissionsQuery();

  const inventoryPermissions = useMemo(
    () => ({
      canAdjustInventory: permissions?.includes(Permissions.AdjustInventory) || false,
      canAddItemsToManifest: permissions?.includes(Permissions.AddItemstoManifest) || false,
      canAssignBatch: permissions?.includes(Permissions.AssignBatch) || false,
      canUpdatePackageData: permissions?.includes(Permissions.UpdatePackageData) || false,
      canMoveInventory: permissions?.includes(Permissions.MoveInventory) || false,
      canMoveInventoryLocation: permissions?.includes(Permissions.MoveInventoryLocation) || false,
      canChangeInventoryProduct: permissions?.includes(Permissions.ChangeInventoryProduct) || false,
      canCombineInventory: permissions?.includes(Permissions.CombineInventory) || false,
      canConvertInventory: permissions?.includes(Permissions.ConvertInventory) || false,
      canCreateAudit: permissions?.includes(Permissions.CreateReconciliation) || false,
      canCreateInventoryPackages: permissions?.includes(Permissions.CreateInventoryPackages) || false,
      canDestroyInventory: permissions?.includes(Permissions.DestroyInventory) || false,
      canDiscontinuePackage: permissions?.includes(Permissions.DiscontinuePackage) || false,
      canEditLabSamples: permissions?.includes(Permissions.EditLabSamples) || false,
      canPrintInventoryLabels: permissions?.includes(Permissions.PrintInventoryLabels) || false,
      canViewInventory: permissions?.includes(Permissions.ViewInventory) || false,
      canViewInventoryTransactions: permissions?.includes(Permissions.ViewInventoryTransactions) || false,
      isSuperuser: permissions?.includes(Permissions.SuperUser) || false,
      canSublot: permissions?.includes(Permissions.Sublot) || false,
      canTransferPlantToInventory: permissions?.includes(Permissions.TransferPlantToInventory) || false,
      canViewPackageHistory: permissions?.includes(Permissions.ViewPackageHistory) || false,
      canEditPackageTags: permissions?.includes(Permissions.EditPackageTags) || false,
      canReceiveInventory: permissions?.includes(Permissions.ReceiveInventory) || false,
      canManageInventoryAllocation: permissions?.includes(Permissions.ManageInventoryAllocation) || false,
      canManuallyFetchQrCodes: permissions?.includes(Permissions.ManuallyFetchQrCodes) || false,
    }),
    [permissions]
  );

  return inventoryPermissions;
}
