import { ALL_ENABLED_CUSTOMER_TYPES, NO_VALID_CUSTOMER_TYPES } from 'src/app/pages/products/inventory-v2/constants';

import type { CustomerType } from 'src/app/queries/customers/get-enabled-customer-types';
import type { InventoryPackageAttributesV2Fragment } from 'src/gql/graphql';

export type InventoryRowArg = InventoryPackageAttributesV2Fragment & { CustomerTypes: number[] };

export function getPackageCustomerTypesFromApi(packageData?: InventoryPackageAttributesV2Fragment) {
  const packageCustomerTypes =
    packageData?.packageToCustomerTypes?.map((ptct) => ptct?.customerType?.customerTypeId || 0) ?? [];
  if (packageCustomerTypes.length) {
    return packageCustomerTypes;
  }
  // if we don't have any package level customer types, we use the catalog level
  return packageData?.product?.productToCustomerTypes?.map((ptct) => ptct?.customerType?.customerTypeId || 0) ?? [];
}

export function getPackageCustomerTypesById(
  packageData: InventoryPackageAttributesV2Fragment | undefined,
  enabledCustomerTypeIds: number[] | undefined
) {
  const packageCustomerTypes =
    packageData?.packageToCustomerTypes
      ?.filter((ptct) => enabledCustomerTypeIds?.includes(ptct?.customerType?.customerTypeId || 0))
      ?.map((ptct) => ptct?.customerType?.customerTypeId || 0) ?? [];
  if (packageCustomerTypes.length) {
    return packageCustomerTypes;
  }
  // if we don't have any package level customer types, we use the catalog level
  return (
    packageData?.product?.productToCustomerTypes
      ?.filter((ptct) => enabledCustomerTypeIds?.includes(ptct?.customerType?.customerTypeId || 0))
      ?.map((ptct) => ptct?.customerType?.customerTypeId || 0) ?? []
  );
}

export function getPackageCustomerTypesByName(
  packageData: InventoryRowArg | undefined,
  enabledCustomerTypeIds: number[]
) {
  const packageCustomerTypes =
    packageData?.packageToCustomerTypes
      ?.filter((ptct) => enabledCustomerTypeIds?.includes(ptct?.customerType?.customerTypeId || 0))
      ?.map((ptct) => ptct?.customerType?.customerTypeName ?? '') ?? [];
  if (packageCustomerTypes.length) {
    return packageCustomerTypes;
  }
  // if we don't have any package level customer types, we use the catalog level
  return (
    packageData?.product?.productToCustomerTypes
      ?.filter((ptct) => enabledCustomerTypeIds?.includes(ptct?.customerType?.customerTypeId || 0))
      ?.map((ptct) => ptct?.customerType?.customerTypeName ?? '') ?? []
  );
}

/**
 * Filters out Caregiver and Non-Cannabis customer types to since we don't want users to configure products with these types.
 * Sellability to Non-Cannabis type will depend on whether the product is a cannabis product or not and caregivers shop on behalf of other customer types.
 * Source of truth for CustomerTypeId comes from LeafLogix.Web/Dutchie.Common.Domain/Constants/CustomerTypeId.cs
 * @param customerTypes - Customer types that are enabled at the current location
 */
export function filterOutCustomerTypesCaregiverAndNonCannabisCustomerTypes(customerTypes?: CustomerType[]) {
  if (!customerTypes) {
    return [];
  }

  return customerTypes.filter((customerType) => ![16, 18].includes(customerType.CustomerTypeId)) || [];
}

type DoesPackageHaveValidCustomerTypesSetArgs = {
  catalogLevelCustomerTypeIds: number[];
  enabledCustomerTypeIds: number[];
  hasPackageLevelCustomerTypeOverrides: boolean;
};

export function doesPackageHaveValidCustomerTypesSet({
  catalogLevelCustomerTypeIds,
  enabledCustomerTypeIds,
  hasPackageLevelCustomerTypeOverrides,
}: DoesPackageHaveValidCustomerTypesSetArgs) {
  const catalogTypesDontMatchLocEnabledTypes =
    !!catalogLevelCustomerTypeIds.length &&
    !catalogLevelCustomerTypeIds.some((customerType) => enabledCustomerTypeIds.includes(customerType));

  // if there are package level overrides, we can assume the types are enabled by the location
  return hasPackageLevelCustomerTypeOverrides || !catalogTypesDontMatchLocEnabledTypes;
}

export function getCustomerTypeColumnValueForInventoryList(row: InventoryRowArg, enabledCustomerTypes: CustomerType[]) {
  const enabledCustomerTypeIds = enabledCustomerTypes?.map((customerType) => customerType.CustomerTypeId) ?? [];

  const customerTypes = getPackageCustomerTypesByName(row, enabledCustomerTypeIds);

  const filteredEnabledCustomerTypes = filterOutCustomerTypesCaregiverAndNonCannabisCustomerTypes(enabledCustomerTypes);

  if (customerTypes?.length > 0 && customerTypes?.length === filteredEnabledCustomerTypes?.length) {
    return ALL_ENABLED_CUSTOMER_TYPES;
  }
  // the API for this is that no customer types returned from the api means it's available to any customer type (unrestricted)
  if (customerTypes?.length === 0) {
    if (row?.CustomerTypes?.length === 0) {
      return ALL_ENABLED_CUSTOMER_TYPES;
    }
    return NO_VALID_CUSTOMER_TYPES;
  }
  return customerTypes?.join(', ');
}
