import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import { useInventoryPermissions } from 'src/app/pages/products/inventory-v2/use-inventory-permissions';
import { useUserSettingsQuery } from 'src/app/queries/settings/get-user-settings';

import { getConditionalFieldsFromUserSettings } from './utils';

// each key should match exactly a GraphQL variable used in a directive
// i.e. - src/app/queries/graphql/inventory-packages/attributes.graphql
export type ConditionalFields = {
  excludeAllocatedInventorySum: boolean;
  excludeLastAuditedDateUtc: boolean;
  excludeLastDatePackageAuditedUtc: boolean;
};

type FieldMap = {
  [key in keyof ConditionalFields]: string[];
};

// this is a map of the directive variable to the associated column field values
export const fieldMap: FieldMap = {
  excludeAllocatedInventorySum: ['allocatedInventorySum', 'allocatedPlusQuantity'],
  excludeLastAuditedDateUtc: ['product.lastAuditedDateUtc'],
  excludeLastDatePackageAuditedUtc: ['lastDatePackageAuditedUtc'],
};

export function useConditionalFieldsForInventoryRequests(): {
  conditionalFields: Partial<ConditionalFields> | undefined;
} {
  const flags = useFlags();
  const enableLastAuditedPackageDate = flags['pos.backoffice.last-audited-date-fix.rollout'];

  const { data: userSettings, isFetching } = useUserSettingsQuery();
  const { isSuperuser, canManageInventoryAllocation } = useInventoryPermissions();

  const computed = getConditionalFieldsFromUserSettings(userSettings);

  const gqlErrorMitigationFlagEnabled = useLDFlag(LD.GQL_ERROR_MITIGATION);

  if (gqlErrorMitigationFlagEnabled) {
    // new code
    if (computed) {
      if ((isSuperuser || canManageInventoryAllocation) && !computed.excludeAllocatedInventorySum) {
        computed.excludeAllocatedInventorySum = false;
      } else {
        computed.excludeAllocatedInventorySum = true;
      }
    }
    // old code path if flag is off
  } else if ((isSuperuser || canManageInventoryAllocation) && computed?.excludeAllocatedInventorySum) {
    computed.excludeAllocatedInventorySum = false;
  }

  const { fieldsToDisable = [] } = flags['pos.backoffice.inventory-columns.operational'] || {};
  // this flag controls beginning to use the new lastDatePackageAuditedUtc field so
  // we need to disable the old lastAuditedDateUtc field when this flag is enabled
  if (enableLastAuditedPackageDate) {
    fieldsToDisable.push('product.lastAuditedDateUtc');
  }
  // using the fieldMap above,
  // if any of the fields for a given variable are being excluded due to the LD directive,
  // we need to remove that field's associated variable from the variables object to avoid a 400 error
  if (fieldsToDisable.length > 0) {
    try {
      Object.entries(fieldMap).forEach(([variable, fields]) => {
        if (fields.some((field) => fieldsToDisable.includes(field))) {
          if (computed !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete computed[variable];
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  return useMemo(() => ({ conditionalFields: isFetching ? undefined : computed }), [computed, isFetching]);
}
