import { useQuery } from '@tanstack/react-query';

import { fetchMany, useInfiniteGQLQuery } from 'src/app/queries/graphql';
import { Direction } from 'src/app/queries/types/gql-filtering-and-pagination';
import { GetInventoryStatusesDocument } from 'src/gql/graphql';

import { extendInventoryStatus } from './extend';
import { inventoryStatusKeys } from './query-key-factory';

import type { AbridgedFilterInput, QueryProps } from '../../types/gql-filtering-and-pagination';
import type { TblInventoryStatusFilterInput, TblInventoryStatusSortInput } from 'src/gql/graphql';

export function useInventoryStatusesQuery() {
  return useQuery({
    queryKey: inventoryStatusKeys.all,
    queryFn: async () => {
      const inventoryStatuses = await fetchMany(GetInventoryStatusesDocument, {
        page: 0,
        // Supporting pagination and infinite scrolling is non-trivial.
        // In lieu of supporting pagination here,
        // we fetch a number that is >10x more than the largest data set we've seen in the app
        // as this resource list is always very small comparatively.
        pageSize: 1000,
        sort: [{ key: 'invStatusDesc', direction: Direction.ASC }],
      });
      const items = inventoryStatuses?.items?.filter(Boolean);
      return items?.map((item) => extendInventoryStatus(item));
    },
  });
}

export function useInfiniteInventoryStatusesQuery(
  queryProps: QueryProps<AbridgedFilterInput<TblInventoryStatusFilterInput>, TblInventoryStatusSortInput>
) {
  const result = useInfiniteGQLQuery({
    queryProps,
    queryDocument: GetInventoryStatusesDocument,
    queryKey: inventoryStatusKeys.many(queryProps, 'infinite'),
  });

  return { ...result, data: result.data?.map((item) => extendInventoryStatus(item)) };
}
