import React, { useMemo } from 'react';

import styled from 'styled-components';

import { QuantityInput } from 'src/app/components/form-elements/inputs/quantity';
import { TrashCan } from 'src/app/components/icons/trash-can';
import { IconButton } from 'src/app/components/lib/icon-button';
import { Status, CostType } from 'src/app/pages/manufacturing/assemblies/constants';

import { calculateTotalOutputCost } from '../../../../utils';
import { useAssemblyFormStateContext } from '../../../use-form-state';

import type { InputPackage } from '../../../../types';
import type { GridColDef } from 'src/app/components/lib/table';

export function useInputPackageColumns({ outputIndex, inputIndex }: { inputIndex: number; outputIndex: number }) {
  const [{ formData, errors, isReadonly }, dispatch] = useAssemblyFormStateContext();
  const isAssemblyInProgress = formData.assemblyStatusId === Status.IN_PROGRESS;
  const outputItem = formData.outputs[outputIndex];
  const { costType, quantity, inputItems, unitId } = outputItem;
  const calculateOutputCost = costType?.costTypeId === CostType.Calculated;
  const { assemblyInputPackages } = inputItems[inputIndex];

  const columns: GridColDef<InputPackage>[] = useMemo(
    () => [
      {
        field: 'serialNumber',
        headerName: 'Package',
        sortable: false,
        width: 150,
      },
      {
        field: 'useByDate',
        headerName: 'Use By Date',
        sortable: false,
        width: 150,
      },
      {
        field: 'roomName',
        headerName: 'Room',
        sortable: false,
        width: 150,
      },
      {
        field: 'batchName',
        headerName: 'Batch',
        sortable: false,
        width: 150,
      },
      {
        field: 'available',
        headerName: 'Available',
        sortable: false,
        width: 150,
      },
      {
        field: 'quantity',
        headerName: 'Amount',
        sortable: false,
        width: 250,
        renderCell: ({ row }) => {
          const rowIndex = assemblyInputPackages.findIndex((input) => input.uuid === row.uuid);
          return (
            <QuantityWrapper>
              <QuantityInput
                disabled={isAssemblyInProgress || isReadonly}
                error={
                  !!errors[
                    `outputs[${outputIndex}].inputItems[${inputIndex}].assemblyInputPackages[${rowIndex}.quantity`
                  ] || (row.quantity ?? 0) > row.availableQuantity
                }
                nameType='abbreviation'
                required
                tableVariant
                unitDisabled
                unitValue={(assemblyInputPackages[rowIndex].unitId ?? '').toString()}
                value={assemblyInputPackages[rowIndex].quantity ?? ''}
                onChange={({ value }) => {
                  dispatch({
                    type: 'set-input-package-value',
                    payload: {
                      outputIndex,
                      inputIndex,
                      inputPackageIndex: rowIndex,
                      key: 'quantity',
                      value: value ? parseFloat(value) : null,
                    },
                  });
                  if (calculateOutputCost) {
                    const calculatedOutputCost = calculateTotalOutputCost(
                      { quantity: quantity ?? 0, unitId },
                      inputItems
                    );
                    dispatch({
                      type: 'set-output-value',
                      payload: { outputIndex, key: 'cost', value: calculatedOutputCost },
                    });
                  }
                }}
              />
            </QuantityWrapper>
          );
        },
      },
      {
        field: 'rowAction',
        flex: 1,
        minWidth: 75,
        headerAlign: 'right',
        align: 'right',
        headerName: '',
        hideable: false,
        hideFromColumnConfig: true,
        resizable: false,
        sortable: false,
        renderCell: ({ row }) => {
          const rowIndex = assemblyInputPackages.findIndex((input) => input.uuid === row.uuid);
          const onClick = () => {
            dispatch({
              type: 'remove-input-package',
              payload: { outputIndex, inputIndex, inputPackageIndex: rowIndex },
            });
          };
          return (
            <IconButton
              aria-label='delete input'
              data-testid={`delete-input-${rowIndex}`}
              disabled={isAssemblyInProgress || isReadonly}
              style={{ width: 'fit-content' }}
              onClick={onClick}
            >
              <TrashCan height={20} />
            </IconButton>
          );
        },
      },
    ],
    [
      assemblyInputPackages,
      calculateOutputCost,
      dispatch,
      errors,
      inputIndex,
      inputItems,
      isAssemblyInProgress,
      isReadonly,
      outputIndex,
      quantity,
      unitId,
    ]
  );

  return columns;
}

const QuantityWrapper = styled.div`
  & .MuiTextField-root {
    width: 200px;
  }
`;
