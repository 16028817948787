import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postData } from 'src/app/queries';
import { assemblyKeys } from 'src/app/queries/graphql/assemblies/query-key-factory';

import type { FailedAssemblyResponse, StartAssemblyRequest } from './types';

export const endpoint = 'api/manufacturing/assembly/start';

export type StartAssemblyResponse = {
  Result: boolean;
};

export const useStartAssembly = () => {
  const queryClient = useQueryClient();
  return useMutation<StartAssemblyResponse, FailedAssemblyResponse, StartAssemblyRequest>({
    mutationKey: ['start-assembly'],
    mutationFn: (payload) => postData({ payload, endpoint }),
    onSuccess: () => void queryClient.resetQueries({ queryKey: assemblyKeys.all }),
  });
};
