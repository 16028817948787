import { endPoints as EndPoints } from '../constants/GeneralConstants';
import { endPoints as InventoryEndPoints } from '../constants/InventoryConstants';
import { endPoints as PosEndpoints } from '../constants/PosConstants';
import AjaxPromises from '../utils/AjaxPromises';

export default {
  getRooms: (formData) => AjaxPromises.POST(EndPoints.GET_ROOMS, formData),

  getLabels: (formData) => AjaxPromises.POST(EndPoints.GET_LABELS, formData),

  getStrains: (formData) => AjaxPromises.POST(InventoryEndPoints.GET_STRAINS, formData),

  getUsers: (formData) => AjaxPromises.POST(EndPoints.GET_USERS, formData),

  getPricingTiers: (formData) => AjaxPromises.POST(PosEndpoints.GET_CHARGE_CODES, formData),
};
