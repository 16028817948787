import { useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { userAtom } from 'src/app/state/user';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

const scriptId = 'decagon-script';

// more here if necessary https://docs.decagon.ai/adding-decagon-to-your-website/overview
export type DecagonApi = {
  close: () => void;
  hide: () => void;
  open: () => void;
  setMetadata: (metadata: Record<string, any>) => void;
  setUserId: (userId: string) => void;
};

export function useDecagon() {
  const [ready, setReady] = useState(false);
  const user = useRecoilValue(userAtom);
  const { locations, selectedLocation, companies, selectedCompany } = useRecoilValue(userDispensariesAtom);
  const activeLocation = (locations || [])?.find((loc) => loc.LocId === selectedLocation.LocId);
  const activeCompany = (companies || [])?.find((company) => company.LspId === selectedCompany.LspId);

  const decagon: DecagonApi | null = useMemo(() => {
    if (!ready || !window.duet) {
      return null;
    }
    return {
      hide: () => window.duet.hide(),
      close: () => window.duet.close(),
      open: () => window.duet.open(),
      setMetadata: (metadata) => window.duet.setMetadata(metadata),
      setUserId: (userId) => window.duet.setUserId(userId),
    };
  }, [ready]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://decagon.ai/loaders/dutchie.js';
    script.async = true;
    script.id = scriptId;
    script.addEventListener('load', () => {
      setReady(true);
      window.duet.hide();
    });

    if (!window.duet) {
      document.body.appendChild(script);
    }

    return () => {
      if (document.getElementById(scriptId)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (decagon) {
      decagon.setUserId(String(user.Id));
      decagon.setMetadata({
        userId: String(user.Id),
        location: activeLocation?.Name,
        company: activeCompany?.LspName,
        name: user.FullName,
        email: user.Email || user.UserName,
      });
    }
  }, [decagon, user, activeLocation, activeCompany]);

  return { decagon };
}
