import React, { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { MultiFilterSelect } from 'src/app/components/lib/multi-filter-select';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

import { getMultiSelectedOptions, parseSelectedIds } from './utils';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

type LastPurchaseLocationDropdownProps = {
  disabled?: boolean;
  onChange: (value: (number | string)[]) => void;
  values: string;
};

export type Location = {
  LocId: string;
  Name: string;
};

export function LastPurchaseLocationDropdown({
  onChange,
  values,
  disabled = false,
}: LastPurchaseLocationDropdownProps) {
  const [label, setLabel] = useState('Select a location');
  const { locations } = useRecoilValue(userDispensariesAtom);
  const allLocations: any[] = locations?.length ? locations : [];

  const getMultiSelectedOptionsForLastPurchaseLocations = (
    values: string,
    allLocations: Location[]
  ): DropdownMenuOption[] =>
    getMultiSelectedOptions(
      values,
      allLocations,
      parseSelectedIds,
      (option) => option.LocId,
      (option) => ({
        label: option.Name,
        id: option.LocId.toString(),
      })
    );

  const [locationDropdownSelection, setLastPurchaseLocationsDropdownSelection] = useState<DropdownMenuOption[]>([]);

  useEffect(() => {
    if (locationDropdownSelection.length !== 0 && locationDropdownSelection.length < 4) {
      const LastPurchaseLocationsLabelList = locationDropdownSelection.map((item) => item.label).join(', ');
      setLabel(LastPurchaseLocationsLabelList);
    } else {
      setLabel('Select a location');
    }
  }, [locationDropdownSelection]);

  useEffect(() => {
    // if values updates be sure to update the selection
    setLastPurchaseLocationsDropdownSelection(getMultiSelectedOptionsForLastPurchaseLocations(values, allLocations));
  }, [locations]);

  const handleLastPurchaseLocationsFilterChange = (values: DropdownMenuOption[]) => {
    setLastPurchaseLocationsDropdownSelection(values);

    const customerTypeDropdownSelection = values.map((option) => option.id);
    onChange(customerTypeDropdownSelection);
  };

  return (
    <MultiFilterSelect
      activeFilter={locationDropdownSelection}
      automationId='last-purchase-location-dropdown_multi-filter-select'
      disabled={disabled}
      label={label}
      options={allLocations.map((location: Location) => ({
        label: String(location.Name),
        id: String(location.LocId),
      }))}
      onChange={handleLastPurchaseLocationsFilterChange}
    />
  );
}
