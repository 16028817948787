import { useLDClient } from 'launchdarkly-react-client-sdk';

import type { LD } from '../constants/launch-darkly-flags';

type TypeOf<T> = T extends boolean
  ? boolean
  : T extends number
  ? number
  : T extends string
  ? string
  : T extends undefined
  ? undefined
  : T extends null
  ? null
  : T extends object
  ? T
  : unknown;

/**
 * Launch Darkly flags
 * @example Usage:
 * import { LD } from 'src/app/constants/launch-darkly-flags';
 * import { useLDFlag } from 'src/app/hooks/use-ld-flag';
 *
 * const thingRollout = useLDFlag(LD.THING_ROLLOUT, false);
 * or
 * const thingRollout = useLDFlag<boolean>(LD.THING_ROLLOUT);
 *
 * @see LD Enum - src/app/constants/launch-darkly-flags.ts
 *
 * @see Naming Conventions
 * https://dutchie.atlassian.net/wiki/spaces/EN/pages/19934543881/LaunchDarkly+Flag+Naming+Convention
 * `{charter}.{module/team}.{name}.{rollout|experiment|entitlement|operational}`
 */
export function useLDFlag<ValueType>(name: LD, defaultValue?: ValueType): TypeOf<ValueType> | undefined {
  /**
   * We intentionally don't use the `useFlags` hook from LD because it does not properly
   * handle flag usage evaluations shown in the launch darkly UI.
   */
  const ldClient = useLDClient();
  const value: ValueType = ldClient?.variation(name, defaultValue);

  return value as TypeOf<ValueType> | undefined;
}
