import React from 'react';

import { TooltipIndicatorText } from 'src/app/pages/marketing/discounts/wizard-refactor/wizard/shared/styled';
import { useDarkMode } from 'src/app/state/dark-mode';

import { Tooltip, TooltipIconContainer, TooltipIcon } from '../tooltip';

import { RebrandLabel } from './label-rebrand.styles';

export function Label(props) {
  const { children, required = false, tooltip, useSpanLabel = false, useV2Tooltip = false } = props;
  const darkMode = useDarkMode();

  if (useV2Tooltip && tooltip) {
    return (
      <RebrandLabel {...props} $darkMode={darkMode} as={useSpanLabel ? 'span' : 'label'}>
        <Tooltip placement='top' title={tooltip}>
          <TooltipIndicatorText>
            {children} {required && <span className='required-asterisk'>*</span>}
          </TooltipIndicatorText>
        </Tooltip>
      </RebrandLabel>
    );
  }
  return (
    <RebrandLabel {...props} $darkMode={darkMode} as={useSpanLabel ? 'span' : 'label'}>
      {children} {required && <span className='required-asterisk'>*</span>}
      {tooltip && (
        <Tooltip placement='top' title={tooltip}>
          <TooltipIconContainer>
            <TooltipIcon />
          </TooltipIconContainer>
        </Tooltip>
      )}
    </RebrandLabel>
  );
}
