import { useCallback, useMemo, useState } from 'react';

import { buildOutputProductDropdownFooter } from 'src/app/pages/manufacturing/assemblies/utils';

import { maxPageSize } from '..';
import { Direction, FilterOperator, LogicalOperator } from '../../types/gql-filtering-and-pagination';

import { useInfiniteProductsQuery } from './get-many';

import type { QueryProps } from '../../types/gql-filtering-and-pagination';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';
import type {
  ProductDropDownAttributesFragment,
  TblWhseProductFilterInput,
  TblWhseProductSortInput,
} from 'src/gql/graphql';

type UseCatalogProductDropdownDataParameters = {
  dynamicFilters?: NonNullable<QueryProps<TblWhseProductFilterInput, TblWhseProductSortInput>['filterModel']>;
  enabled?: boolean;
  includeFooter?: boolean;
  injectedItems?: DropdownMenuOption[];
};
export function useCatalogProductDropdownData({
  dynamicFilters = [],
  includeFooter = true,
  injectedItems = [],
  enabled = true,
}: UseCatalogProductDropdownDataParameters) {
  const [filter, setFilter] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);

  // InfiniteQueryProps
  const infiniteQueryProps: QueryProps<TblWhseProductFilterInput, TblWhseProductSortInput> = useMemo(
    () => ({
      applyUserId: true,
      filterModel: [
        ...dynamicFilters,
        {
          value: false,
          fields: ['isRetired'],
          operator: FilterOperator.EQUALS,
          logicalOperator: LogicalOperator.AND,
        },
        {
          fields: ['couponItem'],
          logicalOperator: LogicalOperator.OR,
          operator: FilterOperator.NOT_EQUAL,
          value: 'yes',
        },
        {
          value: filter,
          fields: ['whseProductsDescription'],
          operator: FilterOperator.CONTAINS,
          logicalOperator: LogicalOperator.OR,
        },
      ],
      page: 0,
      pageSize,
      sort: [{ key: 'whseProductsDescription', direction: Direction.ASC }],
    }),
    [dynamicFilters, filter, pageSize]
  );

  const mapOptions = useCallback(
    (products: ProductDropDownAttributesFragment[]) =>
      products.map(({ id, name, sku, strain, alternateDesc }) => ({
        id,
        label: name,
        ...(includeFooter
          ? { footer: buildOutputProductDropdownFooter(sku, strain?.strainName ?? '', alternateDesc ?? '') }
          : {}),
      })),
    [includeFooter]
  );

  const {
    data: productsData,
    isFetching: isFetchingInfiniteProducts,
    error: infiniteProductsError,
    fetchNextPage,
    morePagesExist,
    fetchAllProducts,
  } = useInfiniteProductsQuery(infiniteQueryProps, enabled);

  const infiniteDropDownOptions: DropdownMenuOption[] = useMemo(() => {
    if (infiniteProductsError || isFetchingInfiniteProducts || !productsData) {
      return [];
    }
    const options = productsData.filter((d) => !!d);
    return mapOptions(options);
  }, [infiniteProductsError, isFetchingInfiniteProducts, mapOptions, productsData]);

  // Map of packageId to package data, this is useful if you need to find associated data for a package
  // after the package has been selected based on the packageId
  const infiniteDropDownMap = useMemo(() => {
    const map = new Map<number, ProductDropDownAttributesFragment>();
    productsData?.forEach((productData) => {
      if (productData) {
        map.set(productData.id, productData);
      }
    });
    return map;
  }, [productsData]);

  const mergedOptions = useMemo(() => {
    if (injectedItems.length === 0) {
      return infiniteDropDownOptions;
    }
    // Deduplicate options
    const seenIds = new Set();
    const deduplicatedOptions = [...injectedItems, ...infiniteDropDownOptions].filter((option) => {
      if (seenIds.has(option.id)) {
        return false; // Skip duplicates
      }
      seenIds.add(option.id);
      return true;
    });

    return deduplicatedOptions.sort((a, b) => a.label.localeCompare(b.label));
  }, [injectedItems, infiniteDropDownOptions]);

  const onSelectAll = useCallback(async () => {
    setIsLoading(true);
    const result = await fetchAllProducts();
    const options = result.filter((d) => !!d);
    const newOptions = mapOptions(options);
    setPageSize(maxPageSize);
    setIsLoading(false);
    return newOptions;
  }, [fetchAllProducts, mapOptions]);

  const memoizedResponse = useMemo(
    () => ({
      error: infiniteProductsError,
      isFetching: isFetchingInfiniteProducts || isLoading,
      options: mergedOptions,
      morePagesExist,
      infiniteDropDownMap,
      fetchNextPage,
      setFilter,
      onSelectAll,
    }),
    [
      infiniteProductsError,
      infiniteDropDownMap,
      isFetchingInfiniteProducts,
      isLoading,
      mergedOptions,
      morePagesExist,
      fetchNextPage,
      setFilter,
      onSelectAll,
    ]
  );
  return memoizedResponse;
}
