import React, { useEffect, useState } from 'react';

import { MultiFilterSelect } from 'src/app/components/lib/multi-filter-select';
import { genderOptions } from 'src/app/utils/gender-options';

import { getMultiSelectedOptions, parseSelectedValues } from './utils';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

type GenderOptionDropdownProps = {
  disabled?: boolean;
  onChange: (value: (number | string)[]) => void;
  values: string;
};

export type GenderOption = {
  label: string;
  value: string;
};

export function GenderOptionDropdown({ onChange, values, disabled = false }: GenderOptionDropdownProps) {
  const filteredGenderOptions = genderOptions.filter((genderOption) => genderOption.value !== '');

  const getMultiSelectedOptionsForGenderOptions = (
    values: string,
    allGenderOptions: GenderOption[]
  ): DropdownMenuOption[] =>
    getMultiSelectedOptions(
      values,
      allGenderOptions,
      parseSelectedValues,
      (option) => option.value,
      (option) => ({
        label: option.label,
        id: option.value.toString(),
      })
    );

  const [label, setLabel] = useState('Select a gender');
  const [genderOptionDropdownSelection, setGenderOptionsDropdownSelection] = useState<DropdownMenuOption[]>(
    getMultiSelectedOptionsForGenderOptions(values, filteredGenderOptions)
  );

  useEffect(() => {
    if (genderOptionDropdownSelection.length !== 0 && genderOptionDropdownSelection.length < 4) {
      const genderOptionsLabelList = genderOptionDropdownSelection.map((item) => item.label).join(', ');
      setLabel(genderOptionsLabelList);
    } else {
      setLabel('Select a gender');
    }
  }, [genderOptionDropdownSelection]);

  const handlegenderOptionsFilterChange = (values: DropdownMenuOption[]) => {
    setGenderOptionsDropdownSelection(values);

    const customerTypeDropdownSelection = values.map((option) => option.id);
    onChange(customerTypeDropdownSelection);
  };

  return (
    <MultiFilterSelect
      activeFilter={genderOptionDropdownSelection}
      automationId='gender-options-dropdown_multi-filter-select'
      disabled={disabled}
      label={label}
      options={filteredGenderOptions.map((genderOptions) => ({
        label: String(genderOptions.label),
        id: String(genderOptions.value),
      }))}
      onChange={handlegenderOptionsFilterChange}
    />
  );
}
