import React from 'react';

import i18next from 'i18next';
import { debounce } from 'lodash';

import { ControlBar, ControlGroup, ControlSearchBox } from 'src/app/components/lib/control-bar';
import { Modal } from 'src/app/components/lib/modal';
import { ModalDataGrid, useGridApiRef } from 'src/app/components/lib/table';
import { ServerPaginatedTables } from 'src/app/constants/table-names-paginated';
import { useServerTableControlsWithModal } from 'src/app/hooks/use-server-table-controls-in-modal';
import { useBatchesWithLabsQuery } from 'src/app/queries/graphql/batches/get-many';
import { Direction, FilterOperator, LogicalOperator } from 'src/app/queries/types/gql-filtering-and-pagination';
import { formatDate } from 'src/app/utils/formatters';

import type { GridColDef } from 'src/app/components/lib/table';
import type { BatchesWithLabsFragment, TblBatchFilterInput, TblBatchSortInput } from 'src/gql/graphql';

type SelectBatchModalProps = {
  onClose: (newBatch?: BatchesWithLabsFragment) => void;
};

export function SelectBatchModal({ onClose }: SelectBatchModalProps) {
  const { getFilterValue, setFilterValue, tableProps, queryProps } = useServerTableControlsWithModal<
    TblBatchFilterInput,
    TblBatchSortInput
  >({
    initialSort: [{ key: 'lastModifiedDateUtc', direction: Direction.DESC }],
    filterDefinitions: [
      {
        filterKey: 'batch-search',
        fields: ['batchLotNumber', 'batchStrainNavigation.strainName'],
        logicalOperator: LogicalOperator.OR,
        operator: FilterOperator.CONTAINS,
        initialValue: '',
      },
    ],
    tableName: ServerPaginatedTables.BatchesWithLabsList,
  });

  const { data, isLoading } = useBatchesWithLabsQuery(queryProps);
  const totalCount = data?.totalCount ?? 0;
  const dataGridRows = data?.items ?? [];
  const gridApiRef = useGridApiRef();

  return (
    <Modal
      Content={
        <>
          <ControlBar>
            <ControlGroup grow>
              <ControlSearchBox
                initialValue={String(getFilterValue('batch-search'))}
                placeholder='Search by strain or batch...'
                onChange={debounce((input) => setFilterValue('batch-search', input), 500)}
              />
            </ControlGroup>
          </ControlBar>
          <ModalDataGrid
            apiRef={gridApiRef}
            columns={columns}
            getRowId={(row) => String(row.id)}
            loading={isLoading}
            minHeight='120px'
            minWidth='auto'
            rowCount={totalCount}
            rows={dataGridRows}
            onRowClick={({ row }) => onClose(row as BatchesWithLabsFragment)}
            {...tableProps}
          />
        </>
      }
      open
      title={i18next.t('Select a batch')}
      width='720px'
      onBack={onClose}
      onClose={onClose}
    />
  );
}

const columns: GridColDef[] = [
  {
    field: 'batchStrainNavigation.strainName',
    valueGetter: ({ row }) => row.batchStrainNavigation?.strainName ?? '',
    headerName: i18next.t('Strain name'),
  },
  {
    field: 'batchLotNumber',
    headerName: i18next.t('Batch name'),
  },
  {
    field: 'harvestCompletedOn',
    valueGetter: ({ row }) => (row.harvestCompletedOn ? formatDate(row.harvestCompletedOn, false) : ''),
    headerName: i18next.t('Harvest date'),
  },
  {
    field: 'THC',
    headerName: i18next.t('THC'),
    sortable: false,
  },
  {
    field: 'THCa',
    headerName: i18next.t('THCA'),
    sortable: false,
  },
  {
    field: 'CBD',
    headerName: i18next.t('CBD'),
    sortable: false,
  },
  {
    field: 'CBDa',
    headerName: i18next.t('CBDA'),
    sortable: false,
  },
];
