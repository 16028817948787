import { useMutation } from '@tanstack/react-query';

import { postData } from '..';

const CREATE_LOT_NUMBER = 'api/cultivation/CreateLotNo';

type CreateBatchPayload = {
  BatchName: string;
  StrainId: number;
};

export type CreateBatchResponse = {
  BatchId: number;
  BatchNo: string;
}[];

export function useCreateBatch() {
  return useMutation({
    mutationKey: ['create-lot-number'],
    mutationFn: async (payload: CreateBatchPayload) => {
      const [newBatch] = await postData<CreateBatchPayload, CreateBatchResponse>({
        payload,
        endpoint: CREATE_LOT_NUMBER,
      });
      return newBatch;
    },
  });
}
