import React, { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { LinkButton } from 'src/app/components/lib/button';
import { MultiFilterSelect } from 'src/app/components/lib/multi-filter-select';
import { customerTypeQueryKey, useGetAllCustomerTypes } from 'src/app/queries/customers/get-all-customer-types';

import { getMultiSelectedOptions, parseSelectedIds } from './utils';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

type CustomerTypeDropdownProps = {
  automationId?: string;
  disabled?: boolean;
  onChange: (value: (number | string)[]) => void;
  values: string;
};

export type CustomerType = {
  CustomerType: string;
  CustomerTypeId: number;
};

export function CustomerTypeDropdown(props: CustomerTypeDropdownProps) {
  const { onChange, values, disabled = false, automationId } = props;
  const queryClient = useQueryClient();
  const { data: allCustomerTypes = [], isLoading } = useGetAllCustomerTypes();

  const [label, setLabel] = useState('Select a customer type');
  const [customerTypeDropdownSelection, setCustomerTypeDropdownSelection] = useState<DropdownMenuOption[]>([]);

  const getMultiSelectedOptionsForCustomerTypes = (
    values: string,
    allCustomerTypeOptions: CustomerType[]
  ): DropdownMenuOption[] =>
    getMultiSelectedOptions(
      values,
      allCustomerTypeOptions,
      parseSelectedIds,
      (option) => option.CustomerTypeId,
      (option) => ({
        label: option.CustomerType,
        id: option.CustomerTypeId.toString(),
      })
    );

  useEffect(() => {
    if (customerTypeDropdownSelection.length !== 0 && customerTypeDropdownSelection.length < 4) {
      const customerTypeLabelList = customerTypeDropdownSelection.map((item) => item.label).join(', ');
      setLabel(customerTypeLabelList);
    } else {
      setLabel('Select a customer type');
    }
  }, [customerTypeDropdownSelection]);

  useEffect(() => {
    // if values updates be sure to update the selection
    if (allCustomerTypes.length) {
      setCustomerTypeDropdownSelection(getMultiSelectedOptionsForCustomerTypes(values, allCustomerTypes));
    }
  }, [allCustomerTypes]);

  async function loadCustomerTypes() {
    void queryClient.invalidateQueries({ queryKey: customerTypeQueryKey });
  }

  async function handleRefresh() {
    await loadCustomerTypes();
  }

  const handleCustomerTypeFilterChange = (values: DropdownMenuOption[]) => {
    setCustomerTypeDropdownSelection(values);

    const customerTypeDropdownSelection = values.map((option) => option.id);
    onChange(customerTypeDropdownSelection);
  };

  return (
    <MultiFilterSelect
      activeFilter={customerTypeDropdownSelection}
      automationId={automationId}
      disabled={disabled}
      footerContent={
        <LinkButton
          automationId='customer-type-dropdown_multi-filter-select_refresh-button'
          buttonSize='small'
          key='category-refresh'
          label='Refresh'
          loading={isLoading}
          onClick={handleRefresh}
        />
      }
      label={label}
      options={allCustomerTypes.map((customerType) => ({
        label: String(customerType.CustomerType),
        id: String(customerType.CustomerTypeId),
      }))}
      onChange={handleCustomerTypeFilterChange}
    />
  );
}
