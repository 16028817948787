import React, { useState } from 'react';

import { useRecoilValue } from 'recoil';

import InventoryStore from 'src/app_deprecated/stores/InventoryStore';

import { LinkButton } from 'src/app/components/lib/button';
import { DropdownSingle } from 'src/app/components/lib/dropdown';
import { inventoryAtom } from 'src/app/state/inventory';

type VendorsDropdownProps = {
  automationId?: string;
  disabled?: boolean;
  label?: string;
  labelPlacement?: 'start' | 'top';
  onChange: (value: number | string) => void;
  required?: boolean;
  tooltip?: string;
  value: number | string;
};

export function VendorsDropdown(props: VendorsDropdownProps) {
  const {
    onChange,
    value,
    disabled = false,
    required,
    automationId,
    label = 'Vendors:',
    labelPlacement = 'start',
    tooltip,
  } = props;

  const [loading, setLoading] = useState(false);
  const { vendors } = useRecoilValue(inventoryAtom);
  const options = vendors?.map((vendor) => ({
    label: vendor?.VendorName,
    id: vendor?.VendorId,
  }));

  async function handleRefresh() {
    setLoading(true);
    await InventoryStore.refreshVendors(true);
    setLoading(false);
  }

  return (
    <DropdownSingle
      automationId={automationId}
      disabled={disabled}
      footerContent={
        <LinkButton buttonSize='small' key={automationId} label='Refresh' loading={loading} onClick={handleRefresh} />
      }
      helpText='Select a vendor'
      inputId='select-vendor'
      label={label}
      labelPlacement={labelPlacement}
      options={options}
      required={required}
      setValue={(newVal) => onChange(newVal.id)}
      tooltip={tooltip}
      value={value}
    />
  );
}
