import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

import UserStore from 'src/app_deprecated/stores/UserStore';

import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import usePrevious from 'src/app/hooks/use-previous';
import { useDeterministicDocumentData } from 'src/app/pages/products/inventory-v2/use-deterministic-document-data';
import {
  fetchMany,
  paginatedExportCommonQueryProps,
  paginatedExportNumberOfRequests,
  paginatedExportPageProps,
  useInfiniteGQLQuery,
} from 'src/app/queries/graphql';
import { filterDocumentNode } from 'src/app/utils/dynamic-gql-builder';
import {
  type PackageFilterInput,
  type PackageSortInput,
  type InventoryPackageAttributesV2Fragment,
  GetPackagesForDropdownDocument,
} from 'src/gql/graphql';

import { extendInventoryPackage } from './extend';
import { useConditionalFieldsForInventoryRequests } from './hooks/use-conditional-fields';
import { inventoryPackagesKeys } from './query-key-factory';

import type { InventoryPackage } from './extend';
import type { UseQueryOptionsForUseQueries } from 'src/app/queries/graphql';
import type { AbridgedFilterInput, QueryProps } from 'src/app/queries/types/gql-filtering-and-pagination';

type InventoryPackagesQueryData = { items: InventoryPackage[]; totalCount?: number | undefined };
type PackageQueryProps = QueryProps<AbridgedFilterInput<PackageFilterInput>, PackageSortInput>;
type InventoryPackagesQueryProps = {
  disabled?: boolean;
  enabled?: boolean;
  onDataRequest?: (props: {
    data: InventoryPackagesQueryData;
    previousData: InventoryPackagesQueryData | undefined;
  }) => void;
  queryProps: PackageQueryProps;
};

export type InventoryOnDataRequestProps = Parameters<NonNullable<InventoryPackagesQueryProps['onDataRequest']>>[number];

export function useInventoryPackagesQuery({
  enabled,
  queryProps,
  onDataRequest,
  disabled,
}: InventoryPackagesQueryProps) {
  const queryClient = useQueryClient();
  const userApiPayload = UserStore.getApiData();
  const { conditionalFields } = useConditionalFieldsForInventoryRequests();
  const flags = useFlags();
  const { ApplicablePackagesDocument } = useDeterministicDocumentData();
  const { fieldsToDisable = [] } = flags['pos.backoffice.inventory-columns.operational'] || {};
  const filteredDocument = filterDocumentNode(ApplicablePackagesDocument, fieldsToDisable);
  const hasConditionalFields = conditionalFields !== undefined;
  const useEnabledProp = enabled !== undefined;

  const gqlErrorMitigationFlagEnabled = useLDFlag(LD.GQL_ERROR_MITIGATION);
  const isRefetchInventoryOnLocChangeLDFlagEnabled = useLDFlag(LD.REFETCH_INVENTORY_ON_LOC_CHANGE, false);

  let hasLSP = true;
  if (gqlErrorMitigationFlagEnabled) {
    hasLSP = Boolean(userApiPayload.LspId);
  }

  const isEnabled = useEnabledProp ? enabled : hasConditionalFields && !disabled && hasLSP;
  const previousQueryProps = usePrevious(queryProps);

  if (isRefetchInventoryOnLocChangeLDFlagEnabled) {
    queryProps.locId = userApiPayload.LocId;
  }
  return useQuery({
    queryKey: [...inventoryPackagesKeys.many(queryProps), conditionalFields],
    queryFn: async () => {
      const inventoryPackageData = await fetchMany(filteredDocument, {
        ...queryProps,
        applyUserId: true,
        conditionalFields,
      });
      const previousData = queryClient.getQueryData<InventoryPackagesQueryData>([
        ...inventoryPackagesKeys.many(previousQueryProps),
        conditionalFields,
      ]);

      const items = inventoryPackageData?.items?.filter(Boolean) as InventoryPackageAttributesV2Fragment[];
      const extendedItems = items?.map((item) => extendInventoryPackage(item));

      extendedItems?.forEach((item) => {
        if (item?.id) {
          queryClient.setQueryData(inventoryPackagesKeys.one(item.id), item);
        }
      });

      const result = {
        ...inventoryPackageData,
        items: extendedItems,
      };
      onDataRequest?.({ data: result, previousData });
      return result;
    },
    enabled: isEnabled,
    keepPreviousData: true,
  });
}

type UseInventoryPackageesQueriesProps = {
  queryProps: QueryProps<AbridgedFilterInput<PackageFilterInput>, PackageSortInput>;
  totalCount: number;
};

export function useInventoryPackagesQueries({ queryProps, totalCount }: UseInventoryPackageesQueriesProps) {
  const queries: UseQueryOptionsForUseQueries[] = [];
  const numberOfRequests = paginatedExportNumberOfRequests(totalCount);
  const { conditionalFields } = useConditionalFieldsForInventoryRequests();
  const { ApplicablePackagesDocument } = useDeterministicDocumentData();

  for (let i = 0; i < numberOfRequests; i += 1) {
    const alteredQueryProps = {
      ...queryProps,
      ...paginatedExportPageProps(i),
      applyUserId: true,
      conditionalFields,
    };
    queries.push({
      queryKey: inventoryPackagesKeys.many(alteredQueryProps),
      queryFn: () => fetchMany(ApplicablePackagesDocument, alteredQueryProps),
      ...paginatedExportCommonQueryProps,
    });
  }

  return useQueries({ queries });
}

export function useInfinitePackagesQuery(queryProps: PackageQueryProps, enabled = true) {
  const { fetchAllItems, ...result } = useInfiniteGQLQuery({
    enabled,
    queryKey: inventoryPackagesKeys.many(queryProps, 'infinite'),
    queryProps,
    queryDocument: GetPackagesForDropdownDocument,
  });
  return {
    ...result,
    fetchAllPackages: async () => {
      const res = await fetchAllItems();
      return res.pages.flatMap(({ items }) => items).filter((item) => !!item);
    },
  };
}
