import styled from 'styled-components';

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--sizes-50);
  align-self: stretch;
`;

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--sizes-30);
  border: 1px solid var(--color-gray-20);
  background: #fff;
`;

export const AccordionHeader = styled.div`
  display: flex;
  padding: var(--sizes-40);
  align-items: center;
  gap: var(--sizes-30);
  justify-content: space-between;
  align-self: stretch;
  font: var(--font-large-16pt-semibold);
`;

export const AccordionHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  color: #00000;
  text-align: center;
  line-height: var(--sizes-60);
`;

export const AccordionHeaderRight = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-grayscale-black);
  text-align: center;
  height: var(--sizes-70);
  font-feature-settings: 'calt' off;
  line-height: var(--sizes-60);

  & .MuiTextField-root {
    width: 200px;
    padding-right: var(--sizes-20);
    display: flex !important;
    height: var(--sizes-70);
    align-items: center;
  }
`;

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  border-top: 1px solid var(--color-gray-20);
  gap: var(--sizes-40);
  display: flex;
  padding: ${({ isOpen }) => (isOpen ? '20px' : '0')};
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  transition: height 1s ease, opacity 0.3s ease;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  overflow: hidden; // Hide overflow when closed
`;
