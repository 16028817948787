import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postData } from 'src/app/queries';
import { assemblyKeys } from 'src/app/queries/graphql/assemblies/query-key-factory';

import type { CompleteAssemblyRequest, FailedAssemblyResponse } from './types';

export const endpoint = 'api/manufacturing/assembly/complete';

export type CompleteAssemblyResponse = {
  Result: boolean;
};

export const useCompleteAssembly = () => {
  const queryClient = useQueryClient();
  return useMutation<CompleteAssemblyResponse, FailedAssemblyResponse, CompleteAssemblyRequest>({
    mutationKey: ['complete-assembly'],
    mutationFn: (payload) => postData({ payload, endpoint }),
    onSuccess: () => void queryClient.resetQueries({ queryKey: assemblyKeys.all }),
  });
};
