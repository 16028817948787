import styled, { css } from 'styled-components';

export const ProductListGridWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

export const RequirementCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-20);
  border-radius: var(--sizes-30);
  width: 100%;
`;

export const RequirementCardRow = styled.div<{ $spaceBetween?: boolean }>`
  color: var(--color-gray-90);
  font: var(--font-small-13pt-normal);
  display: flex;
  gap: 16px;
  align-items: center;
  ${({ $spaceBetween }) =>
    $spaceBetween &&
    css`
      justify-content: space-between;
    `}
  padding: var(--sizes-30) var(--sizes-40);
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-gray-20);
  }
  min-height: 56px;
`;

export const RequirementCardTitle = styled.h4`
  font: var(--font-regular-14pt-semibold);
  color: var(--color-grayscale-black);
  margin: 0;
`;

export const InputWithLabelWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const ProductListWrapper = styled.div`
  padding: var(--sizes-60) var(--sizes-70);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--sizes-40);
`;

export const ProductListHeader = styled.div``;

export const ProductListHeading = styled.h3`
  font: var(--font-large-16pt-semibold);
`;

export const ProductListDescription = styled.p`
  color: var(--color-gray-80);
  font: var(--font-extra-small-12pt-normal);
`;

export const FilterCardWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const SummaryFieldTitle = styled.div`
  font: var(--font-small-13pt-semibold);
  color: var(--color-grayscale-black);
`;

export const SummaryFieldValue = styled.div`
  font: var(--font-small-13pt-normal);
  color: var(--color-gray-90);
`;

export const TooltipIndicatorText = styled.span`
  border-bottom: 1px dashed var(--color-gray-90);
`;

export const TooltipContents = styled.p`
  width: 176px;
  max-height: 300px;
  overflow: auto;
`;
