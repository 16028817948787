import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { PROCESSING_STEPS_MAX_LENGTH_MESSAGE, PROCESSING_STEPS_REQUIRED_MESSAGE } from '../bill-of-materials/constants';

import {
  ATTRIBUTE_REQUIRED_MESSAGE,
  CATEGORY_NAME_REQUIRED_MESSAGE,
  DESCRIPTION_MAX_LENGTH_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  DOCUMENT_NAME_REQUIRED_MESSAGE,
  FILE_NAME_REQUIRED_MESSAGE,
  INVALID_URL_FORMAT,
  NAME_REQUIRED_MESSAGE,
  URL_REQUIRED_MESSAGE,
} from './constants';

import type { SchemaContext } from '../bill-of-materials/detail/use-form-state';

export const oneOfRequiredString = <T>(key: string, value: T, message: string) =>
  yup
    .string()
    .when(key, {
      is: value,
      then: yup.string().required(message),
    })
    .default('');

export const requiredNullableNumber = (key: string, message: string) =>
  yup
    .number()
    .nullable()
    .default(null)
    .test(key, message, (v) => v !== null);

export const requireWhenNotSkipped = <T>(fieldSchema: yup.AnySchema<T>) =>
  fieldSchema.when('skip', {
    is: false,
    then: fieldSchema,
    otherwise: () => {
      if (fieldSchema instanceof yup.ArraySchema) {
        const defaultValue = fieldSchema.getDefault();
        return yup.array().nullable().default(defaultValue);
      }
      if (fieldSchema instanceof yup.ObjectSchema) {
        const defaultValue = fieldSchema.getDefault();
        return yup.object().nullable().default(defaultValue);
      }
      if (fieldSchema instanceof yup.StringSchema) {
        const defaultValue = fieldSchema.getDefault();
        return yup.string().nullable().default(defaultValue);
      }
      if (fieldSchema instanceof yup.NumberSchema) {
        const defaultValue = fieldSchema.getDefault();
        return yup.number().nullable().default(defaultValue);
      }
      if (fieldSchema instanceof yup.BooleanSchema) {
        const defaultValue = fieldSchema.getDefault();
        return yup.boolean().nullable().default(defaultValue);
      }
      if (fieldSchema instanceof yup.DateSchema) {
        const defaultValue = fieldSchema.getDefault();
        return yup.date().nullable().default(defaultValue);
      }
      return yup.mixed().nullable().default(null);
    },
  });

export const documentSchema = yup.object({
  id: yup.number().nullable().default(null),
  uuid: yup.string().required().default(uuidv4()),
  documentName: yup.string().required(DOCUMENT_NAME_REQUIRED_MESSAGE).default(''),
  documentTypeId: yup.number().required().default(1),
  fileName: oneOfRequiredString('documentTypeId', 1, FILE_NAME_REQUIRED_MESSAGE).nullable(),
  url: oneOfRequiredString('documentTypeId', 2, URL_REQUIRED_MESSAGE).url(INVALID_URL_FORMAT).nullable(),
  fileUrl: yup.string().nullable().default(null),
  userFileName: yup.string().nullable().default(null),
});

const processingJobAttributeSchema = yup
  .array()
  .of(yup.string())
  .when(['isProcessingJob', 'categoryName', '$categories'], {
    is: (isProcessingJob: boolean, categoryName: string, categories: SchemaContext['categories']) => {
      const selectedCategory = categories?.find((cat) => cat.Name === categoryName);
      return isProcessingJob && selectedCategory?.RequiresProcessingJobAttributes;
    },
    then: yup.array().of(yup.string().required()).min(1, ATTRIBUTE_REQUIRED_MESSAGE),
  })
  .default([]);

export const processingJobTypeTemplateSchema = yup.object({
  id: yup.number().nullable().default(null),
  isProcessingJob: yup.boolean().required().default(false),
  name: oneOfRequiredString('isProcessingJob', true, NAME_REQUIRED_MESSAGE),
  attributes: processingJobAttributeSchema,
  categoryName: oneOfRequiredString('isProcessingJob', true, CATEGORY_NAME_REQUIRED_MESSAGE),
  description: oneOfRequiredString('isProcessingJob', true, DESCRIPTION_REQUIRED_MESSAGE).max(
    1000,
    DESCRIPTION_MAX_LENGTH_MESSAGE
  ),
  processingSteps: oneOfRequiredString('isProcessingJob', true, PROCESSING_STEPS_REQUIRED_MESSAGE).max(
    2500,
    PROCESSING_STEPS_MAX_LENGTH_MESSAGE
  ),
});

export type DocumentSchema = yup.InferType<typeof documentSchema>;
export type ProcessingJobTypeTemplateSchema = yup.InferType<typeof processingJobTypeTemplateSchema>;
export type AttributesSchema = yup.InferType<typeof processingJobAttributeSchema>;
