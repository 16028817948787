import React, { useEffect, useMemo, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { HardwareService, KeyboardScannerService } from '@dutchie/capacitor-hardware';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled, { ThemeProvider } from 'styled-components';
import Cookies from 'universal-cookie';

import AppActions from 'src/app_deprecated/actions/AppActions';

import { Notifications } from 'src/app/components/lib/notification';
import Notification from 'src/app/components/notification';
import Reset from 'src/app/components/reset';
import { IS_PRODUCTION_APP } from 'src/app/constants/environment';
import { environmentAtom } from 'src/app/state/environment';

import { Auth } from './components/auth';
import { defaultBreakpoints } from './components/lib/global-styles/breakpoints';
import { Tokens } from './components/lib/global-styles/tokens';
import { Universals } from './components/lib/global-styles/universals';
import { BohThemeProvider } from './components/lib/theme';
import { Modals } from './components/modals';
import PermissionDeniedModal from './components/modals/modals/permission-denied-modal';
import { LD } from './constants/launch-darkly-flags';
import { REDIRECT_PARAM } from './constants/router-constants';
import { useLDFlag } from './hooks/use-ld-flag';
import { useViewOrgManagementEnabled } from './hooks/use-view-org-management';
import ErrorBoundary from './layout/error/error-boundary';
import { Header } from './layout/header';
import { useHeightOffset } from './layout/header/banners/use-height-offset';
import { HeaderV2 } from './layout/header/headerv2';
import Main from './layout/main/router';
import { FullPageMigrationTakeover } from './layout/server-migration-ui/full-page-migration-takeover';
import { ServerMigrationPromptModal } from './layout/server-migration-ui/server-migration-prompt-modal';
import { Sidebar } from './layout/sidebar';
import { useDarkMode } from './state/dark-mode';
import { userAtom } from './state/user';
import { userDispensariesAtom } from './state/user-dispensaries';
import { userPermissionsAtom } from './state/user-permissions';
import VersionChecker from './version-checker';

LicenseInfo.setLicenseKey(
  'cc01be0642680a608ea29a3b3d69c8c3Tz04NDI4MyxFPTE3Mzk1NDg3MzMwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
);

export default function App() {
  const darkMode = useDarkMode();
  const createBrowserRouterEnabled = useLDFlag(LD.CREATE_BROWSER_ROUTER_ROLLOUT, false);

  const search = new URLSearchParams(window.location.search);
  const token = search.get('token');
  const { companies, selectedLocation, selectedCompany } = useRecoilValue(userDispensariesAtom);
  const { perms } = useRecoilValue(userPermissionsAtom);
  const user = useRecoilValue(userAtom);
  const userHasLocations = selectedLocation?.HasPermission;
  const [searchParams] = useSearchParams();
  const environment = useRecoilValue(environmentAtom);
  const [showPermissionDeniedModal, setShowPermissionDeniedModal] = useState(!!searchParams.get(REDIRECT_PARAM));
  const heightOffset = useHeightOffset();
  const [cookie, setCookie] = useState('assumeWeHaveACookie');

  const flags = useFlags();
  const useNewNotifications = flags['pos.bo.use-new-notifications.rollout'];
  const useNewVersionAvailableNotification = flags['pos.backoffice.new-version-available-notification.rollout'];

  const callSSOLoginEnabledWhenLogout = flags['pos.backoffice.call-sso-login-enabled-when-logout.rollout'];

  // *** Migration values available only via fully-identified LD after a successful login (i.e., before data has actually been migrated)
  const serverMigrationConfig = flags['pos.platform.database-migration-configs.operational'];
  const showMigrationTakeoverPageBasedOnLD = serverMigrationConfig?.shouldShowBlockingUI;
  const shouldShowServerMigrationPromptModal =
    !showMigrationTakeoverPageBasedOnLD && !!serverMigrationConfig?.backofficeURL;
  const [showingMigrationPromptModal, setShowingMigrationPromptModal] = useState<boolean | null>(null);

  // *** Migration values returned from our login endpoint (i.e., after data has been migrated and the user is deactivated)
  const [showMigrationTakeoverPageBasedOnLogin, setShowMigrationTakeoverPageBasedOnLogin] = useState(false);
  const [targetMigrationServerFromLogin, setTargetMigrationServerFromLogin] = useState('');

  // *** Computed migration values dependent on availability of LD vs. login values
  const shouldShowFullPageMigrationTakeover =
    showMigrationTakeoverPageBasedOnLD || showMigrationTakeoverPageBasedOnLogin;
  const targetMigrationServer = targetMigrationServerFromLogin || serverMigrationConfig?.backofficeURL;

  // *** visible only to superusers. Allows dismissal of full page block (e.g. if you need to access a different LSP when your most recently used LSP has been shut off)
  const [fullpageTakeoverEscapeHatch, setFullpageTakeoverEscapeHatch] = useState(false);

  const canViewOrgManagement = useViewOrgManagementEnabled();

  useEffect(() => {
    const cookies = new Cookies();
    const activeCookie = cookies.get('LeafLogixSessionInfo', { path: '/' });
    if (activeCookie) {
      AppActions.boot(activeCookie);
      setCookie(activeCookie);
    } else {
      setCookie('');
    }
  }, [user.Id]);

  useEffect(() => {
    if (shouldShowServerMigrationPromptModal && showingMigrationPromptModal === null) {
      setShowingMigrationPromptModal(true);
    }
  }, [shouldShowServerMigrationPromptModal, showingMigrationPromptModal]);

  // This is a little one-off. The scope of the default breakpoints doesn't work here. Using defaults for now
  const desktopView = useBreakpoint(up('lg')); // ≥992px

  useEffect(() => {
    if (callSSOLoginEnabledWhenLogout) {
      if (!user && !token) {
        AppActions.getSSOLoginEnabled();
      }
    }
  }, [user, token, callSSOLoginEnabledWhenLogout]);

  useEffect(() => {
    if (!callSSOLoginEnabledWhenLogout) {
      AppActions.getSSOLoginEnabled();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowPermissionDeniedModal(!!searchParams.get(REDIRECT_PARAM));
  }, [searchParams]);

  const appReady = useMemo(() => Object.keys(perms).length > 0 && companies.length > 0, [companies, perms]);

  useEffect(() => {
    // notify datadag who the user is
    if (IS_PRODUCTION_APP && appReady && user && selectedCompany && selectedLocation && environment) {
      datadogRum.setUser({
        id: String(user?.Id),
        name: user?.FullName,
        lspId: selectedCompany?.LspId,
        locId: selectedLocation?.LocId,
        region: environment?.Region,
      });
    }
  }, [user, appReady, selectedCompany, selectedLocation, environment]);

  const rollBackScannerRegistration = flags['pos.backoffice.register_scanner.rollback'];

  useEffect(() => {
    if (rollBackScannerRegistration) {
      return () => undefined;
    }
    const keyboardScannerService = new KeyboardScannerService();
    const registerScanners = async () => {
      await HardwareService.scanner.register(keyboardScannerService);
    };
    void registerScanners();
    return HardwareService.scanner.unregister(keyboardScannerService);
  }, [rollBackScannerRegistration]);

  const handleShowMigrationTakeoverLogin = ({ backofficeURL }: { backofficeURL?: string }) => {
    setShowMigrationTakeoverPageBasedOnLogin(true);
    setTargetMigrationServerFromLogin(backofficeURL ?? '');
  };

  return (
    <BohThemeProvider>
      <ThemeProvider theme={defaultBreakpoints}>
        <Universals $darkMode={darkMode} />
        <Tokens />
        {shouldShowFullPageMigrationTakeover && !fullpageTakeoverEscapeHatch && (
          <>
            <FullPageMigrationTakeover
              backofficeURL={targetMigrationServer}
              setEscapeHatch={() => setFullpageTakeoverEscapeHatch(true)}
              useUpdatedMessaging={serverMigrationConfig?.useUpdatedMessaging ?? false}
            />
            {/* Just need this here so we can pop toasts (e.g. for the "Copy URL" button) */}
            <Notifications />
          </>
        )}
        {(!shouldShowFullPageMigrationTakeover || fullpageTakeoverEscapeHatch) && (
          <AppContainer data-is-new-ia id='app-container'>
            {!user && token && <Reset token={token} />}
            {user && !canViewOrgManagement && <Header />}
            {user && canViewOrgManagement && <HeaderV2 />}
            {appReady && (
              <SidebarContentContainer $heightOffset={heightOffset}>
                {desktopView && <Sidebar />}

                {user && userHasLocations && (
                  <ErrorBoundary>{createBrowserRouterEnabled ? <Outlet /> : <Main />}</ErrorBoundary>
                )}
              </SidebarContentContainer>
            )}
            {!user && !token && !cookie && <Auth showFullPageMigrationTakeover={handleShowMigrationTakeoverLogin} />}
            <PermissionDeniedModal
              open={showPermissionDeniedModal}
              onClose={() => setShowPermissionDeniedModal(false)}
            />
            <ServerMigrationPromptModal
              backofficeURL={serverMigrationConfig?.backofficeURL}
              dayBeforeDeadline={serverMigrationConfig?.dayBeforeDeadline}
              deadlineDateLabel={serverMigrationConfig?.deadlineDateLabel}
              open={showingMigrationPromptModal ?? false}
              useUpdatedMessaging={serverMigrationConfig?.useUpdatedMessaging ?? false}
              onClose={() => setShowingMigrationPromptModal(false)}
            />
            {useNewNotifications ? <Notifications /> : <Notification />}
            {!useNewVersionAvailableNotification && <VersionChecker />}
            <Modals />
          </AppContainer>
        )}
      </ThemeProvider>
    </BohThemeProvider>
  );
}

const AppContainer = styled.div`
  margin-left: auto;
  height: 100%;
  overflow-y: auto;

  .rebrand_container {
    width: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const SidebarContentContainer = styled.div<{ $heightOffset: string }>`
  display: flex;
  width: 100%;
  height: calc(100vh - ${({ $heightOffset }) => $heightOffset});
`;
