import React, { useState, useEffect } from 'react';

import i18next from 'i18next';

import { endPoints } from 'src/app_deprecated/constants/InventoryConstants';
import UserStore from 'src/app_deprecated/stores/UserStore';
import AjaxPromises from 'src/app_deprecated/utils/AjaxPromises';

import { LinkButton } from 'src/app/components/lib/button';
import { Input, InputAdornment } from 'src/app/components/lib/input';

import type { InputProps } from 'src/app/components/lib/input';

export type BatchIdInputProps = Omit<InputProps, 'onChange'> & {
  automationId?: string;
  gridColumns?: number;
  onChange: (batchId: string) => void;
  onFocus?: (e) => void;
  product?: any;
  systemGenerated?: boolean;
};

export function BatchIdInput(props: BatchIdInputProps) {
  const [generating, setGenerating] = useState(false);
  const {
    automationId,
    product,
    value,
    label,
    labelPlacement,
    required,
    disabled,
    gridColumns,
    onChange,
    onBlur,
    onFocus,
    systemGenerated,
  } = props;

  useEffect(() => {
    if (generating) {
      const handleGenerate = async () => {
        const data = UserStore.getApiData({
          StrainID: product?.StrainId || null,
          ProductId: product?.ProductId || null,
        });
        try {
          const resp = await AjaxPromises.POST(endPoints.GENERATE_BATCH_ID, data);
          if (resp.Result) {
            const batchNumber = resp.Data[0].SerialNo;
            onChange(batchNumber);
          }
        } finally {
          setGenerating(false);
        }
      };
      void handleGenerate();
    }
  }, [generating]);

  const generateSerialNumber = async () => {
    if (!value) {
      setGenerating(true);
    }
  };

  const setSerialNumber = (event) => {
    const serialNumber = event.target.value;
    onChange(serialNumber);
  };

  return (
    <Input
      automationId={automationId}
      className='form-control form-control--batch-id'
      data-testid='batch-id_input_text'
      disabled={disabled || systemGenerated}
      endAdornment={
        !disabled && (
          <InputAdornment position='end'>
            <LinkButton
              data-testid='batch-id_button_generate'
              disabled={!!value || systemGenerated}
              label={i18next.t('Generate')}
              loading={generating}
              onClick={generateSerialNumber}
            />
          </InputAdornment>
        )
      }
      gridColumns={gridColumns}
      label={label}
      labelPlacement={labelPlacement}
      required={required}
      type='text'
      value={systemGenerated ? 'System generated' : value}
      onBlur={(e: any) => {
        if (onBlur) {
          onBlur(e.target.value);
        }
      }}
      onChange={setSerialNumber}
      onFocus={(e: any) => {
        if (onFocus) {
          onFocus(e.target.value);
        }
      }}
    />
  );
}
