import React, { useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { endOfToday, isAfter, isBefore, startOfYear, subYears } from 'date-fns';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { Button, white } from 'src/app/components/lib/button';
import {
  DatePickerRange,
  MILLISECONDS_IN_A_WEEK,
} from 'src/app/components/lib/date-picker/date-picker-range/date-picker-range';
import { DropdownMultiple } from 'src/app/components/lib/dropdown';
import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import { useGetSegmentReportQuery, segmentsReportQueryKey } from 'src/app/queries/segment/get-segment-report';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

import { ReportsSnapshotSummary } from './reports-snapshot-summary';
import { SegmentDemographics } from './segment-demographics';
import { filterLocation, maskLocationName } from './utils/utils';

import type { DateRange } from '@mui/x-date-pickers-pro';
import type { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internals/models';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';
import type { ICustomerTypes } from 'src/app/pages/customers/customers/segments/details/reports/utils/types';

export function SegmentReport() {
  // hooks
  const { segmentId = '' } = useParams<{ segmentId: string }>();
  const {
    locations,
    selectedLocation: defaultLocation,
    locProfile,
    customerTypes,
  } = useRecoilValue(userDispensariesAtom);

  // LD Flags
  const isReportsDummyDataEnabled = useLDFlag(LD.CUSTOMERS_SEGMENTS_REPORTS_DEMO_DATA_ROLLOUT, false);

  // State objects
  const lastWeek = new Date(Date.now() - MILLISECONDS_IN_A_WEEK);
  const [dateRangeValues, setDateRange] = useState<NonEmptyDateRange<Date>>([lastWeek, new Date()]);
  const [customersTypesList, setCustomerTypesList] = useState<ICustomerTypes[]>([]);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState(
    (filterLocation(defaultLocation)
      ? [
          {
            id: defaultLocation.LocId,
            label: maskLocationName(defaultLocation),
          },
        ]
      : []) as DropdownMenuOption[]
  );
  const [selectedCustomerTypes, setSelectedCustomerTypes] = useState(
    (locProfile?.DefaultCustomerTypeId
      ? [
          {
            id: locProfile.DefaultCustomerTypeId,
            label: 'Recreational',
          },
        ]
      : []) as DropdownMenuOption[]
  );

  // Customer type select functions
  const handleCustomerTypesChange = (vals: DropdownMenuOption[]) => {
    setSelectedCustomerTypes(vals);
  };

  const customerTypeSelectText = () =>
    selectedCustomerTypes.length === 0
      ? ''
      : `${selectedCustomerTypes.length} ${selectedCustomerTypes.length > 1 ? 'types' : 'type'}`;

  // Queries
  const queryClient = useQueryClient();
  const {
    data: reportSummaryDetails,
    isLoading,
    refetch,
  } = useGetSegmentReportQuery({
    CustomerTypes: Array.from(selectedCustomerTypes, ({ label }) => label),
    FromDate: dateRangeValues[0],
    Locations: Array.from(selectedLocations, ({ id }) => id),
    ToDate: dateRangeValues[1],
    segmentId: Number(segmentId),
    isDummyDataEnabled: isReportsDummyDataEnabled,
  });

  // Location select functions
  const options: DropdownMenuOption[] = useMemo(
    () =>
      locations
        .filter((location) => filterLocation(location))
        .map((location) => ({
          id: location.LocId,
          label: maskLocationName(location),
        })),
    [locations]
  );

  const customerOptions: DropdownMenuOption[] = useMemo(
    () =>
      customerTypes?.map((customerTypes) => ({
        id: customerTypes.CustomerTypeId,
        label: customerTypes.CustomerType,
      })),
    [customerTypes]
  );

  const handleLocationChange = (vals: DropdownMenuOption[]) => {
    setSelectedLocations(vals);
  };

  const locationSelectText = () =>
    selectedLocations.length === 0
      ? ''
      : `${selectedLocations.length} ${selectedLocations.length > 1 ? 'locations' : 'location'}`;

  // Date range functions
  const handleDateChange = (dateRange: DateRange<Date>) => {
    if (
      dateRange &&
      dateRange.length > 1 &&
      (dateRange[0] !== dateRangeValues[0] || dateRange[1] !== dateRangeValues[1])
    ) {
      setDateRange([dateRange[0]!, dateRange[1]!]);
    }
  };

  const shouldDisableDate = (day: unknown) => {
    const nowDate = new Date();
    const lastYear = subYears(nowDate, 1);
    return isAfter(day as Date, endOfToday()) || isBefore(day as Date, startOfYear(lastYear));
  };

  const handleExport = () => {
    setIsExportLoading(true);
    void refetch();
  };

  // UseEffects
  useEffect(() => {
    if (!isExportLoading) {
      setIsExportLoading(false);
    }
  }, [isExportLoading]);

  useEffect(() => {
    if (selectedLocations?.length || selectedCustomerTypes?.length || dateRangeValues) {
      void queryClient.invalidateQueries({ queryKey: segmentsReportQueryKey });
      void refetch();
    }
  }, [selectedLocations, selectedCustomerTypes, dateRangeValues]);

  useEffect(() => {
    if (!customersTypesList?.length) {
      const currentCustomerTypes: { name: string; val: number }[] = [];

      customerTypes?.forEach((t: { CustomerType: string; CustomerTypeId: number }) => {
        currentCustomerTypes.push({ name: t.CustomerType, val: t.CustomerTypeId });
      });

      if (currentCustomerTypes?.length) {
        setCustomerTypesList(currentCustomerTypes);
      }
    }
  }, [defaultLocation, customerTypes, dateRangeValues, customersTypesList?.length, queryClient]);

  return (
    <Stack direction='column' spacing={4}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' justifyContent='space-between' spacing={1}>
          <StyledDropdownMultiple
            automationId='segment-report-multi-location-selector'
            helpText='Select location(s)'
            inputId='financial-locations'
            options={options || []}
            selectAllButtonAutomationId='multi-location-selector-all'
            selectedToTop
            selectionText={locationSelectText()}
            setValue={(vals) => handleLocationChange(vals)}
            value={selectedLocations}
          />
          <DatePickerRangeWrapper>
            <DatePickerRange
              disabled={false}
              isMobile={false}
              shouldDisableDate={shouldDisableDate}
              value={dateRangeValues}
              onDateChange={(date) => handleDateChange(date)}
            />
          </DatePickerRangeWrapper>
          <StyledDropdownMultiple
            automationId='segment-report-customer-type'
            data-testid='multi-customer-types-selector'
            helpText='Select customer type(s)'
            inputId='report-customer-types'
            options={customerOptions || []}
            selectAllButtonAutomationId='multi-customer-types-selector-all'
            selectedToTop
            selectionText={customerTypeSelectText()}
            setValue={(vals) => handleCustomerTypesChange(vals)}
            value={selectedCustomerTypes}
          />
        </Stack>
        <Button
          automationId='export-button'
          disabled // Disabled until BE is ready
          label='Export'
          loading={isLoading}
          variant='secondary'
          onClick={handleExport}
        />
      </Stack>
      <ReportsSnapshotSummary
        date={dateRangeValues}
        isLoading={isLoading}
        reportSummary={
          reportSummaryDetails ?? {
            AllSales: [
              {
                AOV: 0,
                AvgItemsPerCart: 0,
                Date: new Date(),
                TotalSpend: 0,
                TransactionCount: 0,
              },
            ],
            SegmentSales: [
              {
                AOV: 0,
                AvgItemsPerCart: 0,
                Date: new Date(),
                TotalSpend: 0,
                TransactionCount: 0,
              },
            ],
          }
        }
      />
      <SegmentDemographics />
    </Stack>
  );
}

const StyledDropdownMultiple = styled(DropdownMultiple)`
  width: 233px;
  height: 44px;
  ${white} * {
    font: var(--font-regular-14pt-semibold) !important;
    cursor: pointer;
    color: var(--color-gray-90) !important;
  }
  .MuiInputBase-input {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  fieldset {
    border: none !important;
  }
  outline: 1px solid var(--color-gray-60);
  :focus {
    outline: 1px solid var(--color-grayscale-black) !important;
    * {
      color: var(--color-grayscale-black) !important;
    }
  }
`;
const DatePickerRangeWrapper = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
