export enum PAGE_TITLE {
  Overview = 'Overview',
}

export enum BREAKDOWN_TYPE {
  AvgItemsPerCart = 'AvgItemsPerCart',
  AVO = 'AVO',
  ShoppingFrequency = 'ShoppingFrequency',
  TotalSpend = 'TotalSpend',
  TransactionCount = 'TransactionCount',
}

export enum DEMOGRAPHICS_BREAKDOWN_TYPE {
  Category = 'Categories',
  CustomerTypes = 'CustomerType',
  Products = 'Products',
  Usage = 'Usage',
}

export const dateFormat = 'dd-mm-yyyy';

export type SegmentReportBreakdown = {
  breakdownType: { Key: string; Value: number }[];
};

export enum DemographicTypeOption {
  Age = 'Age',
  Location = 'Location',
}

export const DemographicTypes = [
  { key: 'Age', value: 'Age' },
  { key: 'Location', value: 'Location' },
];

export const ProductBreakdownMetrics = [
  { key: 'Categories', value: 'Categories' },
  { key: 'Products', value: 'Products' },
  { key: 'Usage', value: 'Usage' },
  { key: 'CustomerType', value: 'Customer Type' },
];

export const ProductMetricOption = {
  Categories: 'Categories',
  Products: 'Products',
  Usage: 'Usage',
  CustomerType: 'Customer Type',
};
