import React from 'react';

import BlockSpinner from 'src/app/components/block/block-spinner';
import { Button } from 'src/app/components/lib/button';
import { FormStickyFooter } from 'src/app/components/lib/form';
import { HeaderContainer } from 'src/app/components/lib/header-container';
import { Heading } from 'src/app/components/lib/heading';
import { Page, PageVariant } from 'src/app/components/lib/page';
import { Tab } from 'src/app/components/lib/tab';
import { TabPanel } from 'src/app/components/lib/tab-panel';
import { Tabs } from 'src/app/components/lib/tabs';
import { BlockNavigationModal } from 'src/app/components/modals/modals/block-navigation-modal';
import { ItemNotFound } from 'src/app/layout/error/item-not-found';
import { FullWidthContainer } from 'src/app/pages/manufacturing/shared/layout';

import { NEW_ASSEMBLY_PAGE_TITLE } from '../constants';

import { useAssemblyForm } from './use-assembly-form';

export type AssemblyDetailFormProps = {
  formType: 'create' | 'edit';
};

export function AssemblyDetailForm({ formType }: AssemblyDetailFormProps) {
  const {
    activeTab,
    assemblyData,
    assemblyNotFound,
    setActiveTab,
    onSubmit,
    navigateBackToListPage,
    showBlockNavigationModal,
    tabs,
    isValid,
    isLoading,
    isDirty,
    isReadonly,
  } = useAssemblyForm({
    formType,
  });

  return (
    <>
      {assemblyNotFound && <ItemNotFound item='Assembly' listPageName='assemblies' />}
      {!assemblyNotFound && (
        <Page variant={PageVariant.Table}>
          <BlockSpinner visible={isLoading} />
          {!isLoading && (
            <>
              <HeaderContainer>
                <Heading backBtnTestId='edit-assembly-back-to-list' onBackAction={navigateBackToListPage}>
                  {formType === 'create' ? NEW_ASSEMBLY_PAGE_TITLE : assemblyData?.name}
                </Heading>
              </HeaderContainer>
              {/* Navigation Tabs */}
              <Tabs value={activeTab} onChange={(_, newTab) => setActiveTab(newTab)}>
                {tabs.map(({ queryParam, label }) => (
                  <Tab key={queryParam} label={label} />
                ))}
              </Tabs>
              {/* Form Content */}
              <FullWidthContainer>
                {/* Assembly Tab Content */}
                {tabs.map(({ queryParam, renderTab }, index) => (
                  <TabPanel index={index} key={`${queryParam}`} value={activeTab}>
                    {renderTab()}
                  </TabPanel>
                ))}
                <FormStickyFooter
                  Actions={
                    <Button
                      data-testid={`assembly-form-submit_${formType}`}
                      disabled={!isValid || isLoading || !isDirty || isReadonly}
                      label='Save'
                      loading={isLoading}
                      type='submit'
                      onClick={onSubmit}
                    />
                  }
                />
              </FullWidthContainer>
              {/* Block Navigation Modal */}
              {showBlockNavigationModal && <BlockNavigationModal condition={isDirty} />}
            </>
          )}
        </Page>
      )}
    </>
  );
}
