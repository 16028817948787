import { useQueries, useQuery } from '@tanstack/react-query';

import {
  fetchMany,
  paginatedExportCommonQueryProps,
  paginatedExportNumberOfRequests,
  paginatedExportPageProps,
  useInfiniteGQLQuery,
} from 'src/app/queries/graphql';
import { bomKeys } from 'src/app/queries/graphql/bill-of-materials/query-key-factory';
import { GetBillOfMaterialsDocument } from 'src/gql/graphql';

import type { UseQueryOptionsForUseQueries } from 'src/app/queries/graphql';
import type { AbridgedFilterInput, QueryProps } from 'src/app/queries/types/gql-filtering-and-pagination';
import type { BillOfMaterialFilterInput, BillOfMaterialSortInput, BomAttributesFragment } from 'src/gql/graphql';

type UseBillOfMaterialQueryProps = QueryProps<AbridgedFilterInput<BillOfMaterialFilterInput>, BillOfMaterialSortInput>;

export function useBillOfMaterialsQuery(queryProps: UseBillOfMaterialQueryProps) {
  return useQuery({
    queryKey: bomKeys.many(queryProps),
    queryFn: async () => {
      const bomData = await fetchMany(GetBillOfMaterialsDocument, queryProps);
      const items = bomData?.items?.filter(Boolean) as BomAttributesFragment[];
      return {
        ...bomData,
        items,
      };
    },
    keepPreviousData: true,
  });
}

export function useBillOfMaterialsQueries({
  queryProps,
  totalCount,
}: {
  queryProps: UseBillOfMaterialQueryProps;
  totalCount: number;
}) {
  const queries: UseQueryOptionsForUseQueries[] = [];
  const numberOfRequests = paginatedExportNumberOfRequests(totalCount);

  for (let i = 0; i < numberOfRequests; i += 1) {
    const alteredQueryProps = { ...queryProps, ...paginatedExportPageProps(i) };
    queries.push({
      queryKey: bomKeys.many(alteredQueryProps),
      queryFn: () => fetchMany(GetBillOfMaterialsDocument, alteredQueryProps),
      ...paginatedExportCommonQueryProps,
    });
  }

  return useQueries({ queries });
}

export function useInfiniteBillOfMaterialsQuery(queryProps: UseBillOfMaterialQueryProps) {
  const { fetchAllItems, ...result } = useInfiniteGQLQuery({
    queryKey: bomKeys.many(queryProps, 'infinite'),
    queryProps,
    queryDocument: GetBillOfMaterialsDocument,
  });
  return {
    ...result,
    data: result.data.filter((bom) => !!bom),
    fetchAllBillOfMaterials: async () => {
      const res = await fetchAllItems();
      return res.pages.map(({ items }) => items?.filter((bom) => !!bom) ?? []).flat();
    },
  };
}
