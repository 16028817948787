import React, { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import GeneralActions from 'src/app_deprecated/actions/GeneralActions';

import { LinkButton } from 'src/app/components/lib/button';
import { DropdownSingle } from 'src/app/components/lib/dropdown';
import { generalAtom } from 'src/app/state/general';

type StrainDropdownProps = {
  automationId?: string;
  disabled?: boolean;
  label?: string;
  labelPlacement?: 'start' | 'top';
  onChange: (value: number | string) => void;
  refreshButtonAutomationId?: string;
  required?: boolean;
  resetButtonAutomationId?: string;
  searchAutomationId?: string;
  value: number | string;
};

export function StrainDropdown(props: StrainDropdownProps) {
  const {
    onChange,
    value,
    disabled = false,
    required,
    automationId,
    label = 'Strain:',
    labelPlacement = 'start',
    searchAutomationId,
    refreshButtonAutomationId,
    resetButtonAutomationId,
  } = props;
  const [loading, setLoading] = useState(false);
  const { strains } = useRecoilValue(generalAtom);
  const options = strains?.length ? strains : [];

  useEffect(() => {
    void loadStrains();
  }, []);

  async function loadStrains(notification = false) {
    setLoading(true);
    await GeneralActions.getStrains(notification);
    setLoading(false);
  }

  async function handleRefresh() {
    await loadStrains(true);
  }

  return (
    <DropdownSingle
      automationId={automationId}
      disabled={disabled}
      footerContent={
        <LinkButton
          automationId={refreshButtonAutomationId}
          buttonSize='small'
          key='strains-refresh'
          label='Refresh'
          loading={loading}
          onClick={handleRefresh}
        />
      }
      helpText='Select a strain'
      inputId='select-strain'
      label={label}
      labelPlacement={labelPlacement}
      options={options.map((strain) => ({
        label: strain.StrainName,
        id: String(strain.StrainId),
      }))}
      required={required}
      resetButtonAutomationId={resetButtonAutomationId}
      searchAutomationId={searchAutomationId}
      setValue={(newVal) => onChange(newVal.id)}
      value={String(value)}
    />
  );
}
